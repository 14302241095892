import React, { useState, useEffect } from "react";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import Dashboard from "./layouts/Dashboard";
import {
  isLoggedIn,
  checkUser,
  clearCurrentUser,
} from "./services/userService";
import { onError } from "./libs/errorLib";
import "rsuite/dist/styles/rsuite-default.css";
import "./App.css";
import { useHistory } from "react-router-dom";
import { connectToTopic } from "./services/utilityService";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      window.location.hostname === "beta.political-atlas.com" &&
      window.location.search !== "?stop"
    ) {
      window.location.href =
        "https://canada.political-atlas.com" + window.location.pathname;
    }
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function init() {
    try {
      history.listen((location) => {
        window.gtag("event", "page_view", {
          page_title: location.pathname,
          page_location: location.pathname,
          page_path: location.pathname,
        });
      });
      if (isLoggedIn()) {
        checkUser();
        userHasAuthenticated(true);
        // eslint-disable-next-line
        let user = await Auth.currentSession();
        connectToTopic(user.idToken.payload.sub);
      }
    } catch (e) {
      if (e === "No current user") {
        onError(e);
        userHasAuthenticated(false);
        clearCurrentUser();
        history.push("/login");
      }
    }
  }

  return (
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      <Dashboard>
        <Routes />
      </Dashboard>
    </AppContext.Provider>
  );
}

export default App;
