import React from "react";
import { Route, Switch } from "react-router-dom";

import Private from "./components/core/Private";
import Public from "./components/core/Public";

import Profile from "./pages/User/Profile/Profile";
import Login from "./pages/Auth/Login/Login";
import ConfirmationForm from "./pages/Auth/ConfirmUser/ConfirmationForm";
import Signup from "./pages/Auth/SignUp/Signup";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import NotFound from "./pages/NotFound/NotFound";
import Leadership from "./pages/Leadership/Leadership";
import Legislation from "./pages/Legislation/Legislation";
import LeadershipDetails from "./pages/Leadership/LeadershipDetails/LeadershipDetails";
import NationalOverview from "./pages/NationalOverview/NationalOverview";
import Home from "./pages/Home/Home";
import LegislationDetails from "./pages/Legislation/LegislationDetails/LegislationDetails";
import AcademicPublication from "./pages/AcademicPublication/AcademicPublication";

export default function Routes() {
  return (
    <Switch>
      <Public exact path="/login">
        <Login />
      </Public>
      <Public exact path="/signup">
        <Signup />
      </Public>
      <Public exact path="/forgot-password">
        <ForgotPassword />
      </Public>
      <Public exact path="/confirm-user">
        <ConfirmationForm />
      </Public>
      <Private exact path="/">
        <Home />
      </Private>
      <Private exact path="/settings">
        <Profile />
      </Private>
      <Private exact path="/subscriptions">
        <Profile />
      </Private>
      <Private exact path="/management" role="admin">
        <Profile />
      </Private>
      <Private exact path="/contact-admins">
        <Profile />
      </Private>
      <Private exact path="/leadership-board">
        <Leadership />
      </Private>
      <Private exact path="/leadership-board/profile/:id">
        <LeadershipDetails />
      </Private>
      <Private exact path="/legislation-board">
        <Legislation />
      </Private>
      <Private exact path="/legislation-board/profile/:id">
        <LegislationDetails />
      </Private>
      <Private exact path="/academic-publication">
        <AcademicPublication />
      </Private>
      <Private exact path="/national-overview">
        <NationalOverview />
      </Private>
      <Private exact path="/province-overview/:id">
        <NationalOverview />
      </Private>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
