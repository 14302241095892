import UserProfileCard from "../../../../components/shared/users/UserProfileCard/UserProfileCard";
import { Button, Divider, FlexboxGrid } from "rsuite";
import AddUser from "../../../../components/shared/users/AddUser/AddUser";
import { currentUser } from "../../../../services/userService";
import UserCard from "../../../../components/shared/users/UserCard/UserCard";
import React, { useEffect } from "react";
import { API } from "aws-amplify";
import { useStateIfMounted } from "use-state-if-mounted";
import { withNamespaces } from "react-i18next";

function Management({ t }) {
  const user = currentUser();
  const [users, setUsers] = useStateIfMounted();
  const [showMore, setShowMore] = useStateIfMounted(false);

  useEffect(() => {
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUsers = () => {
    API.get("api", "/users", null).then((users) => {
      setUsers(users);
    });
  };

  const showMoreUsers = () => {
    setShowMore(true);
  };

  return (
    <>
      <UserProfileCard user={user} />
      <div className="settings">
        <h5 className="header-style">{t("management.add_new_user")}</h5>
      </div>
      <Divider className="s-divider" />
      <AddUser />
      <div className="settings">
        <h5 className="header-style">{t("management.registered_users")}</h5>
      </div>
      <Divider className="s-divider divider-reg" />
      <FlexboxGrid>
        {users &&
          users
            .filter((user) => user.id !== currentUser().id)
            .slice(0, showMore ? users.length : 3)
            .map((user) => (
              <FlexboxGrid.Item key={user.id} className="p-10" colspan={8}>
                <UserCard user={user} reloadUsers={loadUsers} />
              </FlexboxGrid.Item>
            ))}
      </FlexboxGrid>
      <Button className="load-more" onClick={showMoreUsers}>
        {t("shared.load_more")}
      </Button>
    </>
  );
}

export default withNamespaces()(Management);
