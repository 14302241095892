import React, { useCallback, useEffect, useState } from "react";
import "./Top3LegislationPending.css";
import {
  Panel,
  Divider,
  Placeholder,
  Loader,
  Progress,
  Grid,
  Row,
  Col,
  Tooltip,
  Whisper,
} from "rsuite";
import CPAIcon from "../../../core/CPAIcons";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { notify } from "../../../../services/utilityService";
import { currentUser } from "../../../../services/userService";
import { getstatusColor } from "../../../../services/utilityService";
import { withNamespaces } from "react-i18next";

function Top3LegislationPending(props) {
  const object = {};
  const loading = false;
  const { Paragraph } = Placeholder;
  const { Circle } = Progress;
  const [subscriptions, setSubscriptions] = useState([]);
  const data = props.data;
  const user = currentUser();

  const follow = (objectId, type) => {
    let data = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        objectId: objectId,
        type: type,
        userId: user.id,
      },
    };

    API.post("api", "/subscriptions", data).then((object) => {
      notify("success", "Followed successfully");
      if (props.reloadObject) {
        props.reloadObject();
      } else {
        getSubscriptions();
      }
    });
  };

  const unFollow = (subId) => {
    API.del("api", "/subscriptions/" + subId, null).then(() => {
      notify("success", "Unfollowed successfully");
      if (props.reloadObject) {
        props.reloadObject();
      } else {
        getSubscriptions();
      }
    });
  };

  const getSubscriptions = useCallback(() => {
    API.get("api", "/subscriptions/" + user.id, null).then((subs) => {
      setSubscriptions(subs);
    });
  }, [user.id]);

  const getSubId = (objectId) => {
    return subscriptions.find((sub) => sub.objectId === objectId)?.id;
  };

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const showObject = () => (
    <div className="panel-header">
      <div className="title">{props.t("top.top_3_legislations")}</div>

      <Divider className="m-0 mb-4" />
      <Grid fluid>
        {data.map((legislation) => {
          return (
            <Row key={legislation.id}>
              <Col xs={3}>
                <div className="circle-wrapper pull-left">
                  <Whisper
                    className="pull-left"
                    placement="bottom"
                    speaker={
                      <Tooltip>
                        Status: <b>{legislation?.details?.recentStatus}</b>
                        <br />
                        Last update: <b>{legislation?.details?.recentUpdate}</b>
                      </Tooltip>
                    }
                  >
                    <Circle
                      percent={legislation?.details?.progressBar}
                      strokeColor={getstatusColor(
                        legislation?.details?.progressBar
                      )}
                      showInfo={false}
                      trailWidth={12}
                      strokeWidth={13}
                      strokeLinecap="butt"
                    />
                  </Whisper>
                </div>
              </Col>
              <Col xs={18} lg={18} className="content">
                <Link to={"/legislation-board/profile/" + legislation.id}>
                  <span>{legislation?.billNumber}</span>
                </Link>
                <Whisper
                  placement="right"
                  speaker={
                    <Tooltip>
                      {props.t("filters.monthly_change")}:{" "}
                      {legislation.details.activityIndexChange}%
                    </Tooltip>
                  }
                >
                  <span>
                    {legislation.details.activityIndexChange > 0 ? (
                      <CPAIcon color="#53818A" icon="up" size={20} />
                    ) : legislation.details.activityIndexChange === 0 ? (
                      <CPAIcon icon="neutralarrow" size={20} />
                    ) : (
                      <CPAIcon color="#B14E4E" icon="down" size={20} />
                    )}
                  </span>
                </Whisper>
                <Whisper
                  placement="bottomStart"
                  speaker={<Tooltip>{legislation?.title}</Tooltip>}
                >
                  <div
                    style={{
                      fontSize: 13,
                      color: "#bfbfbf",
                      fontFamily: "apercu-regular",
                    }}
                  >
                    {legislation?.title?.slice(0, 40)}
                  </div>
                </Whisper>
              </Col>
              <Col xs={3} lg={3} className="pl-3">
                <div className="left-border pull-left pl-2">
                  {getSubId(legislation.id) && (
                    <div
                      className="cursor"
                      onClick={() => unFollow(getSubId(legislation.id))}
                    >
                      <CPAIcon color="#BFBFBF" icon="unfollow" size={30} />
                    </div>
                  )}
                  {!getSubId(legislation.id) && (
                    <div
                      className="cursor"
                      onClick={() =>
                        follow(legislation.id, legislation.objectType)
                      }
                    >
                      <CPAIcon color="#BFBFBF" icon="follow" size={30} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          );
        })}
      </Grid>
    </div>
  );

  return (
    <Panel className="Top3LegPending no-padding" shaded>
      {loading && <Loader backdrop center content="loading" />}
      {!object && (
        <div>
          <Paragraph style={{ margin: 10 }} />
          <Paragraph style={{ margin: 30 }} />
        </div>
      )}
      {object && showObject()}
    </Panel>
  );
}
export default withNamespaces()(Top3LegislationPending);
