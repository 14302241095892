import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import config from "./config";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

TimeAgo.addDefaultLocale(en);

//Amplify.Logger.LOG_LEVEL = "DEBUG";
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
      {
        name: "backend",
        endpoint: config.backendApiGateway.URL,
        region: config.backendApiGateway.REGION,
      },
    ],
  },
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
