import React, { useEffect, useState } from "react";
import "./AcademicFilters.css";
import { CheckPicker } from "rsuite";
import { parseFilters, compare } from "../../../services/utilityService";
import { withNamespaces } from "react-i18next";
import { API } from "aws-amplify";

function AcademicFilters(props) {
  const [objectFilters, setObjectFilters] = useState([]);
  const [issue, setIssue] = useState();

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (issue) {
      props.applyFilters(issue.join(","));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issue]);

  const getFilters = () => {
    API.get("api", "/meta/", null).then((filters) => {
      setObjectFilters(filters);
    });
  };

  return (
    <div className="SupportFilters">
      {props.id && (
        <CheckPicker
          appearance="subtle"
          placement="auto"
          onChange={(v) => setIssue(v ? v : "")}
          placeholder={props.t("filters.keyIssues")}
          style={{ width: 150 }}
          className="filter-font border-style"
          data={parseFilters(objectFilters, "issues")}
          groupBy="type"
          sort={(isGroup) => {
            if (isGroup) {
              return (a, b) => {
                return compare(a.type, b.type);
              };
            }

            return (a, b) => {
              return compare(a.label, b.label);
            };
          }}
        />
      )}
    </div>
  );
}

export default withNamespaces()(AcademicFilters);
