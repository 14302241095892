import React, { useCallback, useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import {
  AutoComplete,
  Icon,
  IconButton,
  InputGroup,
  SelectPicker,
  DateRangePicker,
} from "rsuite";
import {
  compare,
  getFilters,
  parseFilters,
  saveFilters,
} from "../../../../services/utilityService";
import { API } from "aws-amplify";
import "./ObjectFilters.css";
import {
  getObjects,
  getObjectsIndexes,
} from "../../../../services/objectService";
import CPAIcon from "../../../core/CPAIcons";
import * as dateFns from "date-fns";

let currentFilters = {};

function ObjectFilters(props) {
  const [objectFilters, setObjectFilters] = useState([]);
  const [typeAdminLevel, setTypeAdminLevel] = useState("");
  const [typeAffiliation, setTypeAffiliation] = useState("");
  const [typeKeyIssues, setTypeKeyIssues] = useState("");
  const [typeProvince, setTypeProvince] = useState("");
  const [typeLegislator, setTypeLegislator] = useState("");
  const [typeDate, setTypeDate] = useState([]);
  const [typeCabinet, setTypeCabinet] = useState("");
  const [search, setSearch] = useState("");
  const [searchAllData, setSearchAllData] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [sorting, setSorting] = useState("");
  const [sortingIndex, setSortingIndex] = useState("");
  const [objects, setObjects] = useState([]);
  const [allObjects, setAllObjects] = useState([]); // need this to get id from name for autocomplete search
  const [legislators, setLegislators] = useState([]);

  const getObjectFilters = () => {
    API.get("api", "/meta/", null).then((filters) => {
      setObjectFilters(filters);
    });
  };

  const handleFilters = (type, value) => {
    if (type === "typeAdminLevel") {
      setTypeAdminLevel(value);
      currentFilters.adminLevel = value;
      if (!value) {
        delete currentFilters.adminLevel;
      }

      saveFilters(currentFilters, props.type);
      getFilteredObjects();
    }

    if (type === "typeAffiliation") {
      setTypeAffiliation(value);
      currentFilters.politicalAffiliation = value;
      if (!value) {
        delete currentFilters.politicalAffiliation;
      }

      saveFilters(currentFilters, props.type);
      getFilteredObjects();
    }

    if (type === "typeKeyIssues") {
      setTypeKeyIssues(value);
      currentFilters.keyIssue = value;
      if (!value) {
        delete currentFilters.keyIssue;
      }
      saveFilters(currentFilters, props.type);
      props.appliedFilters({ issue: value });
      getFilteredObjects();
    }

    if (type === "typeProvince") {
      setTypeProvince(value);
      currentFilters.province = value;
      if (!value) {
        delete currentFilters.province;
      }

      saveFilters(currentFilters, props.type);
      getFilteredObjects();
    }

    if (type === "typeLegislator") {
      setTypeLegislator(value);
      currentFilters.legislator = value;
      if (!value) {
        delete currentFilters.legislator;
      }

      saveFilters(currentFilters, props.type);
      getFilteredObjects();
    }

    if (type === "typeCabinet") {
      setTypeCabinet(value);
      currentFilters.cabinetStatus = value;
      if (!value) {
        delete currentFilters.cabinetStatus;
      }

      saveFilters(currentFilters, props.type);
      getFilteredObjects();
    }

    if (type === "typeDate") {
      setTypeDate(value);
      currentFilters.date = value
        .map((v) => {
          let da = new Date(v);
          let newDate = da.setDate(da.getDate() + 1);
          return new Date(newDate).toISOString().split("T")[0];
        })
        .join(",");

      if (!value) {
        delete currentFilters.date;
      }

      saveFilters(currentFilters, props.type);
      getFilteredObjects();
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const selectSearch = (item) => {
    // clear other filters
    clearFilters();
    props.filteredObjects(
      allObjects.filter((a) => (a.name || a.title) === item.value)
    );
  };

  const hideSearch = () => {
    setSearch("");
    props.filteredObjects(objects);
    setShowSearch(false);
  };

  const clearFilters = () => {
    setTypeAdminLevel("");
    setTypeAffiliation("");
    setTypeKeyIssues("");
    setTypeProvince("");
    setTypeLegislator("");
    setTypeCabinet("");
    setTypeDate([]);
    setSorting("");
    setSortingIndex("");
    currentFilters = {};
    saveFilters(currentFilters, props.type);
  };

  const clear = () => {
    clearFilters();
    getFilteredObjects();
  };

  const sort = () => {
    currentFilters.sort = currentFilters.sort === "asc" ? "desc" : "asc";
    setSorting(currentFilters.sort);
    //setFilters(currentFilters, props.type);
    setObjects(handleSort(objects));
    props.filteredObjects(handleSort(objects));
  };

  const sortByIndex = () => {
    currentFilters.sortByIndex =
      currentFilters.sortByIndex === "asc" ? "desc" : "asc";
    setSortingIndex(currentFilters.sortByIndex);
    //setFilters(currentFilters, props.type);
    setObjects(handleSortByIndex(objects));
    props.filteredObjects(handleSortByIndex(objects));
  };

  const setUserFilters = useCallback(() => {
    currentFilters = getFilters(props.type);
    // Add default for sorting ASC
    currentFilters.sortByIndex = "asc";

    if (currentFilters.adminLevel) {
      setTypeAdminLevel(currentFilters.adminLevel);
    }

    if (currentFilters.politicalAffiliation) {
      setTypeAffiliation(currentFilters.politicalAffiliation);
    }

    if (currentFilters.province) {
      setTypeProvince(currentFilters.province);
    }

    if (currentFilters.legislator) {
      setTypeLegislator(currentFilters.legislator);
    }

    if (currentFilters.cabinetStatus) {
      setTypeCabinet(currentFilters.cabinetStatus);
    }

    if (currentFilters.date) {
      setTypeDate(currentFilters.date.split(",").map((d) => new Date(d)));
    }

    if (currentFilters.keyIssue) {
      setTypeKeyIssues(currentFilters.keyIssue);
      props.appliedFilters({ issue: currentFilters.keyIssue });
    }

    if (currentFilters.sort) {
      setSorting(currentFilters.sort);
    }

    if (currentFilters.sortByIndex) {
      setSortingIndex(currentFilters.sortByIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  const getFilteredObjects = useCallback(() => {
    hideSearch();

    //cool workaround to populate legislation to alberta province by default
    let data = {
      body: { ...getFilters(props.type), objectType: props.type },
    };
    const notYetIncluded = [
      "MB",
      "NB",
      "NL",
      "NT",
      "NS",
      "NU",
      "PE",
      "SK",
      "YT",
    ];
    if (notYetIncluded.includes(getFilters(props.type).province)) {
      data = {
        body: { sortByIndex: "asc", objectType: props.type },
      };
    }

    API.post("api", "/objects/filters", data).then((objects) => {
      getObjectsIndexes(props.type, currentFilters.keyIssue).then((indexes) => {
        objects.map((o) => (o.rank = indexes[o.id]));
        setObjects(objects);
        props.filteredObjects(
          currentFilters.sort
            ? handleSort(objects)
            : currentFilters.sortByIndex
            ? handleSortByIndex(objects)
            : objects
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (object) => {
    return currentFilters.sort === "asc"
      ? object.sort((a, b) => (a.name < b.name ? -1 : Number(a.name > b.name)))
      : object.sort((b, a) => (a.name < b.name ? -1 : Number(a.name > b.name)));
  };

  const handleSortByIndex = (object) => {
    return currentFilters.sortByIndex === "asc"
      ? object.sort((a, b) => (a.rank < b.rank ? -1 : Number(a.rank > b.rank)))
      : object.sort((b, a) => (a.rank < b.rank ? -1 : Number(a.rank > b.rank)));
  };

  const getLegislators = () => {
    getObjects("1").then((objects) => {
      setLegislators(
        objects.map((o) => {
          return { label: o.name, value: o.id };
        })
      );
    });
  };

  const getSearchAllData = () => {
    let data = {
      body: { sortByIndex: "asc", objectType: "1" },
    };
    API.post("api", "/objects/filters", data).then((allObjects) => {
      setSearchAllData(allObjects.map((o) => o.name || o.title));
      setAllObjects(allObjects);
    });
  };

  useEffect(() => {
    getSearchAllData();
    getObjectFilters();
    setUserFilters();
    getFilteredObjects();
    getLegislators();
  }, [getFilteredObjects, setUserFilters]);

  return (
    <div className="ObjectFilters">
      {props.type === "1" && (
        <SelectPicker
          placeholder={props.t("filters.adminlevel")}
          sort={() => {
            return (a, b) => {
              return compare(a.label, b.label);
            };
          }}
          data={parseFilters(objectFilters, "1")}
          value={typeAdminLevel}
          onChange={(v) => handleFilters("typeAdminLevel", v)}
          className="mr8px filter-font border-style pull-left"
          searchable={false}
          appearance="subtle"
        />
      )}
      {props.type === "1" && (
        <SelectPicker
          placeholder={props.t("filters.cabinet_status")}
          sort={() => {
            return (a, b) => {
              return compare(a.label, b.label);
            };
          }}
          data={parseFilters(objectFilters, "14")}
          value={typeCabinet}
          onChange={(v) => handleFilters("typeCabinet", v)}
          className="mr8px filter-font border-style pull-left"
          searchable={false}
          appearance="subtle"
        />
      )}
      {props.type === "1" && (
        <SelectPicker
          placeholder={props.t("filters.affiliation")}
          sort={() => {
            return (a, b) => {
              return compare(a.label, b.label);
            };
          }}
          data={parseFilters(objectFilters, "3")}
          value={typeAffiliation}
          onChange={(v) => handleFilters("typeAffiliation", v)}
          className="mr8px filter-font border-style pull-left"
          searchable={false}
          appearance="subtle"
        />
      )}
      <SelectPicker
        placeholder={props.t("filters.keyIssues")}
        groupBy="type"
        sort={(isGroup) => {
          if (isGroup) {
            return (a, b) => {
              return compare(a.type, b.type);
            };
          }

          return (a, b) => {
            return compare(a.label, b.label);
          };
        }}
        data={parseFilters(objectFilters, "issues")}
        value={typeKeyIssues}
        onChange={(v) => handleFilters("typeKeyIssues", v)}
        className="mr8px filter-font border-style pull-left"
        style={{ width: 150 }}
        searchable={false}
        appearance="subtle"
      />
      {props.type === "2" && (
        <SelectPicker
          placeholder={props.t("filters.legislators")}
          sort={() => {
            return (a, b) => {
              return compare(a.label, b.label);
            };
          }}
          data={legislators}
          value={typeLegislator}
          onChange={(v) => handleFilters("typeLegislator", v)}
          className="mr8px filter-font border-style pull-left"
          appearance="subtle"
        />
      )}
      <SelectPicker
        placeholder={props.t("filters.province")}
        sort={() => {
          return (a, b) => {
            return compare(a.label, b.label);
          };
        }}
        data={parseFilters(objectFilters, "2")}
        value={typeProvince}
        onChange={(v) => handleFilters("typeProvince", v)}
        className="mr8px filter-font border-style pull-left"
        searchable={false}
        appearance="subtle"
      />
      {props.type === "2" && (
        <DateRangePicker
          placeholder={props.t("filters.date")}
          format="YYYY-MM-DD"
          placement="bottomEnd"
          value={typeDate}
          onChange={(v) => handleFilters("typeDate", v)}
          className="mr8px filter-font border-style pull-left"
          appearance="subtle"
          ranges={[
            {
              label: "Last week",
              value: [dateFns.subWeeks(new Date(), 1), new Date()],
            },
            {
              label: "Last month",
              value: [dateFns.subMonths(new Date(), 1), new Date()],
            },
            {
              label: "Last 6 months",
              value: [dateFns.subMonths(new Date(), 6), new Date()],
            },
            {
              label: "Last year",
              value: [dateFns.subYears(new Date(), 1), new Date()],
            },
          ]}
        />
      )}
      {searchAllData && showSearch && (
        <InputGroup
          id="grp_search"
          className="mr-2 pull-left"
          style={{
            width: 200,
          }}
        >
          <AutoComplete
            id="txt_autocomplete"
            data={searchAllData}
            value={search}
            onChange={handleSearch}
            onSelect={selectSearch}
          />
          <InputGroup.Button>
            <Icon onClick={hideSearch} icon="close" />
          </InputGroup.Button>
        </InputGroup>
      )}
      <IconButton
        onClick={() => setShowSearch(true)}
        id="btn_search"
        appearance="subtle"
        className="ml16px mr-2 border-style pull-left"
        icon={<CPAIcon color="#323232" icon="search" size={20} />}
      />
      <IconButton
        id="btn_arrange"
        onClick={sortByIndex}
        data={sortingIndex}
        className="mr-2  border-style pull-left"
        appearance="subtle"
        icon={<CPAIcon color="#323232" icon="sort" size={20} />}
      />
      <IconButton
        onClick={sort}
        data={sorting}
        className="mr-2  border-style"
        appearance="subtle"
        icon={<CPAIcon color="#323232" icon="sortaz" size={20} />}
      />
      {Object.keys(currentFilters).length !== 0 && (
        <IconButton
          onClick={clear}
          className="mr-2  border-style"
          icon={<CPAIcon color="#000" icon="close" size={20} />}
        />
      )}
    </div>
  );
}

export default withNamespaces()(ObjectFilters);
