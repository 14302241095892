import React from "react";
import { Col, Placeholder, FlexboxGrid } from "rsuite";
import Indicator from "../Indicator/LoadingIndicator";

const LoadingPlaceholders = () => {
  const { Paragraph } = Placeholder;

  const CardGrid = () => {
    const arr = [];
    for (let i = 0; i < 11; i++) {
      arr.push(
        <FlexboxGrid.Item key={i} componentClass={Col} colspan={24} lg={8} md={12}>
          <Paragraph
            style={{ margin: 30, width: 600 }}
            rows={3}
            graph="circle"
          />
        </FlexboxGrid.Item>
      );
    }
    return arr;
  };
  return (
    <div style={{ height: "40vh" }}>
      <Col lg={8} md={12}>
        <Paragraph style={{ margin: 30, width: 200 }} rows={3} graph="circle" />
      </Col>
      <CardGrid />
      <div
        style={{
          position: "fixed",
          zIndex: "7",
          top: "27%",
          left: "50%",
        }}
      >
        <Indicator />
      </div>
    </div>
  );
};
export default LoadingPlaceholders;
