import React from "react";
import "./LegislationProfile.css";
import { Grid, Row, Col, Progress, Divider, Tooltip, Whisper } from "rsuite";
import CPAIcon from "../../../core/CPAIcons";
import { getstatusColor } from "../../../../services/utilityService";
import { withNamespaces } from "react-i18next";

function LegislationProfile(props) {
  const { Circle } = Progress;
  const showObject = () => (
    <Grid fluid className="grid-style">
      <Row className="show-grid">
        <Col xs={2}>
          <Whisper
            className="pull-left"
            placement="bottom"
            speaker={
              <Tooltip>
                {props.t("legislationcard.status")} :{" "}
                <b>{props.object?.data?.recentStatus}</b>
                <br />
                {props.t("legislationcard.lastupdated")}:{" "}
                <b>{props.object?.data?.recentUpdate}</b>
              </Tooltip>
            }
          >
            <Circle
              className="mt-2"
              percent={props.object?.data?.progressBar}
              width={80}
              strokeColor={getstatusColor(props.object?.data?.progressBar)}
              showInfo={false}
              trailWidth={12}
              strokeWidth={13}
              strokeLinecap="butt"
            />
          </Whisper>
        </Col>
        <Col xs={19}>
          <b>{props.object?.title}</b>
          <br />
          {props.object?.summary}
          <Divider className="mt-3 mb-3"></Divider>
          <Row>
            <Col className="pt-2" xs={2}>
              {props.t("feedpost.Issues")}:
            </Col>
            <Col xs={10}>
              {props.object?.issueText.map((i) => (
                <div className="tags" key={i}>
                  {i}
                </div>
              ))}
            </Col>
            <Col xs={12}>
              <CPAIcon
                className="icon-line-space"
                color="#000"
                icon="introdate"
                size={20}
              />
              <Whisper
                placement="bottom"
                speaker={
                  <Tooltip>{props.t("legislationcard.introduced_at")}</Tooltip>
                }
              >
                <span className="icon-icon-space">
                  {" "}
                  {props.object?.dateIntroduced}
                </span>
              </Whisper>

              <CPAIcon
                className="icon-line-space"
                color="#000"
                icon="parliamentsession"
                size={20}
              />
              <Whisper
                placement="bottom"
                speaker={
                  <Tooltip>
                    {props.t("legislationcard.parliament")} -{" "}
                    {props.t("legislationcard.session")}
                  </Tooltip>
                }
              >
                <span className="icon-icon-space">
                  {props.object?.parliament}-{props.object?.session}
                </span>
              </Whisper>

              <CPAIcon
                className="icon-line-space"
                color="#000"
                icon="mapmarker"
                size={20}
              />
              <span className="icon-icon-space"> {props.object.province}</span>

              <CPAIcon
                className="icon-line-space"
                color="#000"
                icon="level"
                size={20}
              />
              <span className="pt-1">
                {" "}
                {props.object.data?.adminLevel === "1"
                  ? props.t("leadership_profile_info.federal")
                  : props.t("leadership_profile_info.provincial")}
              </span>
              <div className="clearfix"></div>
              <div className="row-btw-icon"></div>

              <CPAIcon
                className="icon-line-space"
                color="#000"
                icon="twitter"
                size={20}
              />
              <Whisper
                placement="bottom"
                speaker={
                  <Tooltip>
                    {props.t("legislationcard.social_media_mentions")}
                  </Tooltip>
                }
              >
                <span className="number-font-size pl-1">
                  {props.object.data?.tweetCount || 0}
                </span>
              </Whisper>
              {props.object.data?.tweetChange < 0 ? (
                <CPAIcon
                  color="#B14E4E"
                  className="icon-icon-space"
                  icon="down"
                  size={20}
                />
              ) : props.object.data?.tweetChange === 0 ? (
                <span>
                  {" "}
                  <CPAIcon
                    icon="neutralarrow"
                    className="icon-icon-space"
                    size={20}
                  />
                </span>
              ) : (
                <CPAIcon
                  color="#53818A"
                  icon="up"
                  className="icon-icon-space"
                  size={20}
                />
              )}

              <CPAIcon
                color="#000"
                icon="megaphone"
                size={20}
                className="icon-line-space"
              />
              <Whisper
                placement="bottom"
                speaker={
                  <Tooltip>{props.t("legislationcard.news_mentions")}</Tooltip>
                }
              >
                <span className="number-font-size pl-1">
                  {props.object.data?.newsMentions || 0}
                </span>
              </Whisper>
              {props.object.data?.newsMentionsChange < 0 ? (
                <CPAIcon
                  color="#B14E4E"
                  icon="down"
                  className="icon-icon-space"
                  size={20}
                />
              ) : props.object.data?.newsMentionsChange === 0 ? (
                <span>
                  {" "}
                  <CPAIcon
                    icon="neutralarrow"
                    className="icon-icon-space"
                    size={20}
                  />
                </span>
              ) : (
                <CPAIcon
                  color="#53818A"
                  icon="up"
                  className="icon-icon-space"
                  size={20}
                />
              )}

              <CPAIcon
                color="#000"
                icon="computer"
                size={20}
                className="icon-line-space"
              />
              <Whisper
                placement="bottom"
                speaker={<Tooltip>TV mentions</Tooltip>}
              >
                <span className="number-font-size pl-1">
                  {props.object.data?.tvMentions}
                </span>
              </Whisper>
              {props.object.data?.tvMentionsChange < 0 && (
                <CPAIcon
                  color="#B14E4E"
                  className="icon-icon-space"
                  icon="down"
                  size={20}
                />
              )}
              {props.object.data?.tvMentionsChange > 0 && (
                <CPAIcon
                  color="#B14E4E"
                  className="icon-icon-space"
                  icon="up"
                  size={20}
                />
              )}
              {props.object.data?.tvMentionsChange === 0 && (
                <CPAIcon
                  icon="neutralarrow"
                  className="icon-icon-space"
                  size={20}
                />
              )}

              <CPAIcon
                color="#000"
                icon="parliament"
                size={20}
                className="icon-line-space"
              />
              <Whisper
                placement="bottom"
                speaker={
                  <Tooltip>
                    {props.t("leadership_profile_info.house_debate_mentions")}
                  </Tooltip>
                }
              >
                <span className="number-font-size pl-1">
                  {props.object.data?.debateCount || 0}
                </span>
              </Whisper>
              {props.object.data?.debateChange < 0 && (
                <CPAIcon
                  color="#B14E4E"
                  icon="down"
                  className="icon-icon-space"
                  size={20}
                />
              )}
              {props.object.data?.debateChange > 0 && (
                <CPAIcon
                  color="#B14E4E"
                  icon="up"
                  className="icon-icon-space"
                  size={20}
                />
              )}
              {props.object.data?.debateChange === 0 && (
                <CPAIcon
                  icon="neutralarrow"
                  className="icon-icon-space"
                  size={20}
                />
              )}

              <CPAIcon
                color="#000"
                icon="newsletter"
                size={20}
                className="icon-line-space"
              />
              <Whisper
                placement="bottom"
                speaker={
                  <Tooltip>
                    {props.t("leadership_profile_info.newsletter_mentions")}
                  </Tooltip>
                }
              >
                <span className="number-font-size pl-1">
                  {props.object.data?.blogCount}
                </span>
              </Whisper>
              {props.object.data?.blogChange < 0 && (
                <CPAIcon color="#B14E4E" icon="down" size={20} />
              )}
              {props.object.data?.blogChange > 0 && (
                <CPAIcon color="#B14E4E" icon="up" size={20} />
              )}
              {props.object.data?.blogChange === 0 && (
                <CPAIcon icon="neutralarrow" size={20} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  );

  return <div className="LegislationProfile no-padding">{showObject()}</div>;
}
export default withNamespaces()(LegislationProfile);
