import React, { useState } from "react";
import { Auth, API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, Button } from "react-bootstrap";
import LoaderButton from "../../../components/shared/LoaderButton/LoaderButton";
import { useAppContext } from "../../../libs/contextLib";
import { useFormFields } from "../../../libs/hooksLib";
import { onError } from "../../../libs/errorLib";
import { setCurrentUser } from "../../../services/userService";
import "./Login.css";
import { withNamespaces } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

function Login({ t }) {
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const [isChallenge, setIsChallenge] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    newPassword: "",
  });

  const [Errors, setErrors] = useState({ email: " ", password: " " });

  function validateForm() {
    let errors = {};
    let isValid = true;

    if (!(fields.password.length > 0)) {
      isValid = false;
      errors["password"] = t("login.please_enter_your_password");
    }

    if (typeof fields.email !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields.email)) {
        isValid = false;
        errors["email"] = t("login.please_enter_valid_email_address");
      }
    }

    if (!(fields.email.length > 0)) {
      isValid = false;
      errors["email"] = t("login.please_enter_your_email");
    }

    setErrors(errors);
    return isValid;
  }

  async function handleChallenge(event) {
    event.preventDefault();
    try {
      await Auth.completeNewPassword(
        user,
        fields.newPassword,
        user.challengeParam.requiredAttributes
      );
      let newUser = await Auth.currentUserInfo();

      let getUserDetails = await API.get(
        "api",
        "/users/" + newUser.attributes.sub
      );

      setCurrentUser(getUserDetails);
      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    try {
      let login = await Auth.signIn(fields.email, fields.password);
      if (login.challengeName === "NEW_PASSWORD_REQUIRED") {
        setIsLoading(false);
        setIsChallenge(true);
        setUser(login);
        return;
      }

      let getUserDetails = await API.get(
        "api",
        "/users/" + login.attributes.sub
      );
      setCurrentUser(getUserDetails);
      userHasAuthenticated(true);
    } catch (e) {
      let errors = {};
      errors["password"] = e.message;
      setErrors(errors);
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      <div className="form">
        <div className="title">Canadian Political Atlas</div>
        {!isChallenge && (
          <form onSubmit={handleSubmit}>
            <FormGroup controlId="email">
              <FormControl
                autoFocus
                type="email"
                placeholder="name@rbhinc.ca"
                value={fields.email}
                onChange={handleFieldChange}
                className="input-txt-style"
              />
              <div id="err_email" className="text-danger txt-err">
                {Errors.email}
              </div>
            </FormGroup>

            <FormGroup controlId="password">
              <FormControl
                type="password"
                value={fields.password}
                placeholder={t("login.password")}
                onChange={handleFieldChange}
                className="input-txt-style"
              />
              <div className="text-danger txt-err">{Errors.password}</div>
            </FormGroup>

            <div>
              <LoaderButton
                block
                type="submit"
                className="btn-font-color Loginbtn"
                isLoading={isLoading}
              >
                {t("login.log_in")}
              </LoaderButton>
            </div>
            <div className="text-center mt-2">
              <Link to="/forgot-password" className="forgot-password-style">
                {t("login.forgot_password")}?
              </Link>
              <Link
                to="/confirm-user"
                className="forgot-password-style confirm-style"
              >
                Confirm User Email
              </Link>
            </div>
            <hr className="hr-style" />
            <div className="div-signup-wrapper">
              <Button
                id="btn_createnewaccount"
                block
                type="button"
                className="btn btn-signout"
                onClick={() => history.push("/signup")}
              >
                {t("login.create_new_account")}
              </Button>
            </div>
          </form>
        )}
        {isChallenge && (
          <form onSubmit={handleChallenge}>
            <ControlLabel className="welcome">
              {t("login.welcome")},
            </ControlLabel>
            <br />
            <ControlLabel className="welcome-message">
              {t("login.please_change_your_password")}
            </ControlLabel>
            <br />
            <FormGroup controlId="newPassword" bsSize="large">
              <FormControl
                autoFocus
                type="password"
                placeholder={t("login.new_password")}
                value={fields.newPassword}
                onChange={handleFieldChange}
              />
            </FormGroup>
            <div className="pull-right">
              <LoaderButton
                block
                type="submit"
                bsSize="large"
                isLoading={isLoading}
              >
                {t("login.change_password")}
              </LoaderButton>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(Login);
