import React, { useEffect, useState } from "react";
import "./AcademicPublication.css";
import { withNamespaces } from "react-i18next";
import { getObjectWithData } from "../../services/objectService";
import LineChart from "../../components/core/LineChart";
import { Divider, Panel } from "rsuite";
import Posts from "../../components/shared/objects/Posts/Posts";
import AcademicFilters from "../../components/shared/AcademicFilters/AcademicFilters";
import LoadingPlaceholders from "../../components/shared/LoadingPlaceholders/NationProvincePublication/LoadingPlaceholders";

function AcademicPublication({ t }) {
  const id = "3";
  const [object, setObject] = useState();
  const [chartData, setChartData] = useState();

  const issueChanged = (issue) => {
    getObjectWithData(id, null, issue).then((object) => {
      getChartData(object);
    });
  };

  const getChartData = (ob) => {
    ob.data.shareOfVoice = ob.data.activityVolume;
    let array = [];
    for (const [key] of Object.entries(ob.data.shareOfVoice)) {
      let obj = {};
      obj.name = key;
      obj.marker = { enabled: false };
      let unordered = ob.data.shareOfVoice[key];
      const ordered = Object.keys(unordered)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unordered[key];
          return obj;
        }, {});

      obj.data = Object.values(ordered);
      obj.headers = Object.keys(ordered).map((h) => h);
      array.push(obj);
    }

    setChartData(array);
  };

  const loadObject = () => {
    getObjectWithData(id, null, null).then((object) => {
      setObject(object);
      if (Object.entries(object.data.activityVolume).length) {
        getChartData(object);
      }
    });
  };

  useEffect(() => {
    loadObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="LeadershipDetails">
      {object && (
        <div>
          <div className="mb-4">
            <div className="container">
              <h5 className="float-left mt-15px header-style">
                {t("academicpublication.volume_of_publications_over_time")}
              </h5>
              <div className="float-right filters-style">
                <AcademicFilters applyFilters={issueChanged} id={id} />
              </div>
              <div className="clearfix" />
              <Divider className="divider" />
              {chartData && (
                <div>
                  <Panel className="leadership-profile no-padding">
                    <LineChart title={""} data={chartData} />
                  </Panel>
                </div>
              )}
            </div>
          </div>
          <Posts object={object} pubView="true" />
        </div>
      )}
      {!object && <LoadingPlaceholders />}
    </div>
  );
}

export default withNamespaces()(AcademicPublication);
