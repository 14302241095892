import React, { useEffect, useState } from "react";
import "./FeedPost.css";
import {
  Panel,
  Divider,
  FlexboxGrid,
  Avatar,
  Col,
  Placeholder,
  Tooltip,
  Whisper,
  Progress,
} from "rsuite";
import ReactTimeAgo from "react-time-ago";
import CPAIcon from "../../../core/CPAIcons";
import { API } from "aws-amplify";
import { notify } from "../../../../services/utilityService";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { getstatusColor } from "../../../../services/utilityService";

const { Circle } = Progress;

function FeedPost(props) {
  const feedId = props.feedId || props.postId;
  const [feed, setFeed] = useState();
  const { Paragraph } = Placeholder;

  const getFeed = () => {
    API.get("api", "/feed/id/" + feedId, null).then((f) => {
      setFeed(f);
    });
  };

  const getPostFeed = () => {
    API.get("api", "/post/id/" + feedId, null).then((f) => {
      setFeed(f);
    });
  };

  function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    text = text?.includes("RT ")
      ? "<span><span class='bold-title title'>" +
        feed.post.content.split(":")[0] +
        "</span>" +
        feed.post.content.split(":").slice(1).join(":") +
        "</span>"
      : feed.post.content;
    return (
      text?.slice(0, 300).replace(urlRegex, function (url) {
        url = url.split("<")[0];
        return '<a target="_blank" href="' + url + '">' + url + "</a>";
      }) + "..."
    );
  }

  const unFollow = (subId) => {
    API.del("api", "/subscriptions/" + subId, null).then(() => {
      notify("success", "Unfollowed successfully");
      window.location.reload();
    });
  };

  useEffect(() => {
    if (props.feedId) {
      getFeed();
    } else {
      getPostFeed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Panel className="FeedPost mb-4" shaded>
      {feed && (
        <div>
          {!props.pubView && (
            <div className="title pull-left">
              <span className="bold-title ">
                {feed.object.objectType === "1" ? (
                  <Link to={"/leadership-board/profile/" + feed.object.id}>
                    {feed.object?.name}
                  </Link>
                ) : (
                  <Link to={"/legislation-board/profile/" + feed.object.id}>
                    {feed.object?.billNumber}
                  </Link>
                )}
              </span>
              <Whisper
                placement="bottom"
                speaker={
                  <Tooltip>
                    {props.t("filters.monthly_change")}: {feed.object?.change}%
                  </Tooltip>
                }
              >
                <span>
                  {feed.object?.change > 0 && (
                    <CPAIcon icon="up" size={20} color="#53818A" />
                  )}
                  {feed.object?.change < 0 && (
                    <CPAIcon icon="down" size={20} color="#D0703B" />
                  )}
                  {feed.object?.change === 0 && (
                    <CPAIcon icon="neutralarrow" size={20} />
                  )}
                </span>
              </Whisper>
              {feed.postType === "TWT" && (
                <span>
                  {" "}
                  {feed.object.objectType === "1"
                    ? props.t("feedpost.posted")
                    : props.t("feedpost.was_mentioned")}{" "}
                  {props.t("feedpost.on")}{" "}
                  <span className="bold-title">
                    {props.t("feedpost.socialmedia")}
                  </span>
                </span>
              )}
              {feed.postType === "NWS" && (
                <span>
                  {" "}
                  {props.t("feedpost.was_mentioned_in_the")}{" "}
                  <span className="bold-title">{props.t("feedpost.news")}</span>
                </span>
              )}
              {feed.postType === "ART" && (
                <span>
                  {" "}
                  {feed.object.objectType === "1"
                    ? props.t("feedpost.published_a")
                    : props.t("feedpost.mentioned_in_a")}{" "}
                  <span className="bold-title">
                    {props.t("feedpost.newsletter")}
                  </span>
                </span>
              )}
              {feed.postType === "PUB" && (
                <span>
                  {props.t("feedpost.was_mentioned_in_an")}{" "}
                  <span className="bold-title">
                    {props.t("feedpost.academic_publication")}
                  </span>
                </span>
              )}
              {feed.postType === "DEB" && (
                <span>
                  {props.t("feedpost.debated_in")}{" "}
                  <span className="bold-title">
                    {props.t("legislationcard.parliament")}
                  </span>
                </span>
              )}
              {feed.object.objectType === "2" && feed.post.authorName && (
                <span className="rs-hidden-sm">
                  {" "}
                  by{" "}
                  <span className="bold-title">
                    <Link
                      to={"/leadership-board/profile/" + feed.post.authorId}
                    >
                      {feed.post.authorName}
                    </Link>
                  </span>
                </span>
              )}
            </div>
          )}
          {props.pubView === "true" && (
            <div className="pull-left">
              <span className="bold-title title">{feed.post?.source}</span>
            </div>
          )}
          <Whisper
            placement="bottomStart"
            speaker={
              <Tooltip>
                {props.t("feedpost.Sentiment")}: {feed.post?.sentiment}
              </Tooltip>
            }
          >
            <div className="sentiment">
              {feed.post?.sentiment?.toLowerCase() === "positive" && (
                <div className="circle"></div>
              )}
              {feed.post?.sentiment?.toLowerCase() === "negative" && (
                <div className="circle negative"></div>
              )}
              {feed.post?.sentiment?.toLowerCase() === "neutral" && (
                <div className="circle neutral"></div>
              )}
              {feed.post?.sentiment === "Insufficient Data" && (
                <CPAIcon
                  icon="insufficientdata"
                  size={25}
                  style={{ marginTop: -5 }}
                />
              )}
            </div>
          </Whisper>
          <div className="pull-right pt-2 timeStamp">
            <ReactTimeAgo date={feed.timestamp * 1000} locale="en-US" />
          </div>
          <div className="clearfix"></div>
          <Divider className="mb16px"></Divider>
          <FlexboxGrid>
            <FlexboxGrid.Item
              componentClass={Col}
              colspan={24}
              md={3}
              smHidden
              className="p-2"
            >
              {feed.object.objectType === "1" && (
                <Link to={"/leadership-board/profile/" + feed.object.id}>
                  <Avatar
                    className={
                      "profile-pic pull-left " +
                      (feed.object.adminLevel === "1" && "federal")
                    }
                    circle
                  >
                    <img
                      alt="Profile Pic"
                      src={
                        "https://cpa-app-assets.s3.amazonaws.com/object-images/" +
                        feed.object.id +
                        ".jpeg"
                      }
                    />
                  </Avatar>
                </Link>
              )}

              {feed.object.objectType === "2" && (
                <Whisper
                  className="pull-left"
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("feedpost.status")}:{" "}
                      <span className="bold-title">
                        {feed.details?.recentStatus}
                      </span>
                      <br />
                      {props.t("feedpost.last_update")}:{" "}
                      <span className="bold-title">
                        {feed.details?.recentUpdate}
                      </span>
                    </Tooltip>
                  }
                >
                  <Link to={"/legislation-board/profile/" + feed.object.id}>
                    {" "}
                    <Circle
                      className="mt-2"
                      percent={feed.details?.progressBar}
                      width={80}
                      strokeColor={getstatusColor(feed.details?.progressBar)}
                      showInfo={false}
                      trailWidth={12}
                      strokeWidth={13}
                      strokeLinecap="butt"
                    />{" "}
                  </Link>
                </Whisper>
              )}
              {feed.object.objectType === "3" && <div className="big-gray" />}
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              componentClass={Col}
              colspan={24}
              md={21}
              className="pr-0 news-content"
            >
              {feed.post.title && (
                <span className="bold-title">
                  {feed.postType !== "PUB" && feed.post.source && (
                    <span>{feed.post.source} | </span>
                  )}
                  {feed.post.title}
                </span>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: urlify(feed.post.content) }}
              />
              <div>
                {feed.post.retweetCount && (
                  <div className="pull-left mr-2 retweet">
                    <CPAIcon icon="retweet" size={20} viewBox="40 32 40 40" />{" "}
                    {feed.post.retweetCount}
                  </div>
                )}
                {feed.post.favoriteCount && (
                  <div className="pull-left retweet">
                    <CPAIcon icon="heart" size={19} viewBox="20 18 100 100" />{" "}
                    {feed.post.favoriteCount}
                  </div>
                )}
              </div>
              <div className="clearfix"></div>
              {feed.mentions && (
                <div>
                  <Divider className="mtb-8px"></Divider>
                  <div>
                    <div className="pull-left pt-2 subtitle">
                      {props.t("feedpost.Mentions")}:
                    </div>
                    <div className="mentions pull-left ml-10px">
                      {feed.mentions?.map((mention, index) => (
                        <Whisper
                          key={index}
                          placement="bottomStart"
                          speaker={
                            <Tooltip>
                              {mention.objectType === "1" && "Politician"}
                              {mention.objectType === "2" && "Bill"}
                              {mention.objectType === "4" && "Company"}
                            </Tooltip>
                          }
                        >
                          <div className="pull-left mr-2">
                            <div className="tag-style">
                              {mention.objectType === "1" ? (
                                <Link
                                  to={"/leadership-board/profile/" + mention.id}
                                >
                                  {mention.name}
                                </Link>
                              ) : (
                                mention.name
                              )}
                              {mention.objectType === "2" ? (
                                <Link
                                  to={
                                    "/legislation-board/profile/" + mention.id
                                  }
                                >
                                  {mention.billNumber}
                                </Link>
                              ) : (
                                mention.billNumber
                              )}
                            </div>
                          </div>
                        </Whisper>
                      ))}
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              )}
              {feed.post.issues && (
                <div>
                  <Divider className="mtb-8px"></Divider>
                  <div>
                    <div className="pull-left pt-2 mr-25px subtitle">
                      {props.t("feedpost.Issues")}:
                    </div>
                    <div
                      className="issues pull-left"
                      style={{ marginTop: "-5px" }}
                    >
                      {feed.post.issues?.map((issue, index) => (
                        <div className="pull-left mr-2" key={index}>
                          <div className="tag-style mt-2">{issue}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </FlexboxGrid.Item>
          </FlexboxGrid>
          <div className="clearfix" />
          <Divider className="mtb-8px"></Divider>
          <div className="mt-3 pb-2">
            <div className="unfollow">
              {feed.subId && feed.object.objectType !== "3" && (
                <button
                  className="button-link"
                  onClick={() => unFollow(feed.subId)}
                >
                  {" "}
                  <CPAIcon
                    icon="unfollow"
                    size={20}
                    viewBox="20 25 100 100"
                    color="#BFBFBF"
                  />{" "}
                  {props.t("shared.unfollow")}{" "}
                  {feed.object.objectType === "1"
                    ? props.t("feedpost.leader")
                    : props.t("feedpost.legislation")}
                </button>
              )}
            </div>
            <div className="unfollow pull-right">
              <a href={feed.post.url} target="_blank" rel="noopener noreferrer">
                <CPAIcon
                  icon="external"
                  size={20}
                  viewBox="20 25 100 100"
                  color="#BFBFBF"
                />{" "}
                {feed.postType === "TWT" && feed.post.url && (
                  <span>{props.t("feedpost.go_to_tweet")}</span>
                )}
                {feed.postType === "NWS" && feed.post.url && (
                  <span>{props.t("feedpost.go_to_news_article")}</span>
                )}
                {feed.postType === "ART" && feed.post.url && (
                  <span>{props.t("feedpost.go_to_newsletter")}</span>
                )}
                {feed.postType === "PUB" && feed.post.url && (
                  <span>{props.t("feedpost.go_to_publication")}</span>
                )}
                {feed.postType === "DEB" && feed.post.url && (
                  <span>{props.t("feedpost.go_to_hansard")}</span>
                )}
              </a>
            </div>
          </div>
        </div>
      )}
      {!feed && (
        <Paragraph style={{ marginTop: 30, marginBottom: 30 }} graph="circle" />
      )}
    </Panel>
  );
}
export default withNamespaces()(FeedPost);
