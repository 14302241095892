import React from "react";
import "./Status.css";
import {
  Panel,
  Divider,
  Grid,
  Row,
  Col,
  Placeholder,
  Loader,
  Progress,
} from "rsuite";
import { getstatusColor } from "../../../../services/utilityService";
import { withNamespaces } from "react-i18next";

function Status(props) {
  const object = {};
  const loading = false;
  const { Paragraph } = Placeholder;
  const { Circle } = Progress;

  const showObject = () => (
    <div className="panel-header">
      <div className="title">{props.t("status.status")}</div>
      <Divider className="m-0 mb-1 divider" />
      <div className="pt-2">
        <div className="content-style">
          <Grid fluid className="grid-style pb-2">
            <Row>
              <Col lg={4} xs={4}>
                <Circle
                  className="mt-2"
                  percent={props.data?.data?.progressBar}
                  width={50}
                  strokeColor={getstatusColor(props.data?.data?.progressBar)}
                  showInfo={false}
                  trailWidth={12}
                  strokeWidth={13}
                  strokeLinecap="butt"
                />
              </Col>
              <Col lg={20} xs={20} className="pt-3">
                {props.t("status.current_status")}:{" "}
                <b>{props.data?.data?.recentStatus}</b>
                <br />
                {props.t("status.date")}:{" "}
                <b>{props.data?.data?.recentUpdate}</b>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    </div>
  );

  return (
    <Panel className="Status no-padding" shaded>
      {loading && <Loader backdrop center content="loading" />}
      {!object && (
        <div>
          <Paragraph style={{ margin: 10 }} />
          <Paragraph style={{ margin: 30 }} graph="circle" />
        </div>
      )}
      {object && showObject()}
    </Panel>
  );
}
export default withNamespaces()(Status);
