import { Button, FlexboxGrid } from "rsuite";
import UserCard from "../../../../components/shared/users/UserCard/UserCard";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { withNamespaces } from "react-i18next";

function ContactAdmins({ t }) {
  const [users, setUsers] = useState();

  useEffect(() => {
    loadAdmins();
  }, []);

  const loadAdmins = () => {
    API.get("api", "/users", null).then((users) => {
      setUsers(users.filter((user) => user.role === "admin"));
    });
  };

  return (
    <>
      <FlexboxGrid>
        {users &&
          users.map((user) => (
            <FlexboxGrid.Item key={user.id} className="p-10" colspan={8}>
              <UserCard user={user} />
            </FlexboxGrid.Item>
          ))}
      </FlexboxGrid>
      <Button className="load-more">{t("shared.load_more")}</Button>
    </>
  );
}
export default withNamespaces()(ContactAdmins);
