import { API } from "aws-amplify";

export function getObjects(type) {
  return API.get("api", "/objects?type=" + type, null).then((objects) => {
    return objects;
  });
}

export function getObject(id) {
  return API.get("api", "/objects/" + id, null);
}

export function getObjectData(id, issue, issueArray = null) {
  return API.get(
    "backend",
    "/get-object-insights/?id=" +
      id +
      (issue ? "&issue=" + issue : "") +
      (issueArray ? "&issueArray=" + issueArray : ""),
    null
  );
}

export function getObjectsIndexes(type, issue, actorId = null) {
  return API.get(
    "backend",
    "/get-object-rank/?objectType=" +
      type +
      (issue ? "&issue=" + issue : "") +
      (actorId ? "&actor=" + actorId : ""),
    null
  );
}

export function getMeta(type = null) {
  return API.get("api", "/meta/", null).then((filters) => {
    filters = type ? filters.filter((f) => f.type === type) : filters;

    let object = {};

    filters.forEach((f) => {
      object[f.value] = f.key;
    });

    return object;
  });
}

export function getObjectWithData(id, issue = null, issueArray = null) {
  return getObject(id).then((object) => {
    return getObjectData(id, issue, issueArray).then((data) => {
      return { ...object, data: data };
    });
  });
}
