import React, { useCallback, useEffect, useState } from "react";
import "./LeadershipDetails.css";
import { useParams } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import ObjectDetailsFilters from "../../../components/shared/objects/ObjectDetailsFilters/ObjectDetailsFilters";
import { getObjectWithData } from "../../../services/objectService";
import LeadershipProfileInfo from "../../../components/shared/objects/LeadershipProfileInfo/LeadershipProfileInfo";
import { API } from "aws-amplify";
import LineChart from "../../../components/core/LineChart";
import { Panel, Placeholder } from "rsuite";
import { currentUser } from "../../../services/userService";
import Posts from "../../../components/shared/objects/Posts/Posts";

function LeadershipDetails({ t }) {
  const { id } = useParams();
  const [object, setObject] = useState();
  const [subscriptions, setSubscriptions] = useState([]);
  const user = currentUser();
  const { Paragraph } = Placeholder;

  const issueChanged = (issue) => {
    getObjectWithData(id, issue).then((object) => {
      setObject(object);
      getChartData(object);
    });
  };

  const getChartData = (ob) => {
    let array = [];
    for (const [key] of Object.entries(ob.data.shareOfVoice)) {
      let obj = {};
      obj.name = key;
      obj.marker = { enabled: false };
      let unordered = ob.data.shareOfVoice[key];
      const ordered = Object.keys(unordered)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unordered[key];
          return obj;
        }, {});

      obj.data = Object.values(ordered);
      obj.headers = Object.keys(ordered).map(
        (h) =>
          new Date(h).toGMTString().split(" ")[1] +
          "-" +
          new Date(h).toGMTString().split(" ")[2]
      );
      array.push(obj);
    }

    return array;
  };

  const getSubscriptions = useCallback(() => {
    API.get("api", "/subscriptions/" + user.id, null).then((subs) => {
      setSubscriptions(subs);
    });
  }, [user.id]);

  const getSubId = (objectId) => {
    return subscriptions.find((sub) => sub.objectId === objectId)?.id;
  };

  const loadObject = () => {
    getSubscriptions();
    getObjectWithData(id).then((object) => {
      setObject(object);
      getChartData(object);
    });
  };

  useEffect(() => {
    loadObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getSubscriptions]);

  return (
    <div>
      {object && (
        <div>
          <div className="LeadershipDetails mb-4">
            <div className="container pt-4 pb-4">
              <ObjectDetailsFilters
                object={object}
                issueChanged={issueChanged}
                subId={getSubId(object.id)}
                reloadObject={loadObject}
              />
              <div className="profile-info">
                <LeadershipProfileInfo object={object} />
                <br />
              </div>
              <div>
                <Panel className="leadership-profile no-padding" shaded>
                  <LineChart
                    title={t("leadershipdetails.share_of_voice")}
                    data={getChartData(object)}
                  />
                </Panel>
              </div>
            </div>
          </div>
          <Posts object={object} />
        </div>
      )}
      {!object && (
        <div className="LeadershipDetails">
          <div className="container pt-4 pb-4">
            <Paragraph
              style={{ margin: 30, width: "100%" }}
              rows={3}
              graph="circle"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default withNamespaces()(LeadershipDetails);
