import React, { useEffect, useState } from "react";
import "./Home.css";
import { withNamespaces } from "react-i18next";
import { API } from "aws-amplify";
import { currentUser } from "../../services/userService";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Button,
  Col,
  Divider,
  FlexboxGrid,
  Icon,
  Placeholder,
  Panel,
} from "rsuite";
import FeedPost from "../../components/shared/feed/FeedPost/FeedPost";
import Top3Actors from "../../components/shared/objects/Top3Actors/Top3Actors";
import Top3LegislationPending from "../../components/shared/objects/Top3LegislationPending/Top3LegislationPending";
import { appEmitter, chunk } from "../../services/utilityService";
import NewsFeedFilters from "../../components/shared/NewsFeedFilters/NewsFeedFilters";
import loader from "../../assets/Images/loader.gif";
import Top3Issues from "../../components/shared/objects/Top3Issues/Top3Issues";
import { getObjectsIndexes } from "../../services/objectService";
import CPAIcon from "../../components/core/CPAIcons";
import LoadingPlaceholders from "../../components/shared/LoadingPlaceholders/NewsFeed/LoadingPlaceholders";

function Home({ t }) {
  const user = currentUser();
  const [feed, setFeed] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [top3Actors, setTop3Actors] = useState([]);
  const [top3Legislations, setTop3Legislations] = useState([]);
  const [top3Issues, setTop3Issues] = useState([]);
  const [filters, setFilters] = useState();
  const [chunkFeed, setChunkFeed] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [lastKey, setLastKey] = useState();
  const [newFeeds, setNewFeeds] = useState(0);
  const [scroll, setScroll] = useState(0);
  const [counter, setCounter] = useState(0);
  const { Paragraph } = Placeholder;

  const getFeed = (filters, key = null) => {
    setIsLoading(true);
    let f = [];

    if (lastKey || key) {
      f.push("key=" + (key?.id || lastKey?.id));
      f.push("timestamp=" + (key?.timestamp || lastKey?.timestamp));
      f.push("userId=" + (key?.userId || lastKey?.userId));
    }

    if (filters?.issue) {
      f.push("issue=" + filters.issue);
    }

    if (filters?.type) {
      f.push("type=" + filters.type);
    }

    if (filters?.objectType) {
      f.push("objectType=" + filters.objectType);
    }

    let params = f.length ? "?" + f.join("&") : "";
    API.get("api", "/feed/" + user.id + params, null).then((f) => {
      setFeed((feed) => (feed.length ? feed.concat(f.data) : f.data));
      if (f.lastKey) {
        getFeed(filters, f.lastKey);
      }

      setIsLoading(false);
    });
  };

  const getTop3Actors = (filters) => {
    let p = filters?.issue ? "&issue=" + filters.issue : "";
    API.get("api", "/top3/?type=1" + p, null).then((top3) => {
      setTop3Actors(top3);
    });
  };

  const getTop3Legislations = (filters) => {
    let p = filters?.issue ? "&issue=" + filters.issue : "";
    API.get("api", "/top3/?type=2" + p, null).then((top3) => {
      setTop3Legislations(top3);
    });
  };

  const getTop3Issues = (filters) => {
    getObjectsIndexes("5", filters?.issue).then((top) => {
      top = Object.entries(top)
        .sort(function (a, b) {
          return a[1] - b[1];
        })
        .slice(0, 3);
      setTop3Issues(top);
    });
  };

  const handleScroll = () => {
    setScroll(document.getElementById("scrollableDiv").scrollTop);
  };

  const callBack = () => {
    setNewFeeds((newFeeds) => newFeeds + 1);
  };

  const handleFilters = (filters) => {
    setFeed([]);
    setChunkFeed([]);
    setLastKey(null);
    setFilters(filters);
    setCounter(0);
    setHasMore(true);
  };

  const getChunkFeed = () => {
    setHasMore(!!chunk(feed, 20)[counter + 1]?.length);
    setCounter((counter) => counter + 1);
    setChunkFeed((chunkFeed) =>
      chunk(feed, 20)[counter + 1]?.length
        ? chunkFeed.concat(chunk(feed, 20)[counter + 1])
        : chunkFeed
    );
  };

  useEffect(() => {
    setChunkFeed(chunk(feed, 20)[counter] || []);
    setHasMore(!!chunk(feed, 20)[counter + 1]?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed]);

  useEffect(() => {
    if (filters) {
      getFeed(filters);
      getTop3Actors(filters);
      getTop3Legislations(filters);
      getTop3Issues(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    appEmitter.on("newFeed", callBack);
    document
      .getElementById("scrollableDiv")
      .addEventListener("scroll", handleScroll);

    return function cleanupListener() {
      document
        .getElementById("scrollableDiv")
        .removeEventListener("scroll", handleScroll);
      appEmitter.removeListener("newFeed", callBack);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Home">
      {newFeeds !== 0 && (
        <button
          onClick={() => window.location.reload()}
          className="new-post pr-3 pl-3"
        >
          New posts <CPAIcon icon="newpostarrow" size={20} />
        </button>
      )}
      <div className="container">
        <h5 className="float-left mt-19px header-style">
          {t("dashboard.news_feed")}
        </h5>
        <div className="float-right filters-style">
          <NewsFeedFilters applyFilters={handleFilters} showObjectType={true} />
        </div>
        <div className="clearfix" />
        <Divider className="divider" />
        <FlexboxGrid>
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={24}
            md={16}
            className="feed pt-3"
            style={{ padding: 0 }}
          >
            <div
              id="scrollableDiv"
              className="scrollable"
              style={{
                height:
                  window.innerHeight - (window.innerWidth < 1420 ? 70 : 115),
              }}
            >
              {(chunkFeed.length !== 0 || !isLoading) && (
                <InfiniteScroll
                  scrollableTarget="scrollableDiv"
                  className="pr-2 pl-2"
                  dataLength={chunkFeed?.length}
                  next={() => getChunkFeed()}
                  hasMore={hasMore}
                  loader={
                    <div style={{ textAlign: "center" }}>
                      <img src={loader} alt="Loading" width={70} />
                    </div>
                  }
                  endMessage={
                    <p style={{ float: "left" }}>
                      <b>{t("dashboard.no_more_feeds_available")}</b>
                    </p>
                  }
                >
                  {chunkFeed.map((feedId, index) => (
                    <FeedPost key={index} feedId={feedId} />
                  ))}
                </InfiniteScroll>
              )}
              {isLoading && <LoadingPlaceholders />}
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={24}
            md={8}
            smHidden
            style={{ padding: 0 }}
            className="pt-3 pl-4"
          >
            {top3Issues.length === 0 &&
              top3Actors.length === 0 &&
              top3Legislations.length === 0 && (
                <div>
                  <Panel className="mt-1" shaded>
                    <Paragraph
                      style={{ margin: 30, width: "100%" }}
                      rows={3}
                      graph="circle"
                    />
                  </Panel>
                </div>
              )}

            {top3Actors.length !== 0 && <Top3Actors data={top3Actors} />}

            <div className="mt-4">
              {top3Legislations.length !== 0 && (
                <Top3LegislationPending data={top3Legislations} />
              )}
            </div>
            {top3Issues.length !== 0 && (
              <div className="mt-4">
                <Top3Issues data={top3Issues} />
              </div>
            )}
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
      {scroll > 300 && (
        <Button
          onClick={() =>
            document.getElementById("scrollableDiv").scrollTo(0, 0)
          }
          className="scroll-top"
        >
          <Icon icon="sort-up"></Icon>
        </Button>
      )}
    </div>
  );
}

export default withNamespaces()(Home);
