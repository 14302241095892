import React from "react";
import "./Profile.css";
import { withNamespaces } from "react-i18next";
import Settings from "./tabs/Settings";
import Subscriptions from "./tabs/Subscriptions";
import Management from "./tabs/Management";
import ContactAdmins from "./tabs/ContactAdmins";
import { isAdmin } from "../../../services/userService";
import { ButtonToolbar, ButtonGroup, Button, Divider } from "rsuite";
import { Link, useLocation } from "react-router-dom";

function Profile({ t }) {
  const location = useLocation();

  const getCurrentTab = () => location.pathname.split("/")[1];

  const getTitle = () => {
    switch (getCurrentTab()) {
      case "settings":
        return isAdmin() ? t("profile.admin_profile") : t("profile.profile");
      case "subscriptions":
        return t("profile.my_subscriptions");
      case "contact-admins":
        return t("profile.contact_admins");
      case "management":
        return t("profile.platform_management");
      default:
        return t("profile.profile");
    }
  };

  return (
    <div className="Profile">
      <div className="container pb-4">
        <div className="pull-left">
          <h5 className="header-style ">{getTitle()}</h5>
        </div>
        <ButtonToolbar className="pull-right toolbar-style">
          <ButtonGroup className="user-menu">
            <Link to="/settings" id="btn_settings">
              <Button
                className="border-style"
                appearance={
                  getCurrentTab() === "settings" ? "default" : "subtle"
                }
              >
                {isAdmin() ? "Admin" : "User"} {t("profile.profile")}
              </Button>
            </Link>
            <Link to="/subscriptions" id="btn_subscriptions">
              <Button
                className="border-style"
                appearance={
                  getCurrentTab() === "subscriptions" ? "default" : "subtle"
                }
              >
                {t("profile.my_subscriptions")}
              </Button>
            </Link>
            {!isAdmin() && (
              <Link to="/contact-admins">
                <Button
                  className="border-style"
                  appearance={
                    getCurrentTab() === "contact-admins" ? "default" : "subtle"
                  }
                >
                  {t("profile.contact_admins")}
                </Button>
              </Link>
            )}
            {isAdmin() && (
              <Link to="/management">
                <Button
                  className="border-style"
                  appearance={
                    getCurrentTab() === "management" ? "default" : "subtle"
                  }
                >
                  {t("profile.platform_management")}
                </Button>
              </Link>
            )}
          </ButtonGroup>
        </ButtonToolbar>
        <Divider className="divider" />
        {getCurrentTab() === "settings" && <Settings />}
        {getCurrentTab() === "subscriptions" && <Subscriptions />}
        {getCurrentTab() === "management" && <Management />}
        {getCurrentTab() === "contact-admins" && <ContactAdmins />}
      </div>
    </div>
  );
}

export default withNamespaces()(Profile);
