import { API } from "aws-amplify";

export function currentUser() {
  return JSON.parse(localStorage.getItem("currentUser"));
}

export function setCurrentUser(user) {
  localStorage.setItem("currentUser", JSON.stringify(user));
}

export function clearCurrentUser() {
  localStorage.removeItem("currentUser");
}

export function isLoggedIn() {
  return !!localStorage.getItem("currentUser");
}

export function isAdmin() {
  return userCanAccess("admin");
}

export function userCanAccess(routeRole) {
  if (routeRole) {
    return currentUser() && currentUser().role
      ? currentUser().role === routeRole
      : false;
  } else {
    return !!(currentUser() && currentUser().role);
  }
}

export function userCanBeDeleted(user) {
  return !["sameh.hady@gmail.com"].includes(user.email);
}

export function buildUserAvatarUrl(userId) {
  return `https://cpa-app-assets.s3.amazonaws.com/user-images/${userId}.jpeg?${userId}`;
}

export async function checkUser() {
  let getUserDetails = await API.get("api", "/users/" + currentUser().id, null);
  setCurrentUser(getUserDetails);
  return getUserDetails;
}

export async function checkEmail(email) {
  let userEmail = await API.get("api", "/users/email/" + email, null);
  return userEmail;
}
