import React from "react";
import "./ActorsCard.css";
import {
  Panel,
  Divider,
  Avatar,
  Icon,
  Placeholder,
  Loader,
  Whisper,
  Tooltip,
} from "rsuite";
import useObject from "../../../../hooks/object.hook";
import avatar from "../../../../assets/Images/avatar-placeholder.png";
import { Link } from "react-router-dom";
import CPAIcon from "../../../core/CPAIcons";
import { withNamespaces } from "react-i18next";

function ActorsCard(props) {
  const { object, objectInfo, follow, unFollow, loading } = useObject(props);
  const { Paragraph } = Placeholder;
  const subId = props.subId;

  const addDefaultAvatar = (ev) => {
    ev.target.src = avatar;
  };

  const showObject = () => (
    <div className="panel-header">
      <Whisper
        placement="bottom"
        speaker={<Tooltip>{props.t("filters.rank")}</Tooltip>}
      >
        <div className="score">
          {props.objectRank || objectInfo?.activityRank}
        </div>
      </Whisper>
      <div className="profile-title pull-left">
        <Link to={"leadership-board/profile/" + object.id}>{object.name}</Link>
        <Whisper
          placement="bottom"
          speaker={
            <Tooltip>
              {props.t("filters.monthly_change")}:{" "}
              {objectInfo?.activityIndexChange}%
            </Tooltip>
          }
        >
          <span>
            {objectInfo?.activityIndexChange < 0 ? (
              objectInfo?.activityIndexChange === 0 ? (
                <span style={{ color: "grey" }}>
                  <CPAIcon icon="neutralarrow" size={20} />
                </span>
              ) : (
                <CPAIcon color="#B14E4E" icon="down" size={20} />
              )
            ) : objectInfo?.activityIndexChange === 0 ? (
              <span style={{ color: "grey" }}>
                <CPAIcon icon="neutralarrow" size={20} />
              </span>
            ) : (
              <CPAIcon color="#53818A" icon="up" size={20} />
            )}
          </span>
        </Whisper>
      </div>
      <div>
        <Whisper
          placement="bottom"
          speaker={
            <Tooltip>
              {props.t("shared.vaping")}: {objectInfo?.sentimentVaping}
            </Tooltip>
          }
        >
          <Avatar
            size="xs"
            className={
              objectInfo?.sentimentVaping === "Negative"
                ? "icon-active pull-right m-1 "
                : "pull-right m-1 icon-positive"
            }
            style={{
              backgroundColor:
                objectInfo?.sentimentVaping === "Insufficient Data"
                  ? "lightgrey"
                  : objectInfo?.sentimentVaping === "Neutral"
                  ? "grey"
                  : "",
            }}
            circle
          >
            <CPAIcon color="#fff" icon="vaping" size={20} strokeWidth={3} />
          </Avatar>
        </Whisper>
        <Whisper
          placement="bottom"
          speaker={
            <Tooltip>
              {props.t("shared.cannabis")}: {objectInfo?.sentimentCannabis}
            </Tooltip>
          }
        >
          <Avatar
            size="xs"
            className={
              objectInfo?.sentimentCannabis === "Negative"
                ? "icon-active pull-right m-1 "
                : "pull-right m-1 icon-positive"
            }
            style={{
              backgroundColor:
                objectInfo?.sentimentCannabis === "Insufficient Data"
                  ? "lightgrey"
                  : objectInfo?.sentimentCannabis === "Neutral"
                  ? "grey"
                  : "",
            }}
            circle
          >
            <CPAIcon color="#fff" icon="cannabis" size={20} strokeWidth={3} />
          </Avatar>
        </Whisper>
        <Whisper
          placement="bottom"
          speaker={
            <Tooltip>
              {props.t("shared.smoking")}: {objectInfo?.sentimentSmoking}
            </Tooltip>
          }
        >
          <Avatar
            size="xs"
            className={
              objectInfo?.sentimentSmoking === "Negative"
                ? "icon-active pull-right m-1 "
                : "pull-right m-1 icon-positive"
            }
            style={{
              backgroundColor:
                objectInfo?.sentimentSmoking === "Insufficient Data"
                  ? "lightgrey"
                  : objectInfo?.sentimentSmoking === "Neutral"
                  ? "grey"
                  : "",
            }}
            circle
          >
            <CPAIcon color="#fff" icon="smoking" size={20} strokeWidth={4} />
          </Avatar>
        </Whisper>
      </div>
      <div className="clearfix" />
      <Divider className="m-0" />
      <div>
        <div className="profile-type">
          <Whisper
            placement="bottomStart"
            speaker={<Tooltip>{object.title}</Tooltip>}
          >
            <span>{object.title}</span>
          </Whisper>
          <br />
          <span className="profile-type-regular">
            {object.politicalAffiliation}
          </span>
        </div>
        <span className="admin-level">
          {object?.adminLevel === "1"
            ? props.t("leadership_profile_info.federal")
            : object?.adminLevel === "2"
            ? props.t("leadership_profile_info.provincial")
            : ""}
        </span>
        <div className="clearfix" />
        <Divider className="m-0" />
        <div className="profile-pnl">
          <Link to={"leadership-board/profile/" + object.id}>
            <Avatar
              className={
                "profile-pic pull-left " +
                (object.adminLevel === "1" && "federal")
              }
              circle
            >
              <img
                alt="Profile Pic"
                width="80"
                height="80"
                onError={addDefaultAvatar}
                src={
                  "https://cpa-app-assets.s3.amazonaws.com/object-images/" +
                  object.id +
                  ".jpeg"
                }
              />
            </Avatar>
          </Link>
          {objectInfo ? (
            <div className="pull-left profile-info-l">
              {objectInfo.twitterFollowerss && (
                <div className="pb8">
                  <Icon icon="twitter" className="mr-2" />{" "}
                  {objectInfo.twitterFollowers}{" "}
                  {props.t("actorscard.followers")}
                </div>
              )}
              {objectInfo.legilationsIntroduced &&
                objectInfo.legislationsCollaborated && (
                  <Whisper
                    placement="bottomStart"
                    speaker={
                      <Tooltip>
                        {objectInfo?.legilationsIntroduced}{" "}
                        {props.t("actorscard.legislation_passed")}
                        <br />
                        {objectInfo?.legislationsCollaborated}{" "}
                        {props.t("actorscard.legislation_collaborations")}
                      </Tooltip>
                    }
                  >
                    <div className="pb8">
                      <CPAIcon
                        icon="legislation"
                        size={20}
                        className="icon-mr"
                        color="#000"
                      />
                      {objectInfo.legilationsIntroduced}
                      <span className="separator">|</span>
                      {objectInfo.legislationsCollaborated}
                    </div>
                  </Whisper>
                )}
              <div className="pb8 overflow-txt">
                <CPAIcon
                  icon="attention"
                  size={20}
                  className="icon-mr"
                  color="#000"
                />
                {objectInfo.topIssues.join(", ")}
              </div>
              <div className="pb8">
                <CPAIcon
                  icon="megaphone"
                  className="icon-mr"
                  size={20}
                  color="#000"
                />
                {objectInfo.newsMentions}
                <span className=" count-style">
                  {" "}
                  {props.t("actorscard.news")}{" "}
                </span>
                <span className="separator">|</span>
                <span>
                  {objectInfo.tweetCount}
                  <span className="count-style">
                    {props.t("actorscard.tweets")}
                  </span>
                </span>
              </div>
              <div className="pb8 overflow-txt">
                <CPAIcon
                  icon="mapmarker"
                  size={20}
                  className="icon-mr"
                  color="#000"
                />
                {object.riding}, {object.province}
              </div>
            </div>
          ) : (
            <Paragraph className="p-3" />
          )}
        </div>
        <div className="clearfix" />
        <Divider className="m-0" />
        <div
          className="follow-link cursor"
          onClick={() =>
            subId ? unFollow(subId) : follow(object.id, object.objectType)
          }
        >
          <CPAIcon
            className="mr-1"
            icon={subId ? "unfollow" : "follow"}
            size={20}
            strokeWidth={5}
            color="#b3b3b3"
          />
          {subId ? props.t("shared.unfollow") : props.t("shared.follow")}
        </div>
      </div>
    </div>
  );

  return (
    <Panel className="pnl-actorscard ActorsCard no-padding" shaded>
      {loading && <Loader backdrop center content="loading" />}
      {!object && (
        <div>
          <Paragraph style={{ margin: 10 }} />
          <Paragraph style={{ margin: 30 }} graph="circle" />
        </div>
      )}
      {object && showObject()}
    </Panel>
  );
}

export default withNamespaces()(ActorsCard);
