import React from "react";
import { useLoading, Rings } from "@agney/react-loading";

const Indicator = () => {
  const { indicatorEl } = useLoading({
    loading: true,
    indicator: <Rings width="200" />,
  });
  return <>{indicatorEl}</>;
};

export default Indicator;
