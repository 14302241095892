import React, { useEffect, useState } from "react";
import "./Posts.css";
import { withNamespaces } from "react-i18next";
import { API } from "aws-amplify";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Col, Divider, FlexboxGrid, Icon } from "rsuite";
import FeedPost from "../../../../components/shared/feed/FeedPost/FeedPost";
import Top3LegislationPending from "../../../../components/shared/objects/Top3LegislationPending/Top3LegislationPending";
import { chunk } from "../../../../services/utilityService";
import NewsFeedFilters from "../../../../components/shared/NewsFeedFilters/NewsFeedFilters";
import loader from "../../../../assets/Images/loader.gif";
import ObjectIntroduceBy from "../ObjectIntroduceBy/ObjectIntroduceBy";
import Status from "../Status/Status";
import CompaniesCited from "../CompaniesCited/CompaniesCited";
import { getObjectsIndexes } from "../../../../services/objectService";
import Top3Issues from "../Top3Issues/Top3Issues";

function Posts(props) {
  const id = props.object.id;
  const [feed, setFeed] = useState([]);
  const [top3Issues, setTop3Issues] = useState([]);
  const [top3Legislations, setTop3Legislations] = useState([]);
  const [filters, setFilters] = useState();
  const [chunkFeed, setChunkFeed] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scroll, setScroll] = useState(0);
  const [counter, setCounter] = useState(0);
  const getFeed = (filters, key = null) => {
    let f = [];

    if (key) {
      f.push("id=" + key?.id);
      f.push("timestamp=" + key?.timestamp);
      f.push("objectId=" + key?.objectId);
    }

    if (filters?.issue) {
      f.push("issue=" + filters.issue);
    }

    if (filters?.type) {
      f.push("type=" + filters.type);
    }

    let params = f.length ? "?" + f.join("&") : "";
    API.get("api", "/posts/" + id + params, null).then((f) => {
      setFeed((feed) => (feed.length ? feed.concat(f.data) : f.data));
      if (f.lastKey && Object.entries(filters).length) {
        getFeed(filters, f.lastKey);
      }
    });
  };

  const getTop3Issues = (filters) => {
    getObjectsIndexes(
      "5",
      filters?.issue,
      props.pubView === "true" ? null : id
    ).then((top) => {
      top = Object.entries(top)
        .sort(function (a, b) {
          return a[1] - b[1];
        })
        .slice(0, 3);
      setTop3Issues(top);
    });
  };

  const getTop3Legislations = (filters) => {
    let p = filters?.issue ? "&issue=" + filters.issue : "";
    p = p + (id ? "&actor=" + id : "");
    API.get("api", "/top3/?type=2" + p, null).then((top3) => {
      setTop3Legislations(top3);
    });
  };

  const handleScroll = () => {
    setScroll(document.documentElement.scrollTop);
  };

  const handleFilters = (filters) => {
    setFeed([]);
    setChunkFeed([]);
    setFilters(filters);
    setCounter(0);
    setHasMore(true);
  };

  const getChunkFeed = () => {
    setHasMore(!!chunk(feed, 20)[counter + 1]?.length);
    setCounter((counter) => counter + 1);
    setChunkFeed((chunkFeed) =>
      chunk(feed, 20)[counter + 1]?.length
        ? chunkFeed.concat(chunk(feed, 20)[counter + 1])
        : chunkFeed
    );
  };

  useEffect(() => {
    if (filters) {
      setFeed([]);
      getFeed(filters);
      if (
        props?.object?.objectType === "1" ||
        props?.object?.objectType === "3"
      ) {
        getTop3Issues(filters);
      }

      if (props?.object?.objectType === "1") {
        getTop3Legislations(filters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setChunkFeed(chunk(feed, 20)[counter] || []);
    setHasMore(!!chunk(feed, 20)[counter + 1]?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed]);

  useEffect(() => {
    handleFilters({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.object]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return function cleanupListener() {
      document.removeEventListener("scroll", handleScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Home">
      <div className="container">
        {props.pubView !== "true" && (
          <h5 className="float-left mt-15px header-style">
            {props.t("posts.posts")}: {props?.object?.name}
          </h5>
        )}
        {props.pubView === "true" && (
          <h5 className="float-left mt-20px  header-style">
            {props.t("dashboard.publications_board")}
          </h5>
        )}
        <div className="float-right mt5px">
          <NewsFeedFilters
            applyFilters={handleFilters}
            pubView={props.pubView}
          />
        </div>
        <div className="clearfix" />
        <Divider className="divider" />
        <FlexboxGrid>
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={24}
            md={16}
            style={{ padding: 0 }}
            className="feed pt-3"
          >
            <div id="scrollableDiv" className="scrollable">
              <InfiniteScroll
                className=" pr-2 pl-2"
                dataLength={chunkFeed?.length}
                next={() => getChunkFeed()}
                hasMore={hasMore}
                loader={
                  <div style={{ textAlign: "center" }}>
                    <img src={loader} alt="Loading" width={70} />
                  </div>
                }
                endMessage={
                  <p style={{ float: "left" }}>
                    <b>{props.t("dashboard.no_more_feeds_available")}</b>
                  </p>
                }
              >
                {chunkFeed.map((postId, index) => (
                  <FeedPost
                    key={index}
                    postId={postId}
                    pubView={props.pubView}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={24}
            md={8}
            className="pt-3 pr-2 pl-4"
          >
            {top3Legislations.length !== 0 &&
              props?.object?.objectType === "1" && (
                <div className="mb-4">
                  <Top3LegislationPending data={top3Legislations} />
                </div>
              )}
            {(props?.object?.objectType === "1" ||
              props?.object?.objectType === "3") && (
              <div className="mb-4">
                <Top3Issues data={top3Issues} />
              </div>
            )}
            {props?.object?.objectType === "2" && (
              <div className="mb-4">
                <ObjectIntroduceBy data={props.object} />
              </div>
            )}
            {props?.object?.objectType === "2" && (
              <div className="mb-4">
                <Status data={props.object} />
              </div>
            )}
            {props?.object?.objectType === "2" &&
              Object.entries(props.object?.data?.companiesCited)?.length !==
                0 && (
                <div className="mb-4">
                  <CompaniesCited data={props.object} />
                </div>
              )}
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
      {scroll > 300 && (
        <Button
          onClick={() => document.documentElement.scrollTo(0, 0)}
          className="scroll-top"
        >
          <Icon icon="sort-up"></Icon>
        </Button>
      )}
    </div>
  );
}

export default withNamespaces()(Posts);
