import React, { useEffect, useState, useRef } from "react";
import "./UserProfileCard.css";
import { API, Auth } from "aws-amplify";
import {
  setCurrentUser,
  currentUser,
  buildUserAvatarUrl,
  clearCurrentUser,
} from "../../../../services/userService";
import {
  buildLetters,
  notify,
  sendMail,
} from "../../../../services/utilityService";
import { useAppContext } from "../../../../libs/contextLib";
import { useHistory } from "react-router-dom";
import {
  Panel,
  Avatar,
  Divider,
  Icon,
  Input,
  Placeholder,
  Tooltip,
  Whisper,
  ButtonToolbar,
  Modal,
  Button,
  Notification,
  Container,
  PanelGroup,
  InputGroup,
} from "rsuite";
import { withNamespaces } from "react-i18next";
import CPAIcon from "../../../core/CPAIcons";

function UserProfileCard(props) {
  const avatarTooltip = (
    <Tooltip>{props.t("userprofilecard.set_avatar")}</Tooltip>
  );
  const locationTooltip = (
    <Tooltip>{props.t("userprofilecard.change_or_remove_location")}</Tooltip>
  );
  const history = useHistory();
  const userObject = currentUser();
  const [fullName, setFullName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [showInvite, setShowInvite] = useState(false);
  const [invitedMail, setInvitedMail] = useState("");
  const [location, setLocation] = useState("");
  const [userPic, setUserPic] = useState("");
  const [user, setUser] = useState(props.user);
  const { Paragraph } = Placeholder;
  const myRef = useRef(null);
  const strongPasswordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_@#$%^&*])(?=.{6,})"
  );

  const { userHasAuthenticated } = useAppContext();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Errors, setErrors] = useState({
    oldpassword: "",
    password: "",
    confirmpassword: "",
  });
  const tooltip = <Tooltip>{props.t("shared.password_rule")}</Tooltip>;

  function validateForm() {
    let errors = {};
    let isValid = true;

    if (oldPassword.length === 0) {
      isValid = false;
      errors["oldpassword"] = props.t(
        "userprofilecard.please_enter_your_current_password"
      );
    }

    if (
      typeof newPassword !== "undefined" &&
      typeof confirmPassword !== "undefined"
    ) {
      if (newPassword !== confirmPassword) {
        isValid = false;
        errors["password"] = props.t("userprofilecard.password_dont_match");
      } else {
        if (!strongPasswordRegex.test(newPassword)) {
          isValid = false;
          errors["password"] = props.t(
            "userprofilecard.please_enter_password_that_match_the_rule"
          );
        }
      }
    }

    if (newPassword.length === 0) {
      isValid = false;
      errors["password"] = props.t(
        "userprofilecard.please_enter_your_new_password"
      );
    }

    if (confirmPassword.length === 0) {
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  }

  const inviteUser = () => {
    sendMail({
      email: invitedMail,
      subject: "[Canadian Political Atlas] Invitation",
      body: "Hello,<br><br>You are invited to join <b>Canadian Political Atlas</b>, please <a href='https://canada.political-atlas.com'/>click here</a>.<br><br>Canadian Political Atlas team<br><br>If link is broken, please copy/paste following url into your browser to signup:<br><br>https://canada.political-atlas.com",
    });
    notify(
      props.t("shared.success"),
      props.t("userprofilecard.your_friend_has_been_invited")
    );
    closeModal();
  };

  const changePassword = async () => {
    setIsLoading(true);
    if (validateForm()) {
      let user = await Auth.currentAuthenticatedUser();
      try {
        await Auth.changePassword(user, oldPassword, newPassword).then(() => {
          setShowChangePassword(false);
          notify(
            props.t("shared.success"),
            props.t("userprofilecard.password_has_been_updated")
          );
          sendMail({
            email: userObject.email,
            subject: "[Canadian Political Atlas] Account updated",
            body: props.t(
              "userprofilecard.this_is_to_confirm_that_your_password_has_been_changed"
            ),
          });
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
        });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        let errors = {};
        var message = e.message;
        if (e.code === "NotAuthorizedException") {
          message = props.t(
            "userprofilecard.please_make_sure_that_you_have_entered_the_correct_current_password"
          );
        }
        errors["confirmpassword"] = message;
        setErrors(errors);
      }
    } else {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setShowChangePassword(false);
    setShowInvite(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setInvitedMail("");
  };

  const handleChangeModalInfo = (value, event) => {
    event.persist();
    if (event.target.id === "oldPassword") {
      setOldPassword(value);
    }

    if (event.target.id === "newPassword") {
      setNewPassword(value);
    }

    if (event.target.id === "confirmPassword") {
      setConfirmPassword(value);
    }

    if (event.target.id === "invitedMail") {
      //wrong
      setInvitedMail(value);
    }
  };

  const verifyDelete = () => {
    Notification.open({
      title: props.t("userprofilecard.delete_account"),
      duration: 0,
      description: (
        <div>
          <p>
            {props.t(
              "userprofilecard.are_you_sure_you_want_to_delete_your_account?"
            )}
          </p>
          <br />
          <ButtonToolbar>
            <Button
              color="red"
              className="delete-user-button"
              onClick={() => {
                closeAccount();
                Notification.close();
              }}
            >
              {props.t("userprofilecard.yes_delete_my_account")}
            </Button>
            <Button
              onClick={() => {
                Notification.close();
              }}
            >
              {props.t("userprofilecard.cancel")}
            </Button>
          </ButtonToolbar>
        </div>
      ),
    });
  };

  const closeAccount = () => {
    setLoading(true);
    let object = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        id: user.id,
        email: user.email,
      },
    };

    API.post("api", "/users/delete", object).then((subs) => {
      clearCurrentUser();
      userHasAuthenticated(false);
      history.push("/login");
    });
  };

  useEffect(() => {
    setFullName(user.fullName || "");
    setJobTitle(user.jobTitle || "");

    setLocation(user.location || "");
    if (user.hasAvatar) {
      setUserPic(buildUserAvatarUrl(user.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, event) => {
    event.persist();
    if (event.target.id === "fullName") {
      setFullName(value);
    } else if (event.target.id === "jobTitle") {
      setJobTitle(value);
    } else if (event.target.id === "location") {
      setLocation(value);
    }
  };

  const handleBlur = (event) => {
    event.persist();

    if (event.key === "Enter") {
      event.target.blur();
    }

    if (
      user[event.target.id] === event.target.value ||
      event.type === "keydown"
    ) {
      return;
    }

    updateField(event.target.id, event.target.value);
  };

  const updateField = (field, value) => {
    let object = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        field: field,
        value: value,
      },
    };

    API.post("api", "/users/field/" + currentUser().id, object).then((user) => {
      notify(
        props.t("shared.success"),
        props.t("userprofilecard.profile_has_been_updated")
      );
      setUser(user);
      setCurrentUser(user);
    });
  };

  const clickElement = (ref) => {
    ref.current.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    );
  };

  const onChange = async (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      let object = {
        body: {
          file: reader.result,
        },
      };
      API.post("api", "/upload/user/" + user.id, object).then((image) => {
        setUserPic(image.Location + "?" + new Date().getTime());
        updateField("hasAvatar", true);
        myRef.current.value = "";
      });
    };

    reader.readAsDataURL(file);
  };

  const deleteAvatar = () => {
    updateField("hasAvatar", false);
    setUserPic(null);
  };

  const showObject = () => (
    <Container className="user-profile">
      <Panel className="inner-panel" shaded>
        <PanelGroup className="p-relative">
          {userPic && (
            <Icon
              className="delete-avatar cursor"
              onClick={deleteAvatar}
              icon="close"
            />
          )}
          <Button
            id="btn_showInviteDialog"
            onClick={() => setShowInvite(true)}
            className="pull-right buttons-font"
          >
            {props.t("userprofilecard.invite_user")}
          </Button>

          <Whisper placement="bottom" trigger="hover" speaker={avatarTooltip}>
            <Avatar
              onClick={() => clickElement(myRef)}
              className="big-avatar pull-left cursor"
              circle
            >
              {fullName &&
                (userPic ? (
                  <img
                    alt={fullName}
                    src={userPic}
                    width="100px"
                    height="100px"
                  />
                ) : (
                  buildLetters(fullName)
                ))}
            </Avatar>
          </Whisper>
          <input
            ref={myRef}
            className="hidden"
            type="file"
            accept="image/jpg,image/png"
            onChange={(e) => onChange(e.target.files[0])}
          />
          <div className="pull-left user-details pb-1">
            <Input
              id="fullName"
              className="text-input bold-text"
              placeholder={props.t("userprofilecard.add_your_name")}
              value={fullName}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleBlur}
            />
            <div className="clearfix" />

            <div className="job-title  pb-1">
              <CPAIcon
                color="#000"
                icon="edit"
                size={20}
                className="text-icon float-left"
              />
              <Input
                id="jobTitle"
                className="text-input input-info-style"
                placeholder={props.t("userprofilecard.add_job_title")}
                value={jobTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleBlur}
              />
            </div>
            <div className="user-email  pb-1">
              <CPAIcon
                color="#000"
                icon="newsletter"
                size={20}
                className="text-icon float-left mrimg-4"
              />
              <span className="email-txt"> {user.email}</span>
            </div>
            <div className="location  pb-1">
              <CPAIcon
                color="#000"
                icon="mapmarker"
                size={20}
                className="text-icon float-left"
              />

              <Whisper
                placement="bottomStart"
                trigger="hover"
                speaker={locationTooltip}
              >
                <Input
                  id="location"
                  className="text-input input-info-style"
                  placeholder={props.t("userprofilecard.add_location")}
                  value={location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleBlur}
                />
              </Whisper>
            </div>
          </div>
        </PanelGroup>
        <PanelGroup className="footer-actions-style">
          <Button
            id="btn_changepassword"
            onClick={() => {
              setShowChangePassword(true);
            }}
          >
            {props.t("userprofilecard.change_password")}
          </Button>
          <Button
            loading={loading}
            className="pull-right"
            style={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#F5F5F5",
              color: "#323232",
            }}
            appearance="subtle"
            onClick={verifyDelete}
          >
            {props.t("userprofilecard.permanently_close_account")}
          </Button>
        </PanelGroup>
        <PanelGroup>
          <Modal show={showChangePassword} onHide={closeModal}>
            <Modal.Header>
              <Modal.Title className="title-dialog-style">
                {props.t("userprofilecard.change_your_password")}
              </Modal.Title>
            </Modal.Header>
            <Divider className="m-0" />
            <Modal.Body>
              <InputGroup>
                <Input
                  id="oldPassword"
                  type="password"
                  placeholder="Enter current password"
                  value={oldPassword}
                  onChange={handleChangeModalInfo}
                  className="txt-style"
                />
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      {" "}
                      {props.t("userprofilecard.enter_your_old_password")}
                    </Tooltip>
                  }
                >
                  <InputGroup.Addon>?</InputGroup.Addon>
                </Whisper>
              </InputGroup>
              <div className="text-danger txt-err">{Errors.oldpassword}</div>
              <InputGroup>
                <Input
                  id="newPassword"
                  type="password"
                  placeholder={props.t("userprofilecard.enter_new_password")}
                  value={newPassword}
                  aria-describedby="basic-addon2"
                  onChange={handleChangeModalInfo}
                  className="input-no-border-rad txt-style"
                />
                <Whisper placement="top" trigger="hover" speaker={tooltip}>
                  <InputGroup.Addon>?</InputGroup.Addon>
                </Whisper>
              </InputGroup>
              <div className="text-danger txt-err">{Errors.password}</div>
              <Input
                id="confirmPassword"
                type="password"
                placeholder={props.t("userprofilecard.confirm_new_password")}
                value={confirmPassword}
                onChange={handleChangeModalInfo}
                className="txt-style"
              />
              <div className="text-danger txt-err">
                {Errors.confirmpassword}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={changePassword}
                id="btn_changepassword_dialog"
                loading={isLoading}
                appearance="default"
                className="txt-style change-pswd-font"
                block
              >
                {props.t("userprofilecard.change_password")}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showInvite} onHide={closeModal}>
            <Modal.Header>
              <Modal.Title className="title-dialog-style">
                {props.t("userprofilecard.invite_a_friend")}
              </Modal.Title>
            </Modal.Header>
            <Divider className="m-0" />
            <Modal.Body>
              <Input
                id="invitedMail"
                placeholder={props.t("userprofilecard.type_email_address")}
                value={invitedMail}
                onChange={handleChangeModalInfo}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                id="btn_sendInvitation"
                onClick={inviteUser}
                appearance="default"
                className="txt-style change-pswd-font"
                block
              >
                {props.t("userprofilecard.send_invite")}
              </Button>
            </Modal.Footer>
          </Modal>
        </PanelGroup>
      </Panel>
    </Container>
  );

  return (
    <>
      {!user && (
        <div>
          <Paragraph style={{ margin: 30 }} graph="circle" />
        </div>
      )}
      {user && showObject()}
    </>
  );
}

export default withNamespaces()(UserProfileCard);
