import React from "react";
import UserProfileCard from "../../../../components/shared/users/UserProfileCard/UserProfileCard";

import { currentUser } from "../../../../services/userService";

export default function Settings() {
  const user = currentUser();

  return (
    <>
      <UserProfileCard user={user} />
    </>
  );
}
