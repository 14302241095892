import React from "react";
import { Route, Redirect } from "react-router-dom";
import { userCanAccess } from "../../services/userService";

export default function Private({ children, ...rest }) {
  return (
    <Route {...rest}>
      {userCanAccess(rest.role) ? children : <Redirect to={`/login`} />}
    </Route>
  );
}
