import React from "react";
import { Placeholder } from "rsuite";
import Indicator from "../Indicator/LoadingIndicator";

const LoadingPlaceholders = () => {
  const { Paragraph } = Placeholder;

  const Paragraphs = () => {
    const arr = [];
    for (let x = 0; x < 6; x++) {
      arr.push(
        <Paragraph
          key={x}
          style={{ margin: 30, width: "100%" }}
          rows={3}
          graph="circle"
        />
      );
    }
    return arr;
  };

  return (
    <>
      <div className="LeadershipDetails">
        <div className="container pt-4 pb-4">
          <Placeholder.Graph active />
          <Paragraphs />
        </div>
      </div>
      <div style={{ position: "fixed", zIndex: "7", top: "35%", left: "50%" }}>
        <Indicator />
      </div>
    </>
  );
};

export default LoadingPlaceholders;
