const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://xlhwes94ik.execute-api.us-east-1.amazonaws.com/dev",
  },
  backendApiGateway: {
    REGION: "us-east-1",
    URL: "https://9ba43zeflh.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_wNhE6HyGa",
    APP_CLIENT_ID: "597r3v2s2fngurs0ii4hdelo01",
    IDENTITY_POOL_ID: "us-east-1:19c2e70d-10a2-4f85-8d40-512d8ee50125",
  },
  pubSub: {
    aws_pubsub_region: "us-east-1",
    aws_pubsub_endpoint:
      "wss://aacd8b6rw73yh-ats.iot.us-east-1.amazonaws.com/mqtt",
  },
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://jlclam85ig.execute-api.us-east-1.amazonaws.com/prod",
  },
  backendApiGateway: {
    REGION: "us-east-1",
    URL: "https://jznsssq2mb.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_wNhE6HyGa",
    APP_CLIENT_ID: "597r3v2s2fngurs0ii4hdelo01",
    IDENTITY_POOL_ID: "us-east-1:19c2e70d-10a2-4f85-8d40-512d8ee50125",
  },
  pubSub: {
    aws_pubsub_region: "us-east-1",
    aws_pubsub_endpoint:
      "wss://aacd8b6rw73yh-ats.iot.us-east-1.amazonaws.com/mqtt",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
