import React, { useState } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import { useFormFields } from "../../../libs/hooksLib";
import LoaderButton from "../../../components/shared/LoaderButton/LoaderButton";
import { Link, useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { Auth, API } from "aws-amplify";
import { onError } from "../../../libs/errorLib";
import { setCurrentUser } from "../../../services/userService";
import { useAppContext } from "../../../libs/contextLib";

function ConfirmationForm({ t }) {
  const { userHasAuthenticated } = useAppContext();
  const [codeIsGood, setCodeIsGood] = useState(false);
  const [Errors, setErrors] = useState({
    email: " ",
    code: " ",
    username: " ",
    password: " ",
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [fields, handleFieldChange] = useFormFields({
    email: "",
    code: "",
    username: "",
    password: "",
  });

  function useForceUpdate() {
    validateForm();
  }

  function validateConfirmationForm() {
    return fields.code.length > 0;
  }

  function validateForm() {
    let errors = {};
    let isValid = true;

    if (fields.email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields.email)) {
        isValid = false;
        errors["email"] = t("shared.enter_valid_email_address");
      }
    }

    if (fields.email.length === 0) {
      isValid = false;
      errors["email"] = t("shared.please_enter_your_email");
    }

    setErrors(errors);
    return isValid;
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.code);
    } catch (e) {
      if (codeIsGood === false) {
        onError(e);
        setIsLoading(false);
        setCodeIsGood(() => true);
      }
    }
    try {
      let user = await Auth.signIn(fields.email, fields.password);
      let object = {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          id: user.attributes.sub,
          email: fields.email,
          fullName: fields.username,
          role: "user",
        },
      };

      await API.post("api", "/users", object);
      setCurrentUser(object.body);
      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="forgetpswd">
      <div className="form">
        <div className="title">Canadian Political Atlas</div>
        <form onSubmit={handleConfirmationSubmit}>
          <ControlLabel>{t("signup.confirm_your_account")}</ControlLabel>
          <FormGroup controlId="email" bsSize="large">
            <HelpBlock className="pb-0">
              please enter your email address here
            </HelpBlock>
            <FormControl
              autoFocus
              type="email"
              onChange={handleFieldChange}
              placeholder={"name@rbhinc.ca"}
              value={fields.email}
              onKeyUp={useForceUpdate}
              className="input-txt-style"
            />
            <div id="err_email" className="text-danger txt-err">
              {Errors.email}
            </div>
          </FormGroup>

          <FormGroup controlId="password" bsSize="large">
            <HelpBlock className="pb-0">
              please enter your password here
            </HelpBlock>
            <FormControl
              type="password"
              onChange={handleFieldChange}
              placeholder={"password"}
              value={fields.password}
              className="input-txt-style"
            />
            <div id="err_email" className="text-danger txt-err">
              {Errors.password}
            </div>
          </FormGroup>

          <FormGroup controlId="username" bsSize="large">
            <HelpBlock className="pb-0">
              please enter your full name here
            </HelpBlock>
            <FormControl
              type="username"
              onChange={handleFieldChange}
              placeholder={"full name"}
              value={fields.username}
              className="input-txt-style"
            />
            <div className="text-danger txt-err">{Errors.username}</div>
          </FormGroup>

          <FormGroup controlId="code" bsSize="large">
            <HelpBlock className="pb-0">
              please enter confirmation code
            </HelpBlock>
            <FormControl
              type="code"
              onChange={handleFieldChange}
              placeholder={"confirmation code"}
              value={fields.code}
              className="input-txt-style"
            />
            <div className="text-danger txt-err">{Errors.code}</div>
          </FormGroup>

          <LoaderButton
            block
            type="submit"
            bsSize="small"
            isLoading={isLoading}
            disabled={!validateConfirmationForm()}
            className="resetbtn btn-font-color"
          >
            {t("signup.confirm")}
          </LoaderButton>
          <div className="div-login-pg text-center">
            <Link to="/login" className="lnk-login-pg">
              {t("forgotPassword.return_to_login_page")} ?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
export default withNamespaces()(ConfirmationForm);
