import React, { useEffect, useState } from "react";
import "./ObjectDetailsFilters.css";
import { Avatar, Divider, SelectPicker, Tooltip, Whisper } from "rsuite";
import { withNamespaces } from "react-i18next";
import {
  compare,
  notify,
  parseFilters,
} from "../../../../services/utilityService";
import { API } from "aws-amplify";
import { currentUser } from "../../../../services/userService";
import CPAIcon from "../../../core/CPAIcons";
import { useHistory } from "react-router-dom";

function ObjectDetailsFilters(props) {
  const [typeKeyIssues, setTypeKeyIssues] = useState("");
  const [objectFilters, setObjectFilters] = useState([]);
  const user = currentUser();
  const history = useHistory();

  const getObjectFilters = () => {
    API.get("api", "/meta/", null).then((filters) => {
      setObjectFilters(filters);
    });
  };

  const handleFilters = (type, value) => {
    setTypeKeyIssues(value);
    props.issueChanged(value);
  };

  /**
   * Follow Object
   * @param objectId
   */
  const follow = (objectId, type) => {
    let data = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        objectId: objectId,
        type: type,
        userId: user.id,
      },
    };

    API.post("api", "/subscriptions", data).then((object) => {
      notify("success", "Followed successfully");
      props.reloadObject();
    });
  };

  /**
   * Unfollow Object
   * @param subId
   */
  const unFollow = (subId) => {
    API.del("api", "/subscriptions/" + subId, null).then(() => {
      notify("success", "Unfollowed successfully");
      props.reloadObject();
    });
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getObjectFilters();
  }, []);

  return (
    <div className="ObjectDetailsFilters">
      <Whisper
        placement="bottom"
        speaker={<Tooltip>{props.t("filters.rank")}</Tooltip>}
      >
        <div className="index border-style">
          {props.object.data.activityRank}
        </div>
      </Whisper>
      <div
        style={{ fontFamily: "apercu-bold" }}
        className="title float-left ml-3 mt-2"
      >
        {props.object.name}
      </div>
      <div className="pull-left">
        <Whisper
          placement="bottom"
          speaker={
            <Tooltip>
              {props.t("filters.monthly_change")}:{" "}
              {props.object.data.activityIndexChange}%
            </Tooltip>
          }
        >
          <div className="pull-left">
            {props.object.data.activityIndexChange > 0 ? (
              <CPAIcon
                color="#53818A"
                icon="up"
                size={20}
                className="object-arrow-up float-left mt-2 pt-1"
              />
            ) : props.object.data.activityIndexChange === 0 ? (
              <CPAIcon
                icon="neutralarrow"
                size={20}
                className="object-arrow-up float-left mt-2 pt-1"
              />
            ) : (
              <CPAIcon
                color="#B14E4E"
                icon="down"
                size={20}
                className="object-arrow-up float-left mt-2 pt-1"
              />
            )}
          </div>
        </Whisper>
      </div>

      {!props.subId && (
        <div
          className="index border-style  ml8 cursor"
          onClick={() => follow(props.object.id, props.object.objectType)}
        >
          <CPAIcon color="#000" icon="follow" className="mr-2" size={20} />
          {props.t("shared.follow")}
        </div>
      )}
      {props.subId && (
        <div
          className="index border-style  ml8 cursor"
          onClick={() => unFollow(props.subId)}
        >
          <CPAIcon color="#000" icon="unfollow" className="mr-2" size={20} />
          {props.t("shared.unfollow")}
        </div>
      )}

      <div className="profile-info pull-right">
        {props.object?.objectType === "1" && (
          <SelectPicker
            placeholder={props.t("filters.keyIssues")}
            groupBy="type"
            sort={(isGroup) => {
              if (isGroup) {
                return (a, b) => {
                  return compare(a.type, b.type);
                };
              }

              return (a, b) => {
                return compare(a.label, b.label);
              };
            }}
            data={parseFilters(objectFilters, "issues")}
            value={typeKeyIssues}
            onChange={(v) => handleFilters("typeKeyIssues", v)}
            className="bck-issues  mr8px filter-font border-style"
            style={{ width: 150 }}
            searchable={false}
            appearance="subtle"
          />
        )}

        <Whisper placement="bottom" speaker={<Tooltip>Previous</Tooltip>}>
          <div className="icons pull-right border-style cursor" onClick={back}>
            <CPAIcon icon="back" size={20} color="#000" />
          </div>
        </Whisper>
        {props.object?.objectType === "2" && (
          <div className="icons border-style pull-right p-1 cursor mr8">
            <Whisper
              placement="bottom"
              speaker={<Tooltip>{props.t("filters.go_to_bill_text")}</Tooltip>}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.object.url}
              >
                <CPAIcon icon="external" size={30} color="#000" />
              </a>
            </Whisper>
          </div>
        )}

        {props.object?.objectType === "2" && (
          <div className="index border-style ml-3 cursor pb-2 pt-2 pr-3 pl-3 pull-right mr24">
            <Whisper
              placement="bottomStart"
              speaker={
                <Tooltip>
                  {props.t("overview_filters.sentiment")}:{" "}
                  {props.object?.sentiment}
                </Tooltip>
              }
            >
              <div className="sentiment">
                {props.object?.sentiment?.toLowerCase() === "positive" && (
                  <div className="circle positive"></div>
                )}
                {props.object?.sentiment?.toLowerCase() === "negative" && (
                  <div className="circle negative"></div>
                )}
                {props.object?.sentiment?.toLowerCase() === "neutral" && (
                  <div className="circle neutral"></div>
                )}
              </div>
            </Whisper>
          </div>
        )}
        {props.object?.objectType === "1" && (
          <div className="icons  pull-left border-style  cursor mr-8px">
            <Whisper
              placement="bottom"
              speaker={
                <Tooltip>
                  {props.t("shared.vaping")}:{" "}
                  {props?.object?.data?.sentimentVaping}
                </Tooltip>
              }
            >
              <Avatar
                size="xs"
                className={
                  props?.object?.data?.sentimentVaping === "Negative"
                    ? "icon-active pull-right "
                    : "pull-right positive"
                }
                style={{
                  backgroundColor:
                    props?.object?.data?.sentimentVaping === "Insufficient Data"
                      ? "lightgrey"
                      : props?.object?.data?.sentimentVaping === "Neutral"
                      ? "grey"
                      : "",
                }}
                circle
              >
                <CPAIcon color="#fff" icon="vaping" size={20} strokeWidth={3} />
              </Avatar>
            </Whisper>
            <Whisper
              placement="bottom"
              speaker={
                <Tooltip>
                  {props.t("shared.cannabis")}:{" "}
                  {props?.object?.data?.sentimentCannabis}
                </Tooltip>
              }
            >
              <Avatar
                size="xs"
                className={
                  props?.object?.data?.sentimentCannabis === "Negative"
                    ? "icon-active pull-right mr-2"
                    : "pull-right mr-2 positive"
                }
                style={{
                  backgroundColor:
                    props?.object?.data?.sentimentCannabis ===
                    "Insufficient Data"
                      ? "lightgrey"
                      : props?.object?.data?.sentimentCannabis === "Neutral"
                      ? "grey"
                      : "",
                }}
                circle
              >
                <CPAIcon
                  color="#fff"
                  icon="cannabis"
                  size={20}
                  strokeWidth={3}
                />
              </Avatar>
            </Whisper>
            <Whisper
              placement="bottom"
              speaker={
                <Tooltip>
                  {props.t("shared.smoking")}:{" "}
                  {props?.object?.data?.sentimentSmoking}
                </Tooltip>
              }
            >
              <Avatar
                size="xs"
                className={
                  props?.object?.data.sentimentSmoking === "Negative"
                    ? "icon-active pull-right mr-2"
                    : "pull-right mr-2 positive"
                }
                style={{
                  backgroundColor:
                    props?.object?.data?.sentimentSmoking ===
                    "Insufficient Data"
                      ? "lightgrey"
                      : props?.object?.data?.sentimentSmoking === "Neutral"
                      ? "grey"
                      : "",
                }}
                circle
              >
                <CPAIcon
                  color="#fff"
                  icon="smoking"
                  size={20}
                  strokeWidth={4}
                />
              </Avatar>
            </Whisper>
          </div>
        )}
        <div className="clearfix"></div>
      </div>
      <div className="clearfix" />
      <Divider />
      <br />
    </div>
  );
}

export default withNamespaces()(ObjectDetailsFilters);
