import React from "react";
import PropTypes from "prop-types";
import iconPath from "../../assets/iconsLib";

const defaultStyles = { display: "inline-block", verticalAlign: "middle" };

const CPAIcon = ({
  size,
  color,
  icon,
  className,
  style,
  viewBox,
  strokeWidth,
}) => {
  const styles = { ...defaultStyles, ...style };
  return (
    <svg
      className={className}
      style={styles}
      viewBox={viewBox}
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill={color}
        stroke={color}
        d={iconPath[icon]}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

CPAIcon.defaultProps = {
  size: 128,
  color: "#323232",
  viewBox: "0 0 128 128",
  style: {},
  className: "",
  strokeWidth: 2,
};

CPAIcon.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  viewBox: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default CPAIcon;
