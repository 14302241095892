import React, { useEffect, useState } from "react";
import "./NewsFeedFilters.css";
import { IconButton, SelectPicker } from "rsuite";
import { parseFilters, compare } from "../../../services/utilityService";
import { withNamespaces } from "react-i18next";
import { API } from "aws-amplify";
import CPAIcon from "../../core/CPAIcons";

function NewsFeedFilters(props) {
  const [objectFilters, setObjectFilters] = useState([]);
  const [filters, setFilters] = useState({});

  const applyFilters = (type, value) => {
    if (!value) {
      let f = JSON.parse(JSON.stringify(filters));
      delete f[type];
      setFilters(f);
    } else {
      setFilters({ ...filters, [type]: value });
    }
  };

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.applyFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getFilters = () => {
    API.get("api", "/meta/", null).then((filters) => {
      setObjectFilters(filters);
    });
  };

  const clear = () => {
    setFilters({ issue: null, type: null, objectType: null });
  };

  return (
    <div className="NewsFeedFilters filters-style">
      <SelectPicker
        placeholder={props.t("filters.keyIssues")}
        groupBy="type"
        sort={(isGroup) => {
          if (isGroup) {
            return (a, b) => {
              return compare(a.type, b.type);
            };
          }

          return (a, b) => {
            return compare(a.label, b.label);
          };
        }}
        data={parseFilters(objectFilters, "issues")}
        value={filters.issue}
        onChange={(v) => applyFilters("issue", v)}
        className="mr8px filter-font border-style"
        style={{ width: 150 }}
        searchable={false}
        appearance="subtle"
      />
      {props.pubView !== "true" && (
        <SelectPicker
          placeholder={props.t("newsfeedfilter.post_type")}
          sort={() => {
            return (a, b) => {
              return compare(a.label, b.label);
            };
          }}
          data={parseFilters(objectFilters, "15")}
          value={filters.type}
          onChange={(v) => applyFilters("type", v)}
          className="mr8px filter-font border-style"
          searchable={false}
          appearance="subtle"
        />
      )}
      {props.showObjectType && (
        <SelectPicker
          placeholder={props.t("newsfeedfilter.object_type")}
          sort={() => {
            return (a, b) => {
              return compare(a.label, b.label);
            };
          }}
          data={[
            { label: "Actors", value: "1" },
            { label: "Legislation", value: "2" },
          ]}
          value={filters.objectType}
          onChange={(v) => applyFilters("objectType", v)}
          className="mr8px filter-font border-style"
          searchable={false}
          appearance="subtle"
        />
      )}
      {(filters.issue || filters.type || filters.objectType) && (
        <IconButton
          className="mr8px"
          onClick={clear}
          icon={<CPAIcon color="#000" icon="close" size={20} />}
        />
      )}
    </div>
  );
}

export default withNamespaces()(NewsFeedFilters);
