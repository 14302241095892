import React, { useEffect, useState } from "react";
import "./OverviewFilters.css";
import { ButtonToolbar, SelectPicker, CheckPicker } from "rsuite";
import {
  compare,
  getFilters,
  parseFilters,
  provinceList,
  saveFilters,
} from "../../../services/utilityService";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { API } from "aws-amplify";

function OverviewFilters(props) {
  const history = useHistory();
  const [objectFilters, setObjectFilters] = useState([]);
  const [filters, setFilters] = useState(getFilters("overview"));

  const applyFilters = (type, value) => {
    setFilters({ ...filters, [type]: value });
  };

  useEffect(() => {
    API.get("api", "/meta/", null).then((filters) => {
      setObjectFilters(filters);
    });

    setFilters(getFilters("overview"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters.issue) {
      saveFilters(filters, "overview");
      props.applyFilters(filters);
    } else {
      let f = {
        issue: "1",
        metric: "1",
        geoLevel: props.type ? "2" : "1",
        timeFrame: "2",
        view: "1",
      };
      setFilters(f);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (props.type) {
      filters.province = props.type;
      applyFilters("geoLevel", "2");
    } else {
      filters.province = null;
      applyFilters("geoLevel", "1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  return (
    <div className="ObjectFilters">
      <ButtonToolbar className="buttonsToolbar pull-right">
        {props.type && (
          <SelectPicker
            placeholder="Province"
            sort={() => {
              return (a, b) => {
                return compare(a.label, b.label);
              };
            }}
            data={provinceList()}
            value={props.type}
            onChange={(v) => {
              v && history.push("./" + v.toString());
              applyFilters("province", v);
            }}
            className="mr-8px filter-font border-style"
            searchable={false}
            appearance="subtle"
          />
        )}

        {props.isTable ? (
          <CheckPicker
            style={{ width: 224 }}
            groupBy="type"
            placeholder="Key issues"
            sort={(isGroup) => {
              if (isGroup) {
                return (a, b) => {
                  return compare(a.type, b.type);
                };
              }

              return (a, b) => {
                return compare(a.label, b.label);
              };
            }}
            data={parseFilters(objectFilters, "issues")}
            defaultValue={filters["selected-issues"]}
            onChange={(v) => applyFilters("selected-issues", v)}
            className="mr-8px filter-font border-style"
            searchable={false}
            appearance="subtle"
          />
        ) : (
          <SelectPicker
            placeholder={props.t("filters.keyIssues")}
            groupBy="type"
            sort={(isGroup) => {
              if (isGroup) {
                return (a, b) => {
                  return compare(a.type, b.type);
                };
              }

              return (a, b) => {
                return compare(a.label, b.label);
              };
            }}
            data={parseFilters(objectFilters, "issues")}
            value={filters.issue}
            onChange={(v) => (v ? applyFilters("issue", v.toString()) : "")}
            style={{ width: 150 }}
            className="mr-8px filter-font border-style"
            searchable={false}
            appearance="subtle"
          />
        )}
        <SelectPicker
          placeholder="Metric"
          data={[
            { label: props.t("overview_filters.volume"), value: "1" },
            { label: props.t("overview_filters.sentiment"), value: "2" },
          ]}
          value={filters.metric}
          onChange={(v) => applyFilters("metric", v)}
          className="mr-8px filter-font border-style"
          searchable={false}
          appearance="subtle"
        />
        <SelectPicker
          placeholder="Admin Level"
          data={[
            { label: props.t("overview_filters.provincial"), value: "1" },
            { label: props.t("overview_filters.p_riding"), value: "3" },
            { label: props.t("overview_filters.f_riding"), value: "2" },
          ]}
          value={filters.geoLevel}
          onChange={(v) => applyFilters("geoLevel", v)}
          className="mr-8px filter-font border-style"
          searchable={false}
          appearance="subtle"
        />
      </ButtonToolbar>
    </div>
  );
}

export default withNamespaces()(OverviewFilters);
