import AWS from "aws-sdk";
import { PubSub, Auth, API } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib";
import config from "../config";
import { Notification } from "rsuite";
import React from "react";
import { EventEmitter } from "events";

export const appEmitter = new EventEmitter();

export function buildLetters(name) {
  return name
    ? name
        .split(" ")
        .slice(0, 2)
        .map((word) => word[0])
        .join("")
    : "";
}

export function connectToTopic(topic) {
  Auth.currentCredentials().then((credentials) => {
    init(credentials);
    PubSub.addPluggable(new AWSIoTProvider(config.pubSub)).then((r) => {
      console.log("SUBSCRIBED TO TOPIC: " + topic);
      PubSub.subscribe(topic).subscribe({
        next: () => {
          appEmitter.emit("newFeed", "new");
        },
        error: (error) => {
          console.log("UNSUBSCRIBING DUE TO ERROR", error);
          console.log("RECONNECTING TO TOPIC");
          let int = setInterval(() => {
            if (navigator.onLine) {
              clearInterval(int);
              console.log("BACK ONLINE, CONNECTING");
              setTimeout(() => {
                connectToTopic(topic);
              }, 5000);
            } else {
              console.log("YOU SEEM OFFLINE, WILL TRY AGAIN");
            }
          }, 10000);
        },
        close: () => console.log("Done"),
      });
    });
  });
}

export function notify(title, msg) {
  Notification[title === "error" ? "error" : "success"]({
    title: title.charAt(0).toUpperCase() + title.slice(1),
    description: <div>{msg}</div>,
  });
}

export function saveFilters(filters, type) {
  localStorage.setItem("filters-" + type, JSON.stringify(filters));
}

export function getFilters(type) {
  const filters = localStorage.getItem("filters-" + type);
  return JSON.parse(filters) || {};
}

export function clearFilters(type) {
  localStorage.removeItem("filters-" + type);
}

export function chunk(arr, size) {
  // This prevents infinite loops
  if (size < 1) throw new Error("Size must be positive");

  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
}

export function sendMail(data) {
  data = {
    headers: {
      "Content-Type": "application/json",
    },
    body: {
      email: data.email,
      subject: data.subject,
      body: data.body,
    },
  };

  return API.post("api", "/mailer", data);
}

export function mapProvincesData() {
  return {
    QC: "Quebec",
    BC: "British Columbia",
    NU: "Nunavut",
    PE: "Prince Edward Island",
    SK: "Saskatchewan",
    YT: "Yukon",
    MB: "Manitoba",
    ON: "Ontario",
    NB: "New Brunswick",
    NT: "Northwest Territories",
    AB: "Alberta",
    NL: "Newfoundland and Labrador",
    NS: "Nova Scotia",
  };
}

export function provinceList() {
  let array = [];
  for (const [key, value] of Object.entries(mapProvincesData())) {
    array.push({ label: value, value: key });
  }
  return array;
}

export function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function parseFilters(filters, type) {
  return filters
    .filter((f) =>
      type === "issues" ? f.type === "9" || f.type === "10" : f.type === type
    )
    .map((f) => {
      return {
        label: f.key,
        value: f.value.toString(),
        type: f.type === "9" ? "RBH" : "NAT",
      };
    });
}

export function compare(a, b) {
  let nameA = a;
  let nameB = b;

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

async function init(credentials) {
  const iot = new AWS.Iot({
    region: "us-east-1",
    credentials: Auth.essentialCredentials(credentials),
  });
  const policyName = "myIoTPolicy";
  const target = credentials.identityId;
  await iot.attachPolicy({ policyName, target }).promise();
}

export function getstatusColor(progressBarVal) {
  if (progressBarVal < 20) {
    return "#B7E0F5";
  } else if (progressBarVal < 50) {
    return "#95BAD3";
  } else if (progressBarVal === 50) {
    return "#7096B1";
  } else if (progressBarVal < 60) {
    return "#4D6F90";
  } else if (progressBarVal < 80) {
    return "#2B4A6E";
  } else if (progressBarVal === 100) {
    return "#09244D";
  }
}
