import React, { useState } from "react";
import { HelpBlock, FormGroup, FormControl } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { notify } from "../../../services/utilityService";
import LoaderButton from "../../../components/shared/LoaderButton/LoaderButton";
import { useFormFields } from "../../../libs/hooksLib";
import "./ForgotPassword.css";
import { withNamespaces } from "react-i18next";
import { onError } from "../../../libs/errorLib";
import { Link, useHistory } from "react-router-dom";
import { Tooltip, Whisper } from "rsuite";
import { checkEmail } from "../../../services/userService";

function ForgotPassword({ t }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    code: "",
    password: "",
    confirmPassword: "",
  });
  const [Errors, setErrors] = useState({ email: " ", password: " " });
  const tooltip = <Tooltip>{t("shared.password_rule")}</Tooltip>;

  const strongPasswordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_@#$%^&*])(?=.{6,})"
  );

  async function handleForgotPasswordSubmit(event) {
    let errors = {};
    try {
      event.preventDefault();
      if (!validateEmail()) {
        setIsLoading(false);
        return;
      }
      setIsLoading(true);

      let emailCheck = await checkEmail(fields.email);

      if (!emailCheck) {
        errors.email = "Email not found";
        setErrors(errors);
        setIsLoading(false);
        return;
      }

      await Auth.forgotPassword(fields.email);
      setReset(true);

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      onError(e);
      errors["password"] = e.message;
      setErrors(errors);
      setIsLoading(false);
    }
  }

  async function handleResetForm(event) {
    try {
      event.preventDefault();
      if (!validateResetForm()) {
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      notify(t("shared.success"), t("forgotPassword.password_change_success"));
      history.push("/login");
    } catch (e) {
      setIsLoading(false);
      onError(e);
      let errors = {};
      errors["confirmPassword"] = e.message;
      setErrors(errors);
    }
  }

  function validateEmail() {
    let errors = {};
    let isValid = true;

    if (fields.email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields.email)) {
        isValid = false;
        errors["email"] = t("shared.enter_valid_email_address");
      }
    }

    if (fields.email.length === 0) {
      isValid = false;
      errors["email"] = t("shared.please_enter_your_email");
    }

    setErrors(errors);
    return isValid;
  }

  function useForceUpdate() {
    setErrors({ email: "", password: "", confirmPassword: "", code: "" });
  }

  function validateResetForm() {
    let errors = {};
    let isValid = true;

    if (fields.code.length === 0) {
      isValid = false;
      errors["code"] = t("forgotPassword.please_enter_your_code");
    }

    if (fields.password.length === 0) {
      isValid = false;
      errors["password"] = t("forgotPassword.please_enter_your_password");
    } else {
      if (!strongPasswordRegex.test(fields.password)) {
        isValid = false;
        errors["password"] = t(
          "forgotPassword.please_enter_password_that_matches_the_rule"
        );
      }
    }

    if (fields.confirmPassword.length === 0) {
      isValid = false;
      errors["confirmPassword"] = t(
        "forgotPassword.please_enter_your_confirm_password"
      );
    }

    if (
      typeof fields.password !== "undefined" &&
      typeof fields.confirmPassword !== "undefined"
    ) {
      if (fields.password !== fields.confirmPassword) {
        isValid = false;
        errors["password"] = t("forgotPassword.password_dont_match");
      }
    }

    setErrors(errors);
    return isValid;
  }

  return (
    <div className="forgetpswd">
      <div className="form">
        <div className="title">Canadian Political Atlas</div>
        {!reset && (
          <form onSubmit={handleForgotPasswordSubmit}>
            <FormGroup controlId="email" bsSize="large">
              <HelpBlock className="pb-0">
                {t("forgotPassword.please_enter_email_for_password")}
              </HelpBlock>
              <FormControl
                autoFocus
                type="email"
                onBlur={useForceUpdate}
                onChange={handleFieldChange}
                placeholder={"name@rbhinc.ca"}
                value={fields.email}
                className="input-txt-style"
              />
              <div id="err_email" className="text-danger txt-err">
                {Errors.email}
              </div>
            </FormGroup>

            <LoaderButton
              block
              type="submit"
              bsSize="small"
              isLoading={isLoading}
              className="resetbtn btn-font-color"
            >
              {t("forgotPassword.send_reset_code")}
            </LoaderButton>
            <div className="div-login-pg text-center">
              <Link to="/login" className="lnk-login-pg">
                {t("forgotPassword.return_to_login_page")} ?
              </Link>
            </div>
          </form>
        )}
        {reset && (
          <form id="resetForm" onSubmit={handleResetForm}>
            <FormGroup controlId="code">
              <FormControl
                autoFocus
                type="num"
                onChange={handleFieldChange}
                onBlur={useForceUpdate}
                value={fields.code}
                className="input-txt-style"
                placeholder={t("forgotPassword.enter_reset_code")}
              />
              <div id="err-resetcode" className="text-danger txt-err">
                {Errors.code}
              </div>
            </FormGroup>

            <FormGroup controlId="password">
              <div className="input-group">
                <FormControl
                  type="password"
                  onChange={handleFieldChange}
                  onBlur={useForceUpdate}
                  placeholder={t("forgotPassword.enter_new_password")}
                  value={fields.password}
                  aria-describedby="pswd-tootilp"
                  className="input-txt-style"
                />
                <Whisper placement="top" trigger="hover" speaker={tooltip}>
                  <span
                    className="input-group-addon"
                    data-tip
                    data-for="registerTip"
                    id="pswd-tootilp"
                  >
                    ?
                  </span>
                </Whisper>
              </div>
              <div className="text-danger txt-err">{Errors.password}</div>
            </FormGroup>

            <FormGroup controlId="confirmPassword">
              <FormControl
                type="password"
                onChange={handleFieldChange}
                onBlur={useForceUpdate}
                placeholder={t("forgotPassword.confirm_new_password")}
                value={fields.confirmPassword}
                className="input-txt-style"
              />
              <div className="text-danger txt-err">
                {Errors.confirmPassword}
              </div>
            </FormGroup>

            <LoaderButton
              block
              type="submit"
              bsSize="small"
              isLoading={isLoading}
              className="submitbtn form-control btn-font-color"
            >
              {t("forgotPassword.submit")}
            </LoaderButton>
            <div className="div-login-pg text-center">
              <Link to="/login" className="lnk-login-pg">
                {t("forgotPassword.return_to_login_page")} ?
              </Link>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default withNamespaces()(ForgotPassword);
