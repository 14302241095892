import React, { useEffect, useState } from "react";
import "./UserCard.css";
import { API } from "aws-amplify";
import {
  Panel,
  Divider,
  Avatar,
  Placeholder,
  Button,
  Loader,
  Notification,
  ButtonToolbar,
} from "rsuite";
import {
  userCanBeDeleted,
  isAdmin,
  buildUserAvatarUrl,
} from "../../../../services/userService";
import { buildLetters } from "../../../../services/utilityService";
import { withNamespaces } from "react-i18next";
import CPAIcon from "../../../core/CPAIcons";

function UserCard(props) {
  const [user, setUser] = useState();
  const [isLoading, setLoading] = useState(false);
  const { Paragraph } = Placeholder;

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const switchAdminAccess = (user) => {
    setLoading(true);
    let object = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        field: "role",
        value: user.role === "admin" ? "user" : "admin",
      },
    };

    API.post("api", "/users/field/" + user.id, object).then((res) => {
      setUser(res);
      setLoading(false);
    });
  };

  const deleteUser = (user) => {
    if (userCanBeDeleted(user)) {
      setLoading(true);
      let object = {
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          id: user.id,
          email: user.email,
        },
      };

      API.post("api", "/users/delete", object).then((subs) => {
        setLoading(false);
        props.reloadUsers();
      });
    } else {
      alert(props.t("usercard.cant_delete_me"));
    }
  };

  const verifyDelete = (user) => {
    Notification.open({
      title: props.t("usercard.delete_user"),
      duration: 0,
      description: (
        <div>
          <p>{props.t("usercard.confirmationUser")}?</p>
          <br />
          <ButtonToolbar>
            <Button
              color="red"
              onClick={() => {
                deleteUser(user);
                Notification.close();
              }}
            >
              {props.t("usercard.yes_delete_this_user")}
            </Button>
            <Button
              onClick={() => {
                Notification.close();
              }}
            >
              {props.t("usercard.cancel")}
            </Button>
          </ButtonToolbar>
        </div>
      ),
    });
  };

  const verifySwitchAdmin = (user) => {
    Notification.open({
      title: props.t("usercard.update_user_privilege"),
      duration: 0,
      description: (
        <div>
          <p>{props.t("usercard.confirm_update")}?</p>
          <br />
          <ButtonToolbar>
            <Button
              color="red"
              onClick={() => {
                switchAdminAccess(user);
                Notification.close();
              }}
            >
              {props.t("usercard.yes_update_user")}
            </Button>
            <Button
              onClick={() => {
                Notification.close();
              }}
            >
              {props.t("usercard.cancel")}
            </Button>
          </ButtonToolbar>
        </div>
      ),
    });
  };

  const showObject = () => (
    <div className="panel-header">
      {isLoading && (
        <Loader backdrop content={props.t("usercard.loading")} vertical />
      )}
      <div className="p-1">
        <div>
          <Avatar className="profile-pic pull-left" circle>
            {user.fullName &&
              (user.hasAvatar ? (
                <img
                  alt={user.fullName}
                  src={buildUserAvatarUrl(user.id)}
                  width="70px"
                  height="70px"
                />
              ) : (
                buildLetters(user.fullName)
              ))}
          </Avatar>
          <div className="pull-left profile-info">
            <div className="bold fullname">{user.fullName}</div>
            <Divider className="divider"></Divider>
            <div className="info">{user.jobTitle}</div>
            <div className="info">{user.location}</div>
            <div className="info">
              <a href={props.t("usercard.mailto") + ":" + user.email}>
                {user.email}
              </a>
            </div>
          </div>
        </div>
        {isAdmin() && (
          <div>
            <div className="clearfix" />
            <Divider className="m-0 mb-2" />
            <div className="follow-link">
              {user.role === "admin"
                ? props.t("usercard.admin")
                : props.t("usercard.user")}
            </div>
            <div>
              <span onClick={() => verifyDelete(user)}>
                <CPAIcon
                  color="#000"
                  icon="removeadminsecond"
                  size={20}
                  className="pull-right cursor"
                />
              </span>
              <Button appearance="link" className="pull-right p-0">
                <Avatar
                  className="admin-b"
                  onClick={() => verifySwitchAdmin(user)}
                  style={{
                    backgroundColor: user.role === "admin" ? "darkred" : null,
                  }}
                  circle
                />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Panel className="UserCard no-padding" shaded>
      {!user && (
        <div>
          <Paragraph style={{ margin: 30 }} graph="circle" />
        </div>
      )}
      {user && showObject()}
    </Panel>
  );
}

export default withNamespaces()(UserCard);
