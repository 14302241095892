import React, { useEffect, useState } from "react";
import "./AddUser.css";
import { API } from "aws-amplify";
import { Panel, Input, FlexboxGrid, Button } from "rsuite";
import { withNamespaces } from "react-i18next";
import { notify } from "../../../../services/utilityService";

function AddUser(props) {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [Errors, setErrors] = useState({ email: " ", fullName: " " });

  useEffect(() => {}, []);

  function validateInputs() {
    let errors = {};
    let isValid = true;

    if (fullName.length === 0) {
      isValid = false;
      errors["fullName"] = props.t("shared.please_enter_your_fullname");
    }

    if (email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = props.t("shared.enter_valid_email_address");
      }
    }

    if (email.length === 0) {
      isValid = false;
      errors["email"] = props.t("shared.please_enter_your_email");
    }

    setErrors(errors);
    return isValid;
  }

  const addUser = () => {
    if (!validateInputs()) {
      return;
    }

    let object = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        email: email,
        fullName: fullName,
      },
    };

    API.post("api", "/users/cognito/", object)
      .then((res) => {
        setEmail("");
        setFullName("");
        notify(
          props.t("shared.success"),
          props.t("adduser.user_has_been_added_successfully")
        );
      })
      .catch((err) => {
        notify("error", err.message);
      });
  };

  const handleChange = (value, event) => {
    event.persist();
    event.target.attributes["data-id"].value === "email"
      ? setEmail(value)
      : setFullName(value);
  };

  return (
    <Panel className="AddUser" shaded>
      <div className="message">{props.t("adduser.info")}</div>
      <FlexboxGrid>
        <FlexboxGrid.Item className="pr-20" colspan={9}>
          <Input
            data-id="fullName"
            placeholder={props.t("adduser.full_name")}
            value={fullName}
            onChange={handleChange}
          />
          <div className="text-danger txt-err">{Errors.fullName}</div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item className="pr-20" colspan={9}>
          <Input
            data-id="email"
            type="email"
            placeholder={props.t("adduser.email")}
            value={email}
            onChange={handleChange}
          />
          <div className="text-danger txt-err">{Errors.email}</div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} className="text-right">
          <Button onClick={addUser}>
            {props.t("adduser.send_account_details")}
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Panel>
  );
}

export default withNamespaces()(AddUser);
