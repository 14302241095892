import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useHistory } from "react-router-dom";
import federal from "../../assets/mapData/new-federal.topo";
import provinces from "../../assets/mapData/new-provinces.topo";
import provincial from "../../assets/mapData/new-provincial-latest.topo";
import map from "highcharts/modules/map";
import proj4 from "proj4";
import * as topojson from "topojson-client";

map(Highcharts);

const MapChart = (props) => {
  const [config, setConfig] = useState({});
  const history = useHistory();

  let sentimentClasses = [
    {
      name: "Negative",
      from: -1000000,
      to: -60,
      color: "#D0703B",
    },
    {
      name: "Leaning Negative",
      from: -60,
      to: -20,
      color: "#EBBFA7",
    },
    {
      name: "Split",
      from: -20,
      to: 20,
      color: "#BFBFBF",
    },
    {
      name: "Leaning Positive",
      from: 20,
      to: 60,
      color: "#9EC0C7",
    },
    {
      name: "Positive",
      from: 60,
      to: 1000000,
      color: "#53818A",
    },
  ];

  let volumeClasses = [
    {
      name: "<10%",
      from: -100,
      to: 10,
      color: "#B7E0F5",
    },
    {
      name: "0%",
      from: 0,
      to: 10,
      color: "#95BAD3",
    },
    {
      name: "10%",
      from: 10,
      to: 20,
      color: "#7096B1",
    },
    {
      name: "20%",
      from: 20,
      to: 30,
      color: "#4D6F90",
    },
    {
      name: ">30%",
      from: 30,
      to: 1000,
      color: "#2B4A6E",
    },
  ];

  let classes;

  let geojson = {};

  if (props.type === "provincial") {
    geojson = topojson.feature(
      provincial,
      provincial.objects[Object.keys(provincial.objects)[0]]
    );
  } else if (props.type === "province") {
    geojson = topojson.feature(
      provinces,
      provinces.objects[Object.keys(provinces.objects)[0]]
    );
  } else if (props.type === "federal") {
    geojson = topojson.feature(
      federal,
      federal.objects[Object.keys(federal.objects)[0]]
    );
  } else {
    geojson = topojson.feature(
      provinces,
      provinces.objects[Object.keys(provinces.objects)[0]]
    );
  }

  if (props.show && props.show !== "all") {
    geojson.features = geojson.features.filter(
      (g) =>
        g.properties.PROVCODE === props.show ||
        g.properties["hc-key"] === props.show
    );
  }

  project(geojson, "+proj=lcc +lat_1=33 +lat_2=45 +lat_0=39 +lon_0=-96");

  const options = {
    legend: {
      enabled: true,
      margin: 100,
      padding: 0,
      symbolRadius: 0,
      symbolHeight: 10,
      symbolWidth: 80,
      symbolPadding: 0,
      itemDistance: 0,
      squareSymbol: false,
      floating: false,
      itemStyle: {
        color: "#333333",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "bold",
        textOverflow: "ellipsis",
        position: "relative",
        height: "50px",
      },
      useHTML: true,
      labelFormatter: function () {
        return this.name === "Negative" ||
          this.name === "Positive" ||
          this.name === "<10%" ||
          this.name === ">30%"
          ? '<div style="position:absolute;left:-80px;top:-30px"><br>' +
              this.name +
              "</div>"
          : "";
      },
    },
    chart: {
      height: 750,
      map: geojson,
    },
    colors: ["#f7f7f7", "#53818A", "#9EC0C7", "#EBBFA7", "#D0703B"],
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        events: {
          click: function (e) {
            if (props.show === "all") {
              history.push(
                "/province-overview/" + e.point.properties["PROVCODE"]
              );
            }
          },
        },
      },
    },
    title: {
      style: {
        display: "none",
      },
    },

    mapNavigation: {
      enabled: false,
    },

    colorAxis: {
      dataClasses: classes,
    },

    tooltip: {
      formatter: function () {
        return (
          this.point &&
          "<b>" +
            this.point.name +
            '</b><br/><span style="font-size:18px;font-weight:bold">' +
            (this.point.value !== "NA"
              ? this.point.value + "%"
              : "Insufficient data") +
            "</span> " +
            (this.point.change >= 0
              ? "+" + this.point.change
              : this.point.value !== "NA"
              ? this.point.change
              : "") +
            "<br/>" +
            (this.point.value !== "NA"
              ? props.metric === "2"
                ? this.series.colorAxis.dataClasses[this.point.dataClass].name
                : "Volume"
              : "")
        );
      },
      shared: true,
    },

    series: [
      {
        colorKey: "value",
        cursor: "pointer",
        data: props.data,
        keys: ["hc-key", "value", "change"],
        borderWidth: 1,
        borderColor: "white",
        joinBy: ["hc-key"],
        name: "Data",
        states: {
          hover: {
            color: "#a4edba",
          },
        },
        dataLabels: {
          enabled: false,
          format: "{point.properties.hc-key}",
        },
      },
    ],
  };

  function project(geojson, projection) {
    const projectPolygon = (coordinate) => {
      coordinate.forEach((lonLat, i) => {
        coordinate[i] = proj4(projection, lonLat);
      });
    };
    geojson.features.forEach(function (feature) {
      feature.properties["hc-key"] = feature.properties["hc-key"].toString();
      if (!feature.geometry) {
        feature.geometry = {};
        feature.geometry.type = "Polygon";
        feature.geometry.coordinates = [];
      }
      if (feature.geometry.type === "Polygon") {
        feature.geometry.coordinates.forEach(projectPolygon);
      } else if (feature.geometry.type === "MultiPolygon") {
        feature.geometry.coordinates.forEach((items) => {
          items.forEach(projectPolygon);
        });
      }
    });
  }

  useEffect(() => {
    if (props.metric === "1") {
      options.colorAxis.dataClasses = volumeClasses;
    } else {
      options.colorAxis.dataClasses = sentimentClasses;
    }

    setConfig(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, props.data, props.show]);

  return (
    <div>
      {
        <HighchartsReact
          constructorType="mapChart"
          highcharts={Highcharts}
          options={config}
        />
      }
    </div>
  );
};

export default MapChart;
