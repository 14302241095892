import React, { useEffect, useState } from "react";
import "./NationalOverview.css";
import { withNamespaces } from "react-i18next";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import {
  Divider,
  Grid,
  Row,
  Col,
  Table,
  Button,
  ButtonGroup,
  SelectPicker,
  ButtonToolbar,
} from "rsuite";
import MapChart from "../../components/core/MapChart";
import Top3LegislationPending from "../../components/shared/objects/Top3LegislationPending/Top3LegislationPending";
import SemiPie from "../../components/core/SemiPie";
import Top3Actors from "../../components/shared/objects/Top3Actors/Top3Actors";
import OverviewFilters from "../../components/shared/OverviewFilters/OverviewFilters";
import {
  mapProvincesData,
  parseFilters,
  provinceList,
} from "../../services/utilityService";
import LineChart from "../../components/core/LineChart";
import SupportFilters from "../../components/shared/SupportFilters/SupportFilters";
import LoadingPlaceholders from "../../components/shared/LoadingPlaceholders/NationProvincePublication/LoadingPlaceholders";

function NationalOverview({ t, props }) {
  const [objectFilters, setObjectFilters] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [filters, setFilters] = useState({});
  const [tableData, setTableData] = useState([]);
  const [show, setShow] = useState("map");
  const [tableLoading, setTableLoading] = useState(false);
  const [tableSorting, setTableSorting] = useState("asc");
  const [sortColumn, setSortColumn] = useState("name");
  const [issueType, setIssueType] = useState("1");
  const [ridings, setRidings] = useState({});
  const [supportData, setSupportData] = useState();
  const [supportFilters, setSupportFilters] = useState({});
  const [top3Actors, setTop3Actors] = useState([]);
  const [top3Legislations, setTop3Legislations] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getSocialData();
    getFilters();
    getRidingNames();
    getSupportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (supportFilters.year) {
      getSupportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportFilters]);

  useEffect(() => {
    getTop3Actors("1", filters.issue, filters.province);
    getTop3Legislations("2", filters.issue, filters.province);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const applySupportFilters = (filters) => {
    setSupportFilters(filters);
  };

  const getRidingNames = () => {
    API.get("backend", "/get-riding-names", null).then((ridings) => {
      setRidings(ridings);
    });
  };

  const getFilters = () => {
    API.get("api", "/meta/", null).then((filters) => {
      setObjectFilters(filters);
    });
  };

  const getIssue = (id) => {
    return parseFilters(objectFilters, "issues").find((f) => f.value === id);
  };

  const getSocialData = () => {
    API.get("api", "/social/", null).then((data) => {
      setSocialData(data);
    });
  };

  const prepareMapData = (key = null) => {
    let newFilters = JSON.parse(JSON.stringify(filters));
    delete newFilters["selected-issues"];
    delete newFilters["province"];
    let so = socialData.filter(function (item) {
      for (var key in newFilters) {
        if (item[key] === undefined || item[key] !== newFilters[key])
          return false;
      }
      return true;
    });

    let mapD = so.map((s) => {
      let all = {};
      Object.values(s.data).forEach((k) => {
        all = { ...all, ...k };
      });

      // let data = id && filters.geoLevel === "1" ? { [id]: s.data[id] } : filters.geoLevel === "1" ? s.data : all;
      let data = filters.geoLevel === "1" ? s.data : all;
      if (key) {
        data = s.data[key] || [];
      }
      return Object.entries(data).map(([key, value]) => [
        key,
        value.value,
        value.change,
      ]);
    });

    return mapD[0] || [];
  };

  const applyFilters = (nFilters) => {
    if (!nFilters["selected-issues"]) {
      nFilters["selected-issues"] = parseFilters(objectFilters, "issues").map(
        (f) => f.value
      );
    }
    setFilters({ ...filters, ...nFilters });
  };

  const getMapType = () => {
    if (filters.geoLevel === "1") {
      return "province";
    } else if (filters.geoLevel === "2") {
      return "federal";
    } else {
      return "provincial";
    }
  };

  const prepareLineChartData = (metric, useFilters = true) => {
    let f = {
      view: "1",
      metric: metric,
      issue: filters.issue,
    };
    if (id) {
      f.view = "2";
      f.province = id;
    }

    if (metric === "6") {
      delete f.issue;
    }

    if (!useFilters) {
      f.issueType = issueType;
      delete f.issue;
    }

    let so = socialData.filter(function (item) {
      for (var key in f) {
        if (item[key] === undefined || item[key] !== f[key]) return false;
      }
      return true;
    });

    if (so[0]) {
      let array = [];
      for (const [key] of Object.entries(so[0].data)) {
        let obj = {};
        obj.name = key;
        obj.marker = { enabled: false };
        let unordered = so[0].data[key];
        const ordered = Object.keys(unordered)
          .sort()
          .reduce((obj, key) => {
            obj[key] = unordered[key];
            return obj;
          }, {});

        obj.data = Object.values(ordered);
        obj.headers = Object.keys(ordered).map(
          (h) =>
            new Date(h).toGMTString().split(" ")[1] +
            "-" +
            new Date(h).toGMTString().split(" ")[2]
        );
        array.push(obj);
      }

      return array;
    }
  };

  const prepareTableData = (col = "name", sort = "asc") => {
    let f = JSON.parse(JSON.stringify(filters));
    let obj = {};

    delete f.province;
    delete f.issue;
    delete f["selected-issues"];

    let so = socialData.filter(function (item) {
      for (var key in f) {
        if (item[key] === undefined || item[key] !== f[key]) return false;
      }
      return true;
    });

    so.forEach((s) => {
      let all = {};
      Object.values(s.data).forEach((k) => {
        all = { ...all, ...k };
      });

      let items = filters.geoLevel === "2" ? all : s.data;
      if (id) {
        items = s.data[id];
      }
      for (let [key, value] of Object.entries(items)) {
        key = mapProvincesData()[key] || ridings[key] || key;
        let issueName = parseFilters(
          objectFilters,
          filters.issueType === "1" ? "9" : "10"
        ).find((f) => f.value === s.issue)?.label;

        if (!obj[key]) {
          obj[key] = {};
        }
        if (!obj[key][issueName]) {
          if (value.value === "NA") {
            obj[key][issueName] = "NA";
          } else {
            obj[key][issueName] =
              "<span style='display: inline-block;width:30px'>" +
              value.value +
              "%</span> | " +
              (value.change < 0
                ? "<span style='display: inline-block;width:30px; color:#D0703B'>"
                : "<span style='display: inline-block;width:30px; color:#53818A'>") +
              value.change +
              "%</span>";
          }
        }
      }
    });
    let arr = Object.keys(obj).map((k) => {
      obj[k].name = k;
      return obj[k];
    });

    if (arr.length) {
      arr =
        sort === "asc"
          ? arr.sort((a, b) => a[col] && a[col].localeCompare(b[col]))
          : arr.sort((b, a) => a[col] && a[col].localeCompare(b[col]));
    }

    return arr;
  };

  const getSupportData = () => {
    let params = "year=" + (supportFilters.year ? supportFilters.year : "2021");

    if (supportFilters.province) {
      params = params + "&province=" + supportFilters.province;
    }

    if (supportFilters.gender) {
      params = params + "&gender=" + supportFilters.gender;
    }

    if (supportFilters.ageRange) {
      params = params + "&ageRange=" + supportFilters.ageRange;
    }

    API.get("backend", "/get-survey-data?" + params, null).then((support) => {
      setSupportData(support);
    });
  };

  const sortTableByCol = (sortColumn, sortType) => {
    setTableSorting(sortType);
    setTableLoading(true);
    setSortColumn(sortColumn);
    setTimeout(() => {
      let t =
        sortType === "asc"
          ? tableData.sort((a, b) => a[sortColumn].localeCompare(b[sortColumn]))
          : tableData.sort((b, a) =>
              a[sortColumn].localeCompare(b[sortColumn])
            );
      setTableData(t);
      setTableLoading(false);
    }, 100);
  };

  const getTop3Actors = () => {
    let p = filters.issue ? "&issue=" + filters.issue : "";
    p = p + (filters.province ? "&province=" + filters.province : "");
    API.get("api", "/top3/?type=1" + p, null).then((top3) => {
      setTop3Actors(top3);
    });
  };

  const getTop3Legislations = () => {
    let p = filters.issue ? "&issue=" + filters.issue : "";
    p = p + (filters.province ? "&province=" + filters.province : "");
    API.get("api", "/top3/?type=2" + p, null).then((top3) => {
      setTop3Legislations(top3);
    });
  };

  return (
    <div className="NationalOverview">
      {socialData.length === 0 && <LoadingPlaceholders />}
      {socialData.length !== 0 && (
        <div>
          {
            <div className="container">
              <h5 className="float-left mt-15px header-style">
                {t("nationaloverview.support")}
              </h5>
              <div className="float-right filters-style">
                <SupportFilters applyFilters={applySupportFilters} id={id} />
              </div>
              <div className="clearfix" />
              <Divider className="divider mb14" />
              <Grid
                style={{ position: "relative", paddingBottom: "30px" }}
                fluid
              >
                <Row
                  className="show-grid"
                  style={{ backgroundColor: "#F5F5F5" }}
                >
                  <Col md={8}>
                    <SemiPie
                      data={supportData}
                      type="Awareness"
                      color="#2F469C"
                    />
                  </Col>
                  <Col md={8}>
                    <SemiPie
                      data={supportData}
                      type="Acceptance"
                      color="#009D9C"
                    />
                  </Col>
                  <Col md={8}>
                    <SemiPie
                      data={supportData}
                      type="Advocacy"
                      color="#774399"
                    />
                  </Col>
                </Row>
                <div
                  style={{
                    position: "absolute",
                    bottom: 30,
                    paddingBottom: 10,
                    left: "39%",
                    fontSize: 25,
                  }}
                >
                  <span
                    style={{
                      color: "#323232",
                      fontSize: 18,
                      fontFamily: "apercu-mono-bold",
                    }}
                  >
                    {t("nationaloverview.net_vote_score")} :
                  </span>
                  <span style={{ color: "#D0703B" }}>
                    {supportData?.netVoteScore?.value}%
                  </span>{" "}
                  <span style={{ color: "#9EC0C7", fontSize: 15 }}>
                    {supportData?.netVoteScore?.change}%
                  </span>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 30,
                    paddingBottom: 10,
                    right: "20px",
                    fontSize: 12,
                  }}
                >
                  <span
                    style={{
                      color: "#323232",
                      fontFamily: "apercu-mono-bold",
                    }}
                  >
                    {t("nationaloverview.survey_participants")} :
                  </span>
                  <span style={{ color: "#323232" }}>
                    {supportData?.totalParticipants?.value}
                  </span>
                </div>
              </Grid>
            </div>
          }
          <div className="container mb-0 mt-4">
            <h5 className="float-left mt-12px  header-style">
              {id
                ? t("nationaloverview.province_overview")
                : t("nationaloverview.national_overview")}
            </h5>
            <div className="float-right filters-style ">
              <OverviewFilters
                applyFilters={applyFilters}
                type={id}
                isTable={show === "table"}
              />
            </div>
            <div className="clearfix" />
            <Divider className="divider dividerHeight mb15" />
            <Grid fluid>
              {show === "map" && (
                <Row className="show-grid">
                  <Col md={16}>
                    {!id && (
                      <MapChart
                        show="all"
                        type={getMapType()}
                        data={prepareMapData()}
                        metric={filters.metric}
                      />
                    )}
                    {provinceList().map(
                      (data) =>
                        id === data.value && (
                          <MapChart
                            key={data.value}
                            show={data.value}
                            type={getMapType()}
                            data={prepareMapData(data.value)}
                            metric={filters.metric}
                          />
                        )
                    )}
                  </Col>
                  <Col md={8} className="pt-4">
                    <Top3Actors data={top3Actors} />
                    <br />
                    <Top3LegislationPending data={top3Legislations} />
                  </Col>
                </Row>
              )}
              {show === "table" && (
                <Row className="show-grid">
                  <Table
                    height={700}
                    className="mb-4"
                    data={prepareTableData(sortColumn, tableSorting)}
                    sortType={tableSorting}
                    loading={tableLoading}
                    sortColumn={sortColumn}
                    onSortColumn={sortTableByCol}
                    onRowClick={(data) => {
                      console.log(data);
                    }}
                  >
                    <Table.Column width={200} align="left" fixed sortable>
                      <Table.HeaderCell>Title</Table.HeaderCell>
                      <Table.Cell dataKey="name">
                        {(rowData) => (
                          <span className="main-row">{rowData.name}</span>
                        )}
                      </Table.Cell>
                    </Table.Column>
                    {filters["selected-issues"].map((i) => (
                      <Table.Column
                        key={getIssue(i).value}
                        width={150}
                        align="center"
                        sortable
                      >
                        <Table.HeaderCell>{getIssue(i).label}</Table.HeaderCell>
                        <Table.Cell dataKey={getIssue(i).label}>
                          {(rowData) => (
                            <div
                              className="rest-row"
                              dangerouslySetInnerHTML={{
                                __html: rowData[getIssue(i).label],
                              }}
                            />
                          )}
                        </Table.Cell>
                      </Table.Column>
                    ))}
                  </Table>
                </Row>
              )}
              <ButtonToolbar className="pull-right bordered">
                <ButtonGroup>
                  <Button
                    onClick={() => setShow("map")}
                    appearance={show === "map" ? "default" : "subtle"}
                  >
                    {t("nationaloverview.map")}
                  </Button>
                  <Button
                    onClick={() => setShow("table")}
                    appearance={show === "table" ? "default" : "subtle"}
                  >
                    {t("nationaloverview.table")}
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Grid>
          </div>
          <Grid
            className="container p-0 mt-4"
            style={{
              backgroundColor: "transparent",
              border: 0,
              boxShadow: "none",
            }}
          >
            <Row>
              <Col md={8}>
                <div className="container m-0" style={{ width: "100%" }}>
                  <LineChart
                    title={t("nationaloverview.volume_of_mentions")}
                    className="charts-title"
                    data={prepareLineChartData("3")}
                  />
                </div>
              </Col>
              <Col md={8} className="pl-4 pr-4">
                <div className="container m-0" style={{ width: "100%" }}>
                  <LineChart
                    title={t("nationaloverview.sources_share_of_voices")}
                    className="charts-title"
                    data={prepareLineChartData("4")}
                  />
                </div>
              </Col>
              <Col md={8}>
                <div className="container m-0" style={{ width: "100%" }}>
                  <LineChart
                    title={t("nationaloverview.authors_share_of_voice")}
                    className="charts-title"
                    data={prepareLineChartData("5")}
                  />
                </div>
              </Col>
            </Row>
          </Grid>
          <div className="container mt-4">
            <div className="pull-left">
              <h5 className="mt-15px  header-style bottom-zero">
                {t("nationaloverview.trends")}
              </h5>
            </div>
            <SelectPicker
              className="pull-right mr-8px filter-font border-style"
              placeholder="Issue type"
              data={[
                { label: "RBH", value: "1" },
                { label: "National", value: "2" },
              ]}
              value={issueType}
              onChange={(v) => setIssueType(v)}
              searchable={false}
              appearance="subtle"
            />
            <div className="clearfix"></div>
            <LineChart data={prepareLineChartData("6", false)} />
          </div>
        </div>
      )}
      {socialData.length === 0 && <div></div>}
    </div>
  );
}

export default withNamespaces()(NationalOverview);
