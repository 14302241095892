import React from "react";
import { Panel, Placeholder } from "rsuite";
import Indicator from "../Indicator/LoadingIndicator";

const LoadingPlaceholders = () => {
  const { Paragraph } = Placeholder;
  const Panels = () => {
    const arr = [];
    for (let x = 0; x < 4; x++) {
      arr.push(
        <Panel key={x} className="m-2 mb-4" shaded>
          <Paragraph
            style={{ margin: 30, width: "100%" }}
            rows={3}
            graph="circle"
          />
        </Panel>
      );
    }
    return arr;
  };
  return (
    <div>
      <Panels />
      <div
        style={{
          position: "fixed",
          zIndex: "7",
          top: "35%",
          left: "47%",
        }}
      >
        <Indicator />
      </div>
    </div>
  );
};

export default LoadingPlaceholders;
