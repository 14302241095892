import React, { useCallback, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { currentUser } from "../../services/userService";
import { chunk } from "../../services/utilityService";
import "./Leadership.css";
import { withNamespaces } from "react-i18next";
import { Divider, Grid, Row, Col, Button, Message, Icon } from "rsuite";
import ActorsCard from "../../components/shared/objects/ActorsCard/ActorsCard";
import ObjectFilters from "../../components/shared/objects/ObjectFilters/ObjectFilters";
import LoadingPlaceholders from "../../components/shared/LoadingPlaceholders/LegislationLeadership/LoadingPlaceholders";

let i = 0;

function Leadership({ t }) {
  const [loading, setLoading] = useState(true);
  const [objects, setObjects] = useState([]);
  const [objectChunk, setObjectChunk] = useState([]);
  const [subscriptions, setSubscriptions] = useState();
  const [appliedFilters, setAppliedFilters] = useState({});
  const user = currentUser();

  const getSubscriptions = useCallback(() => {
    API.get("api", "/subscriptions/" + user.id, null).then((subs) => {
      setSubscriptions(subs);
    });
  }, [user.id]);

  const getSubId = (objectId) => {
    return subscriptions.find((sub) => sub.objectId === objectId)?.id;
  };

  const getMoreObjects = () => {
    i = i + 1;
    let total = [].concat.apply([], objectChunk).length;
    if (objects.length !== total) {
      setObjects(objects.concat(objectChunk[i]));
    }
  };

  const filterObjects = (objects) => {
    i = 0;
    let ch = chunk(objects, 9);
    setObjectChunk(ch);
    setObjects(ch.length ? ch[0] : objects);
    setLoading(false);
  };

  const applyFilters = (filters) => {
    setAppliedFilters(filters);
  };

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  return (
    <div className="Leadership">
      <div className="container">
        <h5 className="float-left mt-15px header-style">
          {t("leadership.leaders")}
        </h5>
        <div className="float-right mt-3 filters-style">
          <ObjectFilters
            type="1"
            filteredObjects={filterObjects}
            appliedFilters={applyFilters}
          />
        </div>
        <div className="clearfix" />
        <Divider />
        <div className="mt-4">
          <Grid id="grid_leadership" fluid>
            <Row gutter={15}>
              {subscriptions && objects ? (
                objects.map((object) => (
                  <Col key={object.id} lg={8} md={12} className="pb-4">
                    <ActorsCard
                      objectId={object.id}
                      objectRank={object.rank}
                      subId={getSubId(object.id)}
                      reloadList={getSubscriptions}
                      filtersChanged={appliedFilters}
                    />
                  </Col>
                ))
              ) : (
                <LoadingPlaceholders />
              )}
            </Row>
          </Grid>
          {objects.length === 0 && loading === false && (
            <Message type="warning" description={t("shared.no_result_found")} />
          )}
          {objects &&
            [].concat.apply([], objectChunk).length !== objects.length && (
              <Button className="load-more" onClick={getMoreObjects}>
                {t("shared.load_more")}
              </Button>
            )}
          {window.scrollY > 0 && (
            <Button
              onClick={() => window.scrollTo(0, 0)}
              className="scroll-top"
            >
              <Icon icon="sort-up"></Icon>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Leadership);
