import React from "react";
import "./CompaniesCited.css";
import { Panel, Divider, Placeholder, Loader, Badge, Avatar } from "rsuite";
import avatar from "../../../../assets/Images/avatar-placeholder.png";

export default function CompaniesCited(props) {
  const object = {};
  const loading = false;
  const { Paragraph } = Placeholder;

  const addDefaultAvatar = (ev) => {
    ev.target.src = avatar;
  };

  //list of obejcts for top 3 to loop throught them
  const a = Object.entries(props.data?.data?.companiesCited);
  const showObject = () => (
    <div>
      <div className="title">Companies cited</div>

      <Divider className="m-0 mb-2" />
      <div className="pt-4">
        {a.map((i) => {
          return (
            <div key={i} className="pull-left">
              <div className="badgePadding">
                <Badge circle="true" content={i[1]}>
                  <Avatar className="avatar-border">
                    <img
                      alt="logo"
                      style={{ height: "100%" }}
                      onError={addDefaultAvatar}
                      src={
                        "https://cpa-app-assets.s3.amazonaws.com/company-logos/" +
                        i[0] +
                        ".png"
                      }
                    />
                  </Avatar>
                </Badge>
              </div>{" "}
            </div>
          );
        })}{" "}
      </div>
    </div>
  );

  return (
    <Panel className="CompaniesCited no-padding" shaded>
      {loading && <Loader backdrop center content="loading" />}
      {!object && (
        <div>
          <Paragraph style={{ margin: 10 }} />
          <Paragraph style={{ margin: 30 }} />
        </div>
      )}
      {object && showObject()}
    </Panel>
  );
}
