import React, { useEffect, useState } from "react";
import "./SupportFilters.css";
import { SelectPicker } from "rsuite";
import {
  provinceList,
  parseFilters,
  compare,
} from "../../../services/utilityService";
import { withNamespaces } from "react-i18next";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";

function SupportFilters(props) {
  const [objectFilters, setObjectFilters] = useState([]);
  const [filters, setFilters] = useState({
    year: "2021",
  });
  const { id } = useParams();

  const applyFilters = (type, value) => {
    setFilters({ ...filters, [type]: value });
  };

  useEffect(() => {
    if (id) {
      applyFilters("province", id);
    } else {
      applyFilters("province", null);
    }
    getFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    props.applyFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const getFilters = () => {
    API.get("api", "/meta/", null).then((filters) => {
      setObjectFilters(filters);
    });
  };

  return (
    <div className="SupportFilters">
      {props.id && (
        <SelectPicker
          placeholder="Province"
          sort={() => {
            return (a, b) => {
              return compare(a.label, b.label);
            };
          }}
          data={provinceList()}
          value={filters.province}
          onChange={(v) => applyFilters("province", v ? v.toString() : "")}
          className="mr-8px filter-font border-style"
          searchable={false}
          appearance="subtle"
        />
      )}
      <SelectPicker
        placeholder="Gender"
        sort={() => {
          return (a, b) => {
            return compare(a.label, b.label);
          };
        }}
        data={parseFilters(objectFilters, "13")}
        value={filters.gender}
        onChange={(v) => applyFilters("gender", v ? v.toString() : "")}
        className="mr-8px filter-font border-style"
        searchable={false}
        appearance="subtle"
      />
      <SelectPicker
        placeholder="Age Range"
        sort={() => {
          return (a, b) => {
            return compare(a.label, b.label);
          };
        }}
        data={parseFilters(objectFilters, "11")}
        value={filters.ageRange}
        onChange={(v) => applyFilters("ageRange", v ? v.toString() : "")}
        className="mr-8px filter-font border-style"
        searchable={false}
        appearance="subtle"
      />
      <SelectPicker
        placeholder="Year"
        sort={() => {
          return (a, b) => {
            return compare(a.label, b.label);
          };
        }}
        data={parseFilters(objectFilters, "12")}
        value={filters.year}
        onChange={(v) => applyFilters("year", v ? v.toString() : "")}
        className="mr-8px filter-font border-style"
        searchable={false}
        appearance="subtle"
      />
    </div>
  );
}

export default withNamespaces()(SupportFilters);
