import React from "react";
import "./NotFound.css";
import { withNamespaces } from "react-i18next";

function NotFound({ t }) {
  return (
    <div className="NotFound">
      <h3>{t("notfound.sorry_page_not_found")}</h3>
    </div>
  );
}

export default withNamespaces()(NotFound);
