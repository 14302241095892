import React from "react";
import "./Top3Issues.css";
import { Panel, Divider, Placeholder, Loader, Grid, Row } from "rsuite";
import { withNamespaces } from "react-i18next";

function Top3Issues(props) {
  const object = {};
  const loading = false;
  const { Paragraph } = Placeholder;
  const data = props.data;

  const showObject = () => (
    <div className="panel-header">
      <div className="title">{props.t("top.top_3_issues_today")}</div>

      <Divider className="m-0" />
      <Grid fluid>
        {data.map((issue) => {
          return (
            <Row key={issue} className="row-space">
              {issue[0]} <br />
            </Row>
          );
        })}
      </Grid>
    </div>
  );

  return (
    <Panel className="Top3Issues no-padding" shaded>
      {loading && <Loader backdrop center content="loading" />}
      {!object && (
        <div>
          <Paragraph style={{ margin: 10 }} />
          <Paragraph style={{ margin: 30 }} />
        </div>
      )}
      {object && showObject()}
    </Panel>
  );
}
export default withNamespaces()(Top3Issues);
