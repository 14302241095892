import React, { useCallback, useEffect, useState } from "react";
import "./Top3Actors.css";
import {
  Panel,
  Divider,
  Placeholder,
  Loader,
  Avatar,
  Grid,
  Row,
  Col,
  Tooltip,
  Whisper,
} from "rsuite";
import { withNamespaces } from "react-i18next";
import avatar from "../../../../assets/Images/avatar-placeholder.png";
import { Link } from "react-router-dom";
import CPAIcon from "../../../core/CPAIcons";
import { API } from "aws-amplify";
import { notify } from "../../../../services/utilityService";
import { currentUser } from "../../../../services/userService";

function Top3Actors(props) {
  const object = {};
  const loading = false;
  const { Paragraph } = Placeholder;
  const [subscriptions, setSubscriptions] = useState([]);
  const user = currentUser();

  const addDefaultAvatar = (ev) => {
    ev.target.src = avatar;
  };

  const getSubscriptions = useCallback(() => {
    API.get("api", "/subscriptions/" + user.id, null).then((subs) => {
      setSubscriptions(subs);
    });
  }, [user.id]);

  /**
   * Follow Object
   * @param objectId
   */
  const follow = (objectId, type) => {
    let data = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        objectId: objectId,
        type: type,
        userId: user.id,
      },
    };

    API.post("api", "/subscriptions", data).then((object) => {
      notify("success", "Followed successfully");
      if (props.reloadObject) {
        props.reloadObject();
      } else {
        getSubscriptions();
      }
    });
  };

  const getSubId = (objectId) => {
    return subscriptions.find((sub) => sub.objectId === objectId)?.id;
  };

  /**
   * Unfollow Object
   * @param subId
   */
  const unFollow = (subId) => {
    API.del("api", "/subscriptions/" + subId, null).then(() => {
      notify("success", "Unfollowed successfully");
      if (props.reloadObject) {
        props.reloadObject();
      } else {
        getSubscriptions();
      }
    });
  };

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const showObject = () => (
    <div className="panel-header">
      <div className="title">{props.t("top.top_3_actors")}</div>

      <Divider className="m-0 mb-2" />
      <Grid className="mt-4" fluid>
        {props?.data?.length &&
          props.data.map((object) => {
            return (
              <Row key={object.id}>
                <Col xs={3} lg={3}>
                  <Link to={"/leadership-board/profile/" + object.id}>
                    <Avatar
                      className={
                        "profile-pic pull-left " +
                        (object.adminLevel === "1" && "federal")
                      }
                      circle
                    >
                      <img
                        alt="Profile Pic"
                        width="45"
                        height="45"
                        onError={addDefaultAvatar}
                        src={
                          "https://cpa-app-assets.s3.amazonaws.com/object-images/" +
                          object.id +
                          ".jpeg"
                        }
                      />
                    </Avatar>
                  </Link>
                </Col>
                <Col xs={21} lg={21} className="content">
                  <Link to={"/leadership-board/profile/" + object.id}>
                    {object.name}
                  </Link>{" "}
                  <Whisper
                    placement="right"
                    speaker={
                      <Tooltip>
                        {props.t("filters.monthly_change")}:{" "}
                        {object.details.activityIndexChange}%
                      </Tooltip>
                    }
                  >
                    <span>
                      {object.details.activityIndexChange > 0 ? (
                        <CPAIcon color="#53818A" icon="up" size={20} />
                      ) : object.details.activityIndexChange === 0 ? (
                        <CPAIcon icon="neutralarrow" size={20} />
                      ) : (
                        <CPAIcon color="#B14E4E" icon="down" size={20} />
                      )}
                    </span>
                  </Whisper>
                  <br />
                  <Whisper
                    placement="bottomStart"
                    speaker={<Tooltip>{object.title}</Tooltip>}
                  >
                    <div className="jobtitle">{object.title}</div>
                  </Whisper>
                  <div className="left-border pull-right">
                    {getSubId(object.id) && (
                      <div
                        className="cursor"
                        onClick={() => unFollow(getSubId(object.id))}
                      >
                        <CPAIcon color="#BFBFBF" icon="unfollow" size={30} />
                      </div>
                    )}
                    {!getSubId(object.id) && (
                      <div
                        className="cursor"
                        onClick={() => follow(object.id, object.objectType)}
                      >
                        <CPAIcon color="#BFBFBF" icon="follow" size={30} />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            );
          })}
      </Grid>
    </div>
  );

  return (
    <Panel className="Top3Actors no-padding" shaded>
      {loading && <Loader backdrop center content="loading" />}
      {!object && (
        <div>
          <Paragraph style={{ margin: 10 }} />
          <Paragraph style={{ margin: 30 }} />
        </div>
      )}
      {object && showObject()}
    </Panel>
  );
}

export default withNamespaces()(Top3Actors);
