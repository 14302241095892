import { Divider, Row, SelectPicker, Grid, Col } from "rsuite";
import { API } from "aws-amplify";
import ActorsCard from "../../../../components/shared/objects/ActorsCard/ActorsCard";
import React, { useEffect } from "react";
import { currentUser } from "../../../../services/userService";
import { useStateIfMounted } from "use-state-if-mounted";
import "./Subscriptions.css";
import LegislationCard from "../../../../components/shared/objects/LegislationCard/LegislationCard";

import { withNamespaces } from "react-i18next";

function Subscriptions({ t }) {
  const [subscriptions, setSubscriptions] = useStateIfMounted();
  const user = currentUser();
  const filters = [
    {
      label: "Filter1",
      value: "Filter1",
    },
    {
      label: "Filter2",
      value: "Filter2",
    },
  ];

  useEffect(() => {
    loadSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadSubscriptions = () => {
    API.get("api", "/subscriptions/" + user.id, null).then((subs) => {
      setSubscriptions(subs);
    });
  };

  return (
    <div className="subscription-style">
      <SelectPicker
        placeholder={t("subscriptions.filter_by_type")}
        data={filters}
        searchable={false}
        className="select-picker-width-style"
        style={{ display: "none" }}
      />
      <div className="settings pt-4">
        <h5 className="header-style">
          {t("subscriptions.leaders_and_influencers")}
        </h5>
      </div>
      <Divider className="s-divider" />
      <Grid id="grid_leadership" className="mt-4" fluid>
        <Row gutter={15}>
          {subscriptions &&
            subscriptions
              .filter((s) => s.type === "1")
              .map((sub) => (
                <Col key={sub.id} lg={8} md={12} className="pb-4">
                  <ActorsCard
                    objectId={sub.objectId}
                    subId={sub.id}
                    reloadList={loadSubscriptions}
                  />
                </Col>
              ))}
        </Row>
      </Grid>
      <div className="legislation">
        <h5 className="header-style">{t("subscriptions.legislation")}</h5>
      </div>
      <Divider className="s-divider" />
      <Grid id="grid_leadership" className="mt-4" fluid>
        <Row gutter={15}>
          {subscriptions &&
            subscriptions
              .filter((s) => s.type === "2")
              .map((sub) => (
                <Col key={sub.id} lg={8} md={12} className="pb-4">
                  <LegislationCard
                    objectId={sub.objectId}
                    subId={sub.id}
                    reloadList={loadSubscriptions}
                  />
                </Col>
              ))}
        </Row>
      </Grid>
    </div>
  );
}

export default withNamespaces()(Subscriptions);
