import { useEffect } from "react";
import { API } from "aws-amplify";
import { notify } from "../services/utilityService";
import { currentUser } from "../services/userService";
import { useStateIfMounted } from "use-state-if-mounted";
import { getObjectData } from "../services/objectService";

function useObject(props) {
  const [object, setObject] = useStateIfMounted();
  const [objectInfo, setObjectInfo] = useStateIfMounted();
  const [loading, setLoading] = useStateIfMounted(false);
  const user = currentUser();

  /**
   * Follow Object
   * @param objectId
   */
  const follow = (objectId, type) => {
    setLoading(true);
    let data = {
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        objectId: objectId,
        type: type,
        userId: user.id,
      },
    };

    API.post("api", "/subscriptions", data).then((object) => {
      props.reloadList();
      notify("success", "Followed successfully");
      setLoading(false);
    });
  };

  /**
   * Unfollow Object
   * @param subId
   */
  const unFollow = (subId) => {
    setLoading(true);
    API.del("api", "/subscriptions/" + subId, null).then(() => {
      notify("success", "Unfollowed successfully");
      props.reloadList();
      setLoading(false);
    });
  };

  /**
   * Get Object details on load.
   */
  useEffect(
    () => {
      API.get("api", "/objects/" + props.objectId, null).then((object) => {
        setObject(object);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.objectId]
  );

  /**
   * Get extra details on load and change on filter change.
   */
  useEffect(() => {
    getObjectData(props.objectId, props.filtersChanged?.issue).then(
      (object) => {
        setObjectInfo(object);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filtersChanged]);

  return {
    object,
    objectInfo,
    follow,
    unFollow,
    loading,
  };
}

export default useObject;
