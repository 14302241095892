import React, { useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";

import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import {
  buildUserAvatarUrl,
  clearCurrentUser,
  currentUser,
} from "../services/userService";
import { appEmitter, buildLetters } from "../services/utilityService";
import LogoImg from "../assets/Images/LogoIpsos.svg";
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  Container,
  Avatar,
  Sidebar,
  Sidenav,
  Nav,
  Footer,
  Grid,
  Row,
  Col,
  Badge,
} from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import i18n from "../i18n";
import { withNamespaces } from "react-i18next";
import "./Dashboard.css";
import CPAIcon from "../components/core/CPAIcons";

function Dashboard({ t, children }) {
  const { userHasAuthenticated, isAuthenticated } = useAppContext();
  const [expand] = useState(true);
  const [showMenu, setShowMenu] = useState(true);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [newFeeds, setNewFeeds] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const user = currentUser();

  // let animateTimeOut;

  async function handleLogout() {
    await Auth.signOut();
    clearCurrentUser();
    userHasAuthenticated(false);
    history.push("/login");
  }

  function changeLanguage(lng) {
    i18n.changeLanguage(lng);
  }

  function navigateTo(url) {
    if (url === "/") {
      setNewFeeds(0);
      document.title = "Canadian Political Atlas";
      if (location.pathname === url) {
        window.location.reload();
      }
    }
    history.push(url);
    if (width < 1410) {
      setShowMenu(false);
    }
  }

  function isActive(url) {
    if (location.pathname === url) return true;
    return location.pathname.includes(url) && url !== "/";
  }

  const showMenuNav = () => {
    setShowMenu((showMenu) => !showMenu);
  };

  /*function animateTitle(show = true) {
      document.title = show ? '(' + newFeeds + 1 + ') Canadian Political Atlas' : 'Canadian Political Atlas';
      animateTimeOut = setTimeout(() => {
        animateTitle(!show);
      }, 1000);
    }*/

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    appEmitter.on("newFeed", () => {
      setNewFeeds((newFeeds) => {
        document.title =
          "(" + Number(newFeeds + 1) + ") Canadian Political Atlas";
        return newFeeds + 1;
      });
    });
  }, []);

  useEffect(() => {
    if (width <= 1420) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
  }, [width]);

  return (
    <div className="dashboard">
      <Container id="main-container" style={allPage}>
        <div>
          {showMenu && (
            <Sidebar
              style={{
                display: isAuthenticated && showMenu ? "flex" : "none",
                flexDirection: "column",
                backgroundColor: "white",
              }}
              className="side-nav"
              width={expand ? 240 : 56}
              collapsible
            >
              <Sidenav.Header className="sticky-header">
                <div className="title-style">
                  <a
                    className="logo-link"
                    href="https://canada.political-atlas.com"
                  >
                    Canadian Political Atlas
                  </a>
                </div>
              </Sidenav.Header>
              <Sidenav
                expanded={expand}
                defaultOpenKeys={["3"]}
                appearance="subtle"
              >
                <Sidenav.Body className="sticky-menu">
                  <Nav>
                    <Nav.Item
                      onClick={() => navigateTo("/")}
                      active={isActive("/") && true}
                      className="menu-item-class"
                    >
                      {newFeeds !== 0 && (
                        <Badge className="new-feed" content={newFeeds}>
                          {t("dashboard.news_feed")}
                        </Badge>
                      )}
                      {newFeeds === 0 && t("dashboard.news_feed")}
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => navigateTo("/leadership-board")}
                      active={isActive("/leadership-board") && true}
                      className="menu-item-class"
                    >
                      {t("dashboard.leadership_board")}
                    </Nav.Item>
                    <Nav.Item
                      style={{ display: "block" }}
                      onClick={() => navigateTo("/legislation-board")}
                      className="menu-item-class"
                      active={isActive("/legislation-board") && true}
                    >
                      {t("dashboard.legislation_board")}
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => navigateTo("/national-overview")}
                      className="menu-item-class"
                      active={isActive("/national-overview") && true}
                    >
                      {t("dashboard.nationwide_overview")}
                    </Nav.Item>
                    <Nav.Item
                      onClick={() => navigateTo("/province-overview/AB")}
                      className="menu-item-class"
                      active={isActive("/province-overview") && true}
                    >
                      {t("dashboard.province_overview")}
                    </Nav.Item>
                    <Nav.Item
                      style={{ display: "block" }}
                      onClick={() => navigateTo("/academic-publication")}
                      className="menu-item-class"
                      active={isActive("/academic-publication") && true}
                    >
                      {t("dashboard.publications_board")}
                    </Nav.Item>
                  </Nav>
                </Sidenav.Body>
              </Sidenav>
              <Footer className="footer-side-menu-style">
                <Grid fluid>
                  <Row>
                    <Col xs={24}>
                      <ButtonToolbar>
                        <ButtonGroup className="btn-grp">
                          <Button
                            size="lg"
                            onClick={() => changeLanguage("en")}
                            appearance={"default"}
                          >
                            EN
                          </Button>
                          <Button
                            size="lg"
                            onClick={() => changeLanguage("fr")}
                            appearance={"subtle"}
                          >
                            FR
                          </Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </Col>
                  </Row>
                  <Row className="pt-12">
                    <Col xs={6}>
                      <Link className="user-pic" to="/settings">
                        <Avatar
                          title={user?.fullName}
                          alt={user?.fullName}
                          className="pull-left avatar-link"
                          circle
                        >
                          {user?.hasAvatar ? (
                            <img
                              alt={user.fullName}
                              src={buildUserAvatarUrl(user.id)}
                              width="40px"
                              height="40px"
                            />
                          ) : (
                            buildLetters(user?.fullName)
                          )}
                        </Avatar>
                      </Link>
                    </Col>
                    <Col xs={6}>
                      <div onClick={handleLogout}>
                        <Button size="lg" appearance="default">
                          {t("dashboard.logout")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-12">
                    <Col xs={6}>
                      <a href="/">
                        <img alt="logo" src={LogoImg} />
                      </a>
                    </Col>

                    <Col xs={18}>
                      <a
                        href="mailto:info@canada.political-atlas.com"
                        className="hrefStyle"
                      >
                        {t("dashboard.email_us")}
                      </a>
                      <br />{" "}
                      <a href="/" className="hrefStyle">
                        {t("dashboard.about_the_site")}
                      </a>
                    </Col>
                  </Row>
                </Grid>
              </Footer>
              {showMenu && width < 1410 && (
                <div className="nav-close" onClick={() => setShowMenu(false)}>
                  <CPAIcon
                    icon="close"
                    size={20}
                    color="#000"
                    strokeWidth={7}
                  />
                </div>
              )}
            </Sidebar>
          )}
          {showMenu && width < 1410 && (
            <div
              className="drop-overlay"
              onClick={() => setShowMenu(false)}
            ></div>
          )}
          {isAuthenticated && (
            <div>
              {!showMenu && (
                <div className="hamburger cursor p-3" onClick={showMenuNav}>
                  <CPAIcon
                    icon="burgermenu"
                    size={30}
                    color="#fff"
                    strokeWidth={5}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <Container className={isAuthenticated ? "wrapper" : ""}>
          {children}
        </Container>
        <Footer
          style={{
            display: isAuthenticated ? "none" : "block",
            position: "fixed",
            bottom: "0",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <div className="nav-footer-login">
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={3}>
                  <img alt="logo" src={LogoImg} className="p-1" />
                </Col>
                <Col xs={5} className="color-txt">
                  <a href="/">
                    {t("dashboard.read_more")} <br />{" "}
                    <b> {t("dashboard.about_the_site")}</b>
                  </a>
                </Col>

                <Col xs={10} className="color-txt">
                  {t("dashboard.questions")} <br />
                  <b>
                    <a
                      href="mailto:info@canada.political-atlas.com"
                      className="hrefStyle"
                    >
                      info@canada.political-atlas.com
                    </a>
                  </b>
                </Col>
                <Col xs={6} className="pull-left lang-color-font">
                  <ButtonToolbar>
                    <ButtonGroup>
                      <Button
                        id="btnChangeLang_EN"
                        size="lg"
                        onClick={() => changeLanguage("en")}
                        appearance={"default"}
                      >
                        EN
                      </Button>
                      <Button
                        id="btnChangeLang_FR"
                        size="lg"
                        onClick={() => changeLanguage("fr")}
                        appearance={"subtle"}
                      >
                        FR
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Grid>
          </div>
        </Footer>
      </Container>
    </div>
  );
}

export default withNamespaces()(Dashboard);

const allPage = {
  minHeight: "100%",
  borderRadius: "10px",
  position: "relative",
};
