import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import map from "highcharts/modules/map";

const SemiPie = (props) => {
  const [config, setConfig] = useState({});
  map(Highcharts);

  const options = {
    chart: {
      height: 390,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      backgroundColor: "transparent",
    },
    credits: {
      enabled: false,
    },
    title: {
      text:
        props.data[props.type.toLowerCase()].value +
        '% <span style="font-size:14px;color:' +
        (props.data[props.type.toLowerCase()].change > 0
          ? "#9EC0C7"
          : "#EBBFA7") +
        '">' +
        props.data[props.type.toLowerCase()].change +
        '%</span><br><small style="font-size:15px">' +
        props.type +
        "</small>",
      align: "center",
      verticalAlign: "middle",
      y: 60,
    },
    tooltip: {},
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        tooltip: {
          headerFormat: "",
          // pointFormat:'{series.name}'
        },
        colors: [props.color, "#BFBFBF"],
        dataLabels: {
          enabled: false,
          distance: -50,
          style: {
            fontWeight: "bold",
            color: "white",
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "110%",
        animation: false,
      },
    },
    series: [
      {
        type: "pie",
        name: props.type,
        innerSize: "70%",
        data: [
          ["Awareness", props.data[props.type.toLowerCase()].value],
          ["Others", 100 - props.data[props.type.toLowerCase()].value],
        ],
      },
    ],
  };

  useEffect(() => {
    setConfig(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <div>
      {config && <HighchartsReact highcharts={Highcharts} options={config} />}
    </div>
  );
};

export default SemiPie;
