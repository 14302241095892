import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import map from "highcharts/modules/map";

const LineChart = (props) => {
  const [config, setConfig] = useState({});
  map(Highcharts);

  const options = {
    chart: {
      renderTo: "container",
      type: "spline",
      height: 300,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: props.title,
      align: "left",
      x: 0,
    },
    xAxis: {
      categories: props.data && props.data.length ? props.data[0].headers : [],
      labels: {
        step:
          props.data && props.data.length && props.data[0].headers.length - 1,
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      split: false,
      formatter: function (tooltip) {
        let items = this.points || splat(this);
        items.sort(function (a, b) {
          return a.y < b.y ? -1 : a.y > b.y ? 1 : 0;
        });
        items.reverse();
        return tooltip.defaultFormatter.call(this, tooltip);
      },
    },
    legend: {
      borderWidth: 0,
    },
    series: props.data,
  };

  useEffect(() => {
    setConfig(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  function isArray(obj) {
    return Object.prototype.toString.call(obj) === "[object Array]";
  }

  function splat(obj) {
    return isArray(obj) ? obj : [obj];
  }

  return (
    <div>
      {config && (
        <HighchartsReact
          highcharts={Highcharts}
          options={config}
          callback={(m) =>
            setTimeout(function () {
              m.reflow();
            }, 10)
          }
        />
      )}
    </div>
  );
};

export default LineChart;
