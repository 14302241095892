import React, { useEffect, useState } from "react";
import "./LegislationCard.css";
import {
  Panel,
  Divider,
  Placeholder,
  Loader,
  Progress,
  Tooltip,
  Whisper,
  Col,
  Row,
} from "rsuite";
import useObject from "../../../../hooks/object.hook";
import { getMeta } from "../../../../services/objectService";
import { withNamespaces } from "react-i18next";
import CPAIcon from "../../../core/CPAIcons";
import { Link } from "react-router-dom";
import { getstatusColor } from "../../../../services/utilityService";

function LegislationCard(props) {
  const { object, objectInfo, follow, unFollow, loading } = useObject(props);
  const [filtersMap, setFiltersMap] = useState("");
  const { Paragraph } = Placeholder;
  const subId = props.subId;
  const { Circle } = Progress;

  useEffect(() => {
    getMeta("1").then((data) => {
      setFiltersMap(data);
    });
  }, []);

  const showObject = () => (
    <div>
      <div className="first-header-part">
        <div className="score pull-left">{objectInfo?.activityRank}</div>

        <Link to={"/legislation-board/profile/" + object.id}>
          <Whisper
            className="pull-left"
            placement="bottom"
            speaker={<Tooltip>{object?.billNumber}</Tooltip>}
          >
            <div className="pull-left profile-title-style ">
              {object?.billNumber}
            </div>
          </Whisper>
        </Link>
      </div>
      <Whisper
        placement="bottom"
        speaker={
          <Tooltip>
            {props.t("filters.monthly_change")}:{" "}
            {objectInfo?.activityIndexChange}%
          </Tooltip>
        }
      >
        <div className="inline arrow-wrapper pull-left">
          {objectInfo?.activityIndexChange < 0 ? (
            <CPAIcon color="#B14E4E" icon="down" size={20} />
          ) : objectInfo?.activityIndexChange === 0 ? (
            <div className="ml-2">
              <CPAIcon color="#BFBFBF" icon="neutralarrow" size={20} />
            </div>
          ) : (
            <CPAIcon color="#53818A" icon="up" size={20} />
          )}
        </div>
      </Whisper>
      <Whisper
        placement="bottom"
        speaker={
          <Tooltip>
            {" "}
            {props.t("feedpost.Sentiment")}: {objectInfo?.sentiment}
          </Tooltip>
        }
      >
        <div className="score pull-right pt-2">
          {objectInfo?.sentiment.toLowerCase() === "positive" && (
            <div className="circle positive" />
          )}
          {objectInfo?.sentiment.toLowerCase() === "negative" && (
            <div className="circle negative" />
          )}
          {objectInfo?.sentiment.toLowerCase() === "neutral" && (
            <div className="circle positive neutral" />
          )}
        </div>
      </Whisper>
      <Whisper
        placement="bottom"
        speaker={
          <Tooltip>
            {props.t("legislationcard.issues")}:
            <br />{" "}
            {object?.issueText?.map((i) => (
              <div key={i}>
                - {i}
                <br />
              </div>
            ))}
          </Tooltip>
        }
      >
        <div className="score pull-right mr-2 pt-1">
          <CPAIcon icon="issues" size={32} />
          {object?.issue?.length}
        </div>
      </Whisper>
      <br className="clearBoth" />
      <Divider className="m-0" />
      <div className="leg-card-header">
        <Whisper
          placement="bottomStart"
          speaker={<Tooltip> {object?.title}</Tooltip>}
        >
          <div>{object?.title?.slice(0, 80)}</div>
        </Whisper>
      </div>
      <div className="clearBoth" />
      <Divider className="m-0" />
      <Row>
        <Col xs={6}>
          <Whisper
            className="pull-left"
            placement="bottom"
            speaker={
              <Tooltip>
                {props.t("legislationcard.status")}:{" "}
                <b>{objectInfo?.recentStatus}</b>
                <br />
                {props.t("legislationcard.lastupdated")}:{" "}
                <b>{objectInfo?.recentUpdate}</b>
              </Tooltip>
            }
          >
            <Circle
              className="circle-wrapper"
              percent={objectInfo?.progressBar}
              width={80}
              strokeColor={getstatusColor(objectInfo?.progressBar)}
              showInfo={false}
              trailWidth={12}
              strokeWidth={13}
              strokeLinecap="butt"
            />
          </Whisper>
        </Col>
        <Col xs={18}>
          <div>
            <div className="pull-left profile-info-l">
              <div className="mb-8px">
                <CPAIcon
                  className="icon-mr"
                  color="#000"
                  icon="parliament"
                  size={20}
                />
                {object.actorId && (
                  <Link to={"/leadership-board/profile/" + object.actorId}>
                    <span className="pt-1">{object.actorName}</span>
                  </Link>
                )}
                {!object.actorId && (
                  <span className="pt-1">{object.actorName}</span>
                )}
              </div>

              <div className="mb-8px ">
                <CPAIcon
                  className="icon-mr"
                  color="#000"
                  icon="introdate"
                  size={20}
                />
                <Whisper
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("legislationcard.introduced_at")}
                    </Tooltip>
                  }
                >
                  <span className="pt-1"> {object.dateIntroduced}</span>
                </Whisper>

                <CPAIcon
                  className="icon-mr icon-ml"
                  color="#000"
                  icon="parliamentsession"
                  size={20}
                />
                <Whisper
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("legislationcard.parliament")} -{" "}
                      {props.t("legislationcard.session")}
                    </Tooltip>
                  }
                >
                  <span className="">
                    {object?.parliament}-{object?.session}
                  </span>
                </Whisper>
              </div>

              <div className="mb-8px ">
                {object.province && (
                  <CPAIcon
                    className="icon-mr"
                    color="#000"
                    icon="mapmarker"
                    size={20}
                  />
                )}
                {object.province && (
                  <span className="icon-mr"> {object.province}</span>
                )}

                <CPAIcon
                  className="icon-mr"
                  color="#000"
                  icon="level"
                  size={20}
                />
                <span className="pt-2"> {filtersMap[object.adminLevel]}</span>
              </div>
              <div className="mb-8px ">
                <CPAIcon
                  className="icon-mr"
                  color="#000"
                  icon="twitter"
                  size={20}
                />
                <Whisper
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("legislationcard.social_media_mentions")}
                    </Tooltip>
                  }
                >
                  <span className="number-font-size pl-1">
                    {objectInfo?.tweetCount || 0}
                  </span>
                </Whisper>
                {objectInfo?.tweetChange < 0 ? (
                  <CPAIcon color="#B14E4E" icon="down" size={20} />
                ) : objectInfo?.tweetChange === 0 ? (
                  <span>
                    <CPAIcon color="#BFBFBF" icon="neutralarrow" size={20} />
                  </span>
                ) : (
                  <CPAIcon color="#53818A" icon="up" size={20} />
                )}

                <CPAIcon
                  color="#000"
                  icon="megaphone"
                  size={20}
                  className="icon-mr icon-ml"
                />
                <Whisper
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("legislationcard.news_mentions")}
                    </Tooltip>
                  }
                >
                  <span className="number-font-size pl-1">
                    {objectInfo?.newsMentions || 0}
                  </span>
                </Whisper>
                {objectInfo?.blogChange < 0 ? (
                  <CPAIcon color="#B14E4E" icon="down" size={20} />
                ) : objectInfo?.newsMentionsChange === 0 ? (
                  <span>
                    {" "}
                    <CPAIcon color="#BFBFBF" icon="neutralarrow" size={20} />
                  </span>
                ) : (
                  <CPAIcon color="#53818A" icon="up" size={20} />
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className="clearfix" />
      <Divider className="m-0" />
      <div className="follow-link">
        <div
          className="cursor pull-left"
          onClick={() =>
            subId ? unFollow(subId) : follow(object.id, object.objectType)
          }
        >
          <CPAIcon
            className="mr-1"
            icon={subId ? "unfollow" : "follow"}
            size={20}
            strokeWidth={5}
            color="#b3b3b3"
          />

          {subId ? props.t("shared.unfollow") : props.t("shared.follow")}
        </div>
        <div className="pull-right goto">
          <a target="_blank" rel="noopener noreferrer" href={object.url}>
            <CPAIcon color="#53818A" icon="external" size={20} />
            {props.t("filters.go_to_bill_text")}
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <Panel className="LegislationCard" shaded>
      {loading && <Loader backdrop center content="loading" />}
      {!object && (
        <div>
          <Paragraph style={{ margin: 10 }} />
          <Paragraph style={{ margin: 30 }} graph="circle" />
        </div>
      )}
      {object && showObject()}
    </Panel>
  );
}

export default withNamespaces()(LegislationCard);
