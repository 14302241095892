import React from "react";
import "./LeadershipProfileInfo.css";
import {
  Panel,
  Avatar,
  IconButton,
  Grid,
  Row,
  Col,
  Tooltip,
  Whisper,
} from "rsuite";
import CPAIcon from "../../../core/CPAIcons";
import { withNamespaces } from "react-i18next";

function LeadershipProfileInfo(props) {
  const object = props.object;
  const showObject = () => (
    <Grid className="leadership-profile" fluid>
      <Row className="p-3">
        <Col xs={4} lg={3}>
          <Avatar className={"profile-pic pull-left federal"} circle>
            <img
              alt="Profile Pic"
              width="110"
              height="110"
              src={
                "https://cpa-app-assets.s3.amazonaws.com/object-images/" +
                object.id +
                ".jpeg"
              }
            />
          </Avatar>
        </Col>
        <Col xs={7} lg={7}>
          <div className="info">
            <CPAIcon
              color="#000"
              icon="badge"
              size={30}
              className="img-style"
            />
            {object.title}
          </div>
          <div className="info">
            <CPAIcon
              color="#000"
              icon="parliament"
              size={30}
              className="img-style"
            />
            {object.adminLevel === "1"
              ? props.t("leadership_profile_info.federal")
              : props.t("leadership_profile_info.provincial")}
          </div>
          <div className="info pb-2">
            <CPAIcon
              color="#000"
              icon="network"
              size={30}
              className="img-style"
            />
            {object.politicalAffiliation}
          </div>
        </Col>
        <Col xs={9} lg={7}>
          <div className="info">
            <CPAIcon
              color="#000"
              icon="province"
              size={30}
              className="img-style"
            />
            {object.riding}, {object.province}
          </div>
          <div className="info result-padding">
            <CPAIcon
              color="#000"
              icon="legislation"
              size={30}
              className="img-style"
            />
            <Whisper
              placement="bottom"
              speaker={
                <Tooltip>
                  {props.t("leadership_profile_info.legislation_passed")}:{" "}
                  {object.data.legilationsIntroduced}
                </Tooltip>
              }
            >
              <span>{object.data.legilationsIntroduced}</span>
            </Whisper>{" "}
            |{" "}
            <Whisper
              placement="bottom"
              speaker={
                <Tooltip>
                  {props.t("leadership_profile_info.legislation_pending")}:{" "}
                  {object.data.legislationsCollaborated}
                </Tooltip>
              }
            >
              <span>{object.data.legislationsCollaborated}</span>
            </Whisper>
          </div>
          <div className="info ex-padding">
            <CPAIcon
              color="#000"
              icon="attention"
              size={30}
              className="img-style"
            />
            {object.data.topIssues.join(", ")}
          </div>
        </Col>
        <Col xs={4} lg={7}>
          <div className="info pb-3">
            <Row>
              <Col lg={2}>
                <CPAIcon color="#000" icon="twitter" size={20} />
              </Col>
              <Col lg={5}>
                {" "}
                <Whisper
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("leadership_profile_info.tweets_posted")}:{" "}
                      {object.data?.tweetCount || 0}
                    </Tooltip>
                  }
                >
                  <span className="number-font-size">
                    {object.data?.tweetCount || 0}
                  </span>
                </Whisper>
                {object.data?.tweetCountChange < 0 && (
                  <CPAIcon color="#B14E4E" icon="down" size={20} />
                )}
                {object.data?.tweetCountChange > 0 && (
                  <CPAIcon color="#53818A" icon="up" size={20} />
                )}
                {object.data?.tweetCountChange === 0 && " -"}
              </Col>
              <Col lg={2}>
                <CPAIcon color="#000" icon="megaphone" size={20} />
              </Col>
              <Col lg={5}>
                {" "}
                <Whisper
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("leadership_profile_info.news_mentions")} :{" "}
                      {object.data?.newsMentions || 0}
                    </Tooltip>
                  }
                >
                  <span className="number-font-size">
                    {object.data?.newsMentions || 0}
                  </span>
                </Whisper>
                {object.data?.newsMentionsChange < 0 && (
                  <CPAIcon color="#B14E4E" icon="down" size={20} />
                )}
                {object.data?.newsMentionsChange > 0 && (
                  <CPAIcon color="#53818A" icon="up" size={20} />
                )}
                {object.data?.newsMentionsChange === 0 && " -"}
              </Col>
            </Row>
          </div>
          <div className="info pb-2">
            <Row>
              <Col lg={2}>
                <CPAIcon color="#000" icon="computer" size={20} />
              </Col>
              <Col lg={5}>
                <Whisper
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("leadership_profile_info.tv_mentions")} :{" "}
                      {object.data?.tvMentions || 0}
                    </Tooltip>
                  }
                >
                  <span className="number-font-size">
                    {object.data?.tvMentions}
                  </span>
                </Whisper>
                {object.data?.tvMentionsChange < 0 && (
                  <CPAIcon color="#B14E4E" icon="down" size={20} />
                )}
                {object.data?.tvMentionsChange > 0 && (
                  <CPAIcon color="#53818A" icon="up" size={20} />
                )}
                {object.data?.tvMentionsChange === 0 && " -"}
              </Col>
              <Col lg={2}>
                <CPAIcon color="#000" icon="parliament" size={20} />
              </Col>
              <Col lg={5}>
                <Whisper
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("leadership_profile_info.house_debate_mentions")}{" "}
                      : {object.data?.debateCount || 0}
                    </Tooltip>
                  }
                >
                  <span className="number-font-size">
                    {object.data?.debateCount || 0}
                  </span>
                </Whisper>
                {object.data?.debateChange < 0 && (
                  <CPAIcon color="#B14E4E" icon="down" size={20} />
                )}
                {object.data?.debateChange > 0 && (
                  <CPAIcon color="#53818A" icon="up" size={20} />
                )}
                {object.data?.debateChange === 0 && " -"}
              </Col>
              <Col lg={2}>
                <CPAIcon color="#000" icon="newsletter" size={20} />
              </Col>
              <Col lg={5}>
                <Whisper
                  placement="bottom"
                  speaker={
                    <Tooltip>
                      {props.t("leadership_profile_info.newsletter_published")}{" "}
                      : {object.data?.blogCount || 0}
                    </Tooltip>
                  }
                >
                  <span className="number-font-size">
                    {object.data?.blogCount}
                  </span>
                </Whisper>
                {object.data?.blogChange < 0 && (
                  <CPAIcon color="#B14E4E" icon="down" size={20} />
                )}
                {object.data?.blogChange > 0 && (
                  <CPAIcon color="#53818A" icon="up" size={20} />
                )}
                {object.data?.blogChange === 0 && " -"}
              </Col>
            </Row>
          </div>
          <div className="info ">
            <Whisper
              placement="bottom"
              speaker={
                <Tooltip>
                  {props.t("leadership_profile_info.twitter_account")}
                </Tooltip>
              }
            >
              <a
                rel="noopener noreferrer"
                href={"https://twitter.com/" + props.object.twitterHandler}
                target="_blank"
              >
                <IconButton
                  className="mr-2"
                  appearance="subtle"
                  style={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#F5F5F5",
                    padding: "3px",
                    height: "27px",
                  }}
                  icon={<CPAIcon color="#000" icon="twitter" size={20} />}
                />
              </a>
            </Whisper>
            <Whisper
              placement="bottom"
              speaker={
                <Tooltip>{props.t("leadership_profile_info.website")}</Tooltip>
              }
            >
              <a
                rel="noopener noreferrer"
                href={props.object.webSite}
                target="_blank"
              >
                <IconButton
                  className="mr-2"
                  appearance="subtle"
                  style={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: "#F5F5F5",
                    padding: "3px",
                    height: "27px",
                  }}
                  icon={<CPAIcon color="#000" icon="external" size={20} />}
                />
              </a>
            </Whisper>
          </div>
        </Col>
      </Row>
    </Grid>
  );

  return (
    <Panel className="leadership-profile no-padding" shaded>
      {showObject()}
    </Panel>
  );
}

export default withNamespaces()(LeadershipProfileInfo);
