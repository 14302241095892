import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../../components/shared/LoaderButton/LoaderButton";
import { useFormFields } from "../../../libs/hooksLib";
import { onError } from "../../../libs/errorLib";
import "./Signup.css";
import { withNamespaces } from "react-i18next";
import { Tooltip, Whisper } from "rsuite";

function Signup({ t }) {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
    fullName: "",
  });

  const strongPasswordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!_@#$%^&*])(?=.{6,})"
  );
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Errors, setErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    fullName: "",
  });

  const tooltip = <Tooltip>{t("shared.password_rule")}</Tooltip>;

  function validateForm() {
    let errors = {};
    let isValid = true;

    if (fields.password.length === 0) {
      isValid = false;
      errors["password"] = t("signup.please_enter_your_password");
    }

    if (fields.password && fields.confirmPassword) {
      if (fields.password !== fields.confirmPassword) {
        isValid = false;
        errors["password"] = t("signup.password_dont_match");
      } else {
        if (!strongPasswordRegex.test(fields.password)) {
          isValid = false;
          errors["password"] = t(
            "signup.please_enter_password_that_matches_the_rule"
          );
        }
      }
    }

    if (fields.confirmPassword.length === 0) {
      isValid = false;
      errors["confirmpassword"] = t("signup.please_confirm_your_password");
    }

    if (fields.email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(fields.email)) {
        isValid = false;
        errors["email"] = t("signup.please_enter_valid_address");
      }
    }

    if (fields.email.length === 0) {
      isValid = false;
      errors["email"] = t("signup.please_enter_your_mail");
    }

    setErrors(errors);
    return isValid;
  }

  function useForceUpdate() {
    validateForm();
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    try {
      const signedUpUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          email: fields.email,
        },
      });

      setIsLoading(false);
      setNewUser({
        email: fields.email,
        fullName: fields.fullName,
        id: signedUpUser.userSub,
      });
    } catch (e) {
      onError(e);
      setErrors({ email: e.message });
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <div className="form">
        <div className="title">Canadian Political Atlas</div>
        <form className="confirm-label">
          <ControlLabel>
            Please check your email, and confirm your account
          </ControlLabel>
        </form>
      </div>
    );
  }

  function renderForm() {
    return (
      <div className="form">
        <div className="title">Canadian Political Atlas</div>
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="email" bsSize="small">
            <FormControl
              type="email"
              placeholder={t("signup.work_email_address")}
              value={fields.email}
              onChange={handleFieldChange}
              onKeyUp={useForceUpdate}
              className="input-txt-style"
            />
            <div id="err_email" className="text-danger txt-err">
              {Errors.email}
            </div>
          </FormGroup>
          <FormGroup controlId="password" bsSize="small">
            <div className="input-group">
              <FormControl
                type="password"
                className="form-control input-txt-style"
                placeholder={t("signup.password")}
                aria-describedby="basic-addon2"
                value={fields.password}
                onChange={handleFieldChange}
                onKeyUp={useForceUpdate}
              />
              <Whisper placement="top" trigger="hover" speaker={tooltip}>
                <span
                  className="input-group-addon"
                  data-tip
                  data-for="registerTip"
                  id="basic-addon2"
                >
                  ?
                </span>
              </Whisper>
            </div>

            <div id="err_password" className="text-danger txt-err">
              {Errors.password}
            </div>
          </FormGroup>

          <FormGroup controlId="confirmPassword" bsSize="small">
            <FormControl
              type="password"
              onChange={handleFieldChange}
              placeholder={t("signup.repeat_password")}
              value={fields.confirmPassword}
              onKeyUp={useForceUpdate}
              className="confirmpassword input-txt-style"
            />
            <div id="err_confirmpassword" className="text-danger txt-err">
              {Errors.confirmpassword}
            </div>
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            bsSize="small"
            className="signupbtn form-control btn-font-txt"
            isLoading={isLoading}
          >
            {t("signup.create_account")}
          </LoaderButton>
          <div className="div-lnk-login-page text-center">
            {t("signup.already_have_an_account")} ?{" "}
            <Link to="/login" id="lnk_signin" className="login-page-style">
              {t("signup.signin")}
            </Link>
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className="Signup">
      {newUser ? renderConfirmationForm() : renderForm()}
    </div>
  );
}

export default withNamespaces()(Signup);
