import React, { useEffect, useState } from "react";
import "./ObjectIntroduceBy.css";
import {
  Panel,
  Divider,
  Placeholder,
  Loader,
  Col,
  Grid,
  Row,
  Avatar,
} from "rsuite";
import avatar from "../../../../assets/Images/avatar-placeholder.png";
import arrowup from "../../../../assets/Images/arrowup.png";
import { Link } from "react-router-dom";
import { getObject } from "../../../../services/objectService";
import { withNamespaces } from "react-i18next";

function ObjectIntroduceBy(props) {
  const loading = false;
  const { Paragraph } = Placeholder;
  const [object, setObject] = useState();

  const addDefaultAvatar = (ev) => {
    ev.target.src = avatar;
  };

  useEffect(() => {
    getObject(props?.data?.actorId).then((o) => {
      setObject(o);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showObject = () => (
    <div className="panel-header">
      <div className="title">{props.t("introduced.introduced_by")}</div>

      <Divider className="m-0" />
      <Grid fluid>
        <Row>
          <Col lg={7} xs={7}>
            <Link to={"/leadership-board/profile/" + props.data?.actorId}>
              <Avatar
                className={
                  "profile-pic pull-left " +
                  (object.adminLevel === "1" && "federal")
                }
                circle
              >
                <img
                  alt="Profile Pic"
                  onError={addDefaultAvatar}
                  src={
                    "https://cpa-app-assets.s3.amazonaws.com/object-images/" +
                    props.data?.actorId +
                    ".jpeg"
                  }
                />
              </Avatar>
            </Link>
          </Col>
          <Col lg={17} xs={17}>
            <div className="actor-title mt-3">
              <Link to={"/leadership-board/profile/" + props.data?.actorId}>
                {props.data?.actorName}
              </Link>{" "}
              <img src={arrowup} alt="up" />
            </div>
            <div className="content">
              <span id="Title">{object.title}</span>
              <br />
              <span id="ProvincialRiding">{object.riding}</span>
              <br />
              <span id="PoliticalAffiliation">
                {object.politicalAffiliation}
              </span>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );

  return (
    <Panel className="Introduce no-padding" shaded>
      {loading && <Loader backdrop center content="loading" />}
      {!object && (
        <div>
          <Paragraph style={{ margin: 10 }} />
          <Paragraph style={{ margin: 30 }} />
        </div>
      )}
      {object && showObject()}
    </Panel>
  );
}
export default withNamespaces()(ObjectIntroduceBy);
